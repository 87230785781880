import { makeVar } from '@apollo/client';
import { get_current_session } from './get-current-session';
import { localStorageView } from 'Helper/helper';
import {
  meleeFilterDefaultState,
  defaultSpecialRequestState,
  defaultSpecialAdminRequestState,
  DEFAULT_MELEE_QUERY_STATE,
  MELEE_ADMIN_REPOSITORY_LOCAL_FILTER,
  DEFAULT_MELEE_REPO_EDIT_STATE,
} from 'constants/index';
import {
  parseToMeleeSearchQuery,
  getParsedJSON,
  parseToLocalMeleeLGFilterState,
} from 'melee/components/MeleeFilters/filterConstants';
import { omit } from 'lodash/object';

import { referencePageQueryStateResolver } from '../../Admin/components/Melee/Helpers';
import { featureFlagResolver } from './featureFlagResolver';
import { defaultSpecialEditAdminRequestState } from 'constants/product';

// @see https://www.apollographql.com/docs/react/local-state/reactive-variables
// @see https://www.apollographql.com/docs/react/local-state/managing-state-with-field-policies/#defining
export const appRouteRx = makeVar(window.location.pathname);
export const userSession = makeVar(get_current_session());
export const featureFlagsRx = makeVar(featureFlagResolver());
export const printerConfigVar = makeVar(null);

/** Memo Mode **/
const dashboardMode = localStorage.getItem('dashboard_mode');
export const defaultDashboardModeState = {
  isMemoMode: dashboardMode === 'MEMO' ? true : false,
};
export const dashboardModeStateRx = makeVar(defaultDashboardModeState);

/*** Melee ***/
const meleeQueryLSNaturalView = localStorage.getItem('m_s_q_n');

export const meleeCaratNPiecesRx = makeVar({});

const meleeQueryState = getParsedJSON(
  meleeQueryLSNaturalView,
  DEFAULT_MELEE_QUERY_STATE
);
const meleeARFState = MELEE_ADMIN_REPOSITORY_LOCAL_FILTER;
const meleeFilterState = omit(meleeQueryState.query, ['type']);
export const meleeFilterStateRx = makeVar(meleeFilterState);
// Melee Repository page
export const meleeBulkStateRX = makeVar({
  supplier: null,
  uploads: [],
});
export const meleeARFStateRX = makeVar(meleeARFState);
// Melee Reference report page
export const meleeRfPageStateRX = makeVar(referencePageQueryStateResolver());
export const meleeInventoryColumnARFStateRX = makeVar({
  columns: [],
  showCustomColumn: false,
});
export const meleeInventoryLocalColumnRXState = makeVar([]);
const meleeQuery = meleeFilterState
  ? parseToMeleeSearchQuery(meleeFilterState)
  : meleeFilterDefaultState;

export const meleeOffersQueryRx = makeVar({
  ...DEFAULT_MELEE_QUERY_STATE,
  query: {
    ...DEFAULT_MELEE_QUERY_STATE.query,
    ...meleeQuery,
  },
  order: meleeQueryState.order,
});
let meleeLGFilterValue = parseToLocalMeleeLGFilterState();
export const meleeLabgrownFilterStateRx = makeVar(meleeLGFilterValue);
export const meleeSelectedItemsRx = makeVar({
  selectedRows: [],
  selectedRowItems: [],
});
// noinspection SpellCheckingInspection
export const viewModeRx = makeVar(
  localStorageView.get('view') ??
    (global && global.innerWidth < 700 ? 'biggrid' : 'grid')
);

export const meleeEditRepoByIdRx = makeVar(DEFAULT_MELEE_REPO_EDIT_STATE);

// Hubspot chat widget state
export const hubspotStateVar = makeVar({
  open: false,
  settings: {},
});
export const selectedProductsRx = makeVar({
  DNatural: [],
  DLabgrown: [],
  gemstone: [],
  MNatural: [],
  MLabgrown: [],
});

export const searchCurrentTabRx = makeVar('');
export const SpecialRequestCustomerRx = makeVar(defaultSpecialRequestState);
export const SpecialRequestAdminRx = makeVar(
  defaultSpecialEditAdminRequestState
);
export const SpecialRequestCreateAdminRx = makeVar(
  defaultSpecialAdminRequestState
);

/* Self serve */
const self_serve_modal_default = localStorage.getItem('self_serve_help');

export const openSelfHelpModalRx = makeVar(
  self_serve_modal_default ? !self_serve_modal_default : true
);

export const openDiamondRequestModalRx = makeVar({
  open: false,
  certificate_number: '',
  handleSuccess: () => {},
});

export const paymentModalRx = makeVar({
  showPaymentModal: false,
});
