import { gql } from '@apollo/client';
import { NoteFragment } from './NoteFragment';

const JewelleryOrderItemFragment = gql`
  fragment JewelleryOrderItemFragment on OrderItem {
    id
    status
    order_number
    order_date
    ProductType
    origin_country
    base_sell_price
    buy_price
    buy_price_confirmed
    nivoda_markup
    invoice {
      id
    }
    order {
      id
      order_date
    }
    seller {
      id
      company {
        id
        name
      }
    }
    buyer {
      id
      firstName
      lastName
      company {
        id
        name
        company_settings {
          CompanyId
        }
      }
    }
    destination {
      id
      country
    }
    notes {
      ...AdminNote
    }
    services {
      id
      name
      service_fee
      actual_service_fee
      service_fee_currency
    }
  }
  ${NoteFragment}
`;

const JewelleryStoneFragment = gql`
  fragment JewelleryStoneFragment on JewelleryComponent {
    id
    status
    stone_position
    ProductType
    description
    comments
    length
    width
    depth
    set_by_company {
      id
      name
    }
  }
`;

const JewelleryMountFragment = gql`
  fragment JewelleryMountFragment on JewelleryComponent {
    id
    status
    ProductType
    metal_type
    metal_color
    metal_weight
    metal_size
    metal_width
    width_tolerance
    metal_depth
    depth_tolerance
    engraving_font
    engraving_text
    chain_type
    clasp_type
  }
`;

const JewelleryFileFragment = gql`
  fragment JewelleryFileFragment on JewelleryFiles {
    id
    type
    url
  }
`;

const JewelleryFragment = gql`
  fragment JewelleryFragment on Jewellery {
    id
    sku
    status
    jewellery_type
    comments
    description
    sku_supplier
    collection_name_supplier
    supplier_notified
    quote_price
    metal_price
    stones_price
    labour_price
  }
`;

export {
  JewelleryFragment,
  JewelleryOrderItemFragment,
  JewelleryMountFragment,
  JewelleryStoneFragment,
  JewelleryFileFragment,
};
