import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { Icon } from 'fabric/resources/icon';
import { theme } from 'fabric/theme';
import { Tooltip } from '@nivoda/ui';
import { Link } from '@reach/router';

const sizeMap = {
  default: '14px',
  small: '12px',
  large: '16px',
};

const weightMap = {
  regular: 400,
  strong: 600,
};

const variantStyles = {
  h3: css`
    color: ${({ color }) =>
      theme.palette[color] || color || theme.palette.themePrimary} !important;
    font-size: 24px !important;
    font-weight: 700 !important;
    line-height: 28px !important;
  `,
  h4: css`
    color: ${({ color }) =>
      theme.palette[color] || color || theme.palette.themePrimary} !important;
    font-size: 20px !important;
    font-weight: 700 !important;
    line-height: 24px !important;
  `,
  h5: css`
    color: ${({ color }) =>
      theme.palette[color] || color || theme.palette.Neutrals900} !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 20px !important;
  `,
  h6: css`
    color: ${({ color }) =>
      theme.palette[color] || color || theme.palette.Neutrals900} !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 18px !important;
  `,
  interactive: css`
    color: ${({ color }) =>
      theme.palette[color] || color || theme.palette.themePrimary} !important;
    font-size: ${({ size }) => sizeMap[size] || '14px'} !important;
    font-weight: 500 !important;
    line-height: ${({ lineHeight }) => lineHeight || '20px'} !important;
  `,
  body: css`
    color: ${({ color }) =>
      theme.palette[color] || color || theme.palette.Neutrals600} !important;
    font-size: ${({ size }) => sizeMap[size] || '14px'} !important;
    font-weight: 400 !important;
    line-height: ${({ lineHeight }) => lineHeight || '22px'} !important;
  `,
  text: css`
    color: ${({ color }) =>
      theme.palette[color] || color || theme.palette.Neutrals600} !important;
    font-size: ${({ size }) => sizeMap[size] || '14px'} !important;
    font-weight: ${({ weight }) => weightMap[weight] || 400} !important;
    line-height: ${({ size }) => sizeMap[size] || '14px'} !important;
  `,
};

const TypographyStyle = styled.div`
  ${({ variant }) => variantStyles[variant]};
  color: ${({ color, disabled }) =>
    disabled ? theme.palette.Neutrals400 : color};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;

export const Typography = ({
  variant,
  weight,
  size,
  color,
  style,
  children,
  disabled,
  ...rest
}) => {
  return (
    <TypographyStyle
      variant={variant}
      size={size}
      weight={weight}
      color={color}
      style={style}
      disabled={disabled}
      {...rest}
    >
      {children}
    </TypographyStyle>
  );
};

Typography.propTypes = {
  variant: PropTypes.oneOf([
    'text',
    'h3',
    'h4',
    'h5',
    'interactive',
    'body',
    'h6',
  ]),
  weight: PropTypes.oneOf(['regular', 'strong']),
  size: PropTypes.oneOf(['default', 'small', 'large']),
  color: PropTypes.string,
  children: PropTypes.node,
};

Typography.defaultProps = {
  variant: 'text',
  weight: 'regular',
  size: 'default',
  color: undefined,
};

export const CustomButton = ({
  mode = 'default',
  variant = 'primary',
  active,
  disabled,
  width,
  height,
  onClick,
  children,
  loading,
  ...rest
}) => {
  return (
    <CustomButtonStyle
      mode={mode}
      variant={variant}
      height={height}
      width={width}
      active={active}
      disabled={disabled}
      onClick={onClick}
      {...rest}
    >
      {loading ? <Icon name={'loader'} /> : children}
    </CustomButtonStyle>
  );
};

const CustomButtonStyle = styled.button`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  align-items: center;
  gap: ${({ gap }) => gap || '8px'};
  width: ${({ width }) => width};
  min-width: ${({ minWidth }) => minWidth};
  height: ${({ height }) => height || '44px'};
  padding: ${({ padding }) => padding || '12px'};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  border-radius: 8px;
  border: 1px solid transparent;
  outline: none !important;
  cursor: pointer;

  ${({ mode, variant }) =>
    variant === 'primary'
      ? mode === 'memo'
        ? css`
            background: ${theme.semanticColors.memoButtonPrimary};
            color: ${theme.palette.white};
            &:hover:not(:disabled) {
              background: ${theme.semanticColors.memoButtonPrimaryHover};
            }
            &:disabled {
              background: ${theme.palette.offWhite4};
              color: ${theme.palette.Neutrals400};
              cursor: not-allowed;
            }
            &:active:not(:disabled) {
              background: ${theme.semanticColors.memoButtonPrimaryActive};
            }
          `
        : css`
            background: ${({ active }) =>
              active
                ? theme.semanticColors.regularPrimary500
                : theme.semanticColors.buttonPrimary};
            color: ${theme.palette.white};
            &:hover:not(:disabled) {
              background: ${theme.palette.themeSecondary};
            }
            &:disabled {
              background: ${theme.palette.Neutrals200};
              color: ${theme.palette.Neutrals400};
              cursor: not-allowed;
            }
          `
      : null};

  ${({ mode, variant }) =>
    variant === 'secondary'
      ? mode === 'memo'
        ? css`
            background: ${theme.palette.white};
            border: 1px solid ${theme.palette.Neutrals300};
            color: ${theme.palette.Neutrals700};
            &:hover:not(:disabled) {
              border: 1px solid ${theme.semanticColors.memoButtonPrimaryHover};
              color: ${theme.semanticColors.memoButtonPrimary};
            }
            &:disabled {
              background: ${theme.semanticColors.buttonSecondaryDisabled};
              border: 1px solid ${theme.palette.Neutrals200};
              color: ${theme.palette.Neutrals400};
              cursor: not-allowed;
            }
          `
        : css`
            background: ${({ active }) =>
              active ? theme.palette.backgroundColor : theme.palette.white};
            border: ${({ active }) =>
              active
                ? '1px solid #A8A29E'
                : `1px solid ${theme.palette.Neutrals300}`};
            color: ${({ active }) =>
              active ? theme.palette.navyBlue4 : theme.palette.Neutrals700};
            &:hover:not(:disabled) {
              background: ${theme.palette.white};
              border: 1px solid ${theme.semanticColors.regularPrimary300};
              color: ${theme.semanticColors.regularPrimary400};
            }
            &:disabled {
              background: ${theme.palette.Neutrals200};
              color: ${theme.palette.Neutrals400};
              cursor: not-allowed;
            }
          `
      : null};

  ${({ mode, variant }) =>
    variant === 'tertiary'
      ? mode === 'memo'
        ? css`
            background: ${theme.palette.offWhite4};
            color: ${theme.semanticColors.buttonGhostPrimary};
            &:hover:not(:disabled) {
              background: ${theme.palette.offWhite4};
              color: ${theme.semanticColors.memoButtonPrimary};
            }
            &:disabled {
              background: ${theme.palette.offWhite4};
              color: ${theme.palette.Neutrals400};
              cursor: not-allowed;
            }
          `
        : css`
            background: ${({ active }) =>
              active ? theme.palette.Neutrals300 : 'transparent'};
            color: ${theme.semanticColors.buttonGhostPrimary};
            &:hover:not(:disabled) {
              background: #18181b08;
              color: ${theme.palette.navyBlue4};
            }
            &:disabled {
              background: ${theme.palette.Neutrals200};
              color: ${theme.palette.Neutrals400};
              cursor: not-allowed;
            }
          `
      : null};
  ${({ mode, variant }) =>
    variant === 'ghost'
      ? mode === 'memo'
        ? css`
            background: transparent;
            color: ${theme.semanticColors.buttonGhostPrimary};
            &:hover:not(:disabled) {
              color: ${theme.semanticColors.memoButtonPrimary};

              svg {
                color: ${theme.semanticColors.memoButtonPrimary};
              }
            }
            &:disabled {
              color: ${theme.palette.Neutrals400};
              cursor: not-allowed;
            }
          `
        : css`
            background: transparent;
            color: ${theme.semanticColors.buttonGhostPrimary};
            &:hover:not(:disabled) {
              color: ${theme.palette.themeSecondary};
              svg {
                color: ${theme.palette.themeSecondary};
              }
            }
            &:disabled {
              color: ${theme.palette.Neutrals400};
              cursor: not-allowed;
            }
          `
      : null};
`;

const ChoiceBoxStyle = styled.div`
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: ${({ alignItems }) => alignItems || 'center'};
  gap: ${({ gap }) => gap || '16px'};
  border-radius: 8px;
  width: 100%;
  height: ${({ height }) => height || '64px'};
  border: ${({ checked, mode, disabled }) =>
    disabled
      ? `1px solid ${theme.palette.Neutrals200}`
      : checked
      ? `2px solid ${
          mode === 'memo'
            ? theme.semanticColors.memoPrimary400
            : theme.palette.themeSecondary
        }`
      : `1px solid ${theme.palette.Neutrals200}`};
  background: ${({ disabled }) =>
    disabled ? theme.palette.Neutrals100 : theme.palette.white};
  outline: none !important;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  color: ${({ disabled }) =>
    disabled ? theme.palette.Neutrals400 : 'inherit'};

  .choice-box__icon {
    position: relative;
    display: flex;
    width: 16px;
    height: 16px;
    justify-content: center;
    align-items: center;
    border-radius: 999px;
    flex-shrink: 0;
    border: ${({ checked, mode, disabled }) =>
      disabled
        ? `1px solid ${theme.palette.Neutrals200}`
        : checked
        ? `1px solid ${
            mode === 'memo'
              ? theme.semanticColors.memoPrimary400
              : theme.palette.themeSecondary
          }`
        : '1px solid #A8A29E'};
    background: ${({ checked, mode, disabled }) =>
      disabled
        ? theme.palette.Neutrals100
        : checked
        ? mode === 'memo'
          ? theme.semanticColors.memoPrimary400
          : theme.palette.themeSecondary
        : theme.palette.white};
    & > svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      flex-shrink: 0;
    }
  }
`;
export const ChoiceBox = ({
  checked = false,
  disabled,
  height,
  alignItems,
  onClick,
  children,
  mode,
  ...rest
}) => (
  <ChoiceBoxStyle
    className="choice-box"
    checked={checked}
    disabled={disabled}
    onClick={onClick}
    height={height}
    alignItems={alignItems}
    mode={mode}
    {...rest}
  >
    <React.Fragment>{children}</React.Fragment>
    <div className="choice-box__icon">
      {checked && <Icon name="check_3" size={12} color={theme.palette.white} />}
    </div>
  </ChoiceBoxStyle>
);

const CustomLinkStyle = styled.div`
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  color: ${theme.palette.themePrimary};
  cursor: pointer;
  .__underline {
    border-top: 1px solid #a8a29e;
    margin-top: 1px;
  }
  &:hover {
    color: ${({ hoverColor }) =>
      hoverColor ? hoverColor : theme.palette.themeSecondary};
    .__underline {
      border-top: 1px solid
        ${({ hoverColor }) =>
          hoverColor ? hoverColor : theme.palette.themeSecondary};
    }
  }
`;
export const CustomLink = ({ onClick, label, hoverColor }) => {
  return (
    <CustomLinkStyle onClick={onClick} hoverColor={hoverColor}>
      {label}
      <div className="__underline"></div>
    </CustomLinkStyle>
  );
};

const BoxStyle = styled.div`
  ${({ direction }) =>
    direction === 'row' &&
    css`
      display: flex;
      flex-direction: row;
    `}

  ${({ direction }) =>
    direction === 'col' &&
    css`
      display: flex;
      flex-direction: column;
    `}

  display: ${({ display }) => display};
  gap: ${({ gap }) => gap};
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justifyContent }) => justifyContent};
  flex-wrap: ${({ flexWrap }) => flexWrap};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  max-width: ${({ maxWidth }) => maxWidth};
  max-height: ${({ maxHeight }) => maxHeight};
`;

export const Box = ({
  direction,
  display,
  gap,
  alignItems,
  justifyContent,
  width,
  height,
  maxWidth,
  maxHeight,
  style,
  children,
  ...rest
}) => {
  return (
    <BoxStyle
      direction={direction}
      display={display}
      gap={gap}
      alignItems={alignItems}
      justifyContent={justifyContent}
      width={width}
      height={height}
      maxWidth={maxWidth}
      maxHeight={maxHeight}
      style={style}
      {...rest}
    >
      {children}
    </BoxStyle>
  );
};

export const InfoIconWithTooltip = ({ tooltip_props, iconSize }) => {
  return (
    <Tooltip
      style={{ display: 'flex', alignItems: 'center' }}
      placement={'right'}
      {...tooltip_props}
    >
      <Icon
        name="question_mark_circled"
        color={theme.palette.subdued}
        size={iconSize}
      />
    </Tooltip>
  );
};

const SingleLineCheckoutBannerWrapper = styled.div`
  background: ${({ bgColor }) => (bgColor ? bgColor : theme.palette.red600)};
  display: flex;
  width: 100%;
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : 'center'};
  align-items: center;
  height: ${({ height }) => height || '43px'};
  color: ${({ contentColor }) =>
    contentColor ? contentColor : theme.palette.white};
  gap: 5px;
  ${theme.fonts.small}
  a {
    ${theme.fonts.smallBold}
    color: ${({ contentColor }) =>
      contentColor ? contentColor : theme.palette.white};
    text-decoration: underline;
  }
`;

const CheckoutBannerWrapper = styled.div`
  color: ${theme.palette.red800};
  padding: 16px;
  background: ${theme.palette.red50};
  ${theme.fonts.medium}
  border-radius:8px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  .pointer {
    margin-top: 8px;
    background: ${theme.palette.amber900};
    height: 5px;
    width: 5px;
    border-radius: 20px;
  }
  .multi_info_checkout_banner {
    display: flex;
    flex-direction: column;
  }
  .checkout_banner_left {
    margin-top: 3px;
  }
  .close_icon {
    cursor: pointer;
  }
  .checkout_banner_right {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 5px;
    width: 100%;
    .heading {
      ${theme.fonts.mediumBold}
    }
  }
`;

export const CheckoutBanner = ({
  iconProps = { name: 'lock-v2', size: 14, color: theme.palette.red800 },
  title,
  description,
  bannerWrapperClass,
  onCloseClick,
  hasClose = true,
}) => {
  return (
    <CheckoutBannerWrapper className={bannerWrapperClass}>
      <div className="checkout_banner_left">
        <Icon {...iconProps} />
      </div>
      <div
        className="checkout_banner_right"
        data-automation-id="checkout-banner"
      >
        <div>
          <span className="heading">{title}</span>
          <div> {description}</div>
        </div>
        {hasClose && (
          <Icon
            name="close"
            className="close_icon"
            size={10}
            onClick={onCloseClick}
          />
        )}
      </div>
    </CheckoutBannerWrapper>
  );
};

export const SingleLineCheckoutBanner = ({
  iconProps,
  title,
  linkProps = { path: '', content: '' },
  bannerWrapperClass,
  bgColor = '',
  justifyContent = '',
  contentColor,
  height,
}) => {
  return (
    <SingleLineCheckoutBannerWrapper
      className={bannerWrapperClass}
      data-automation-id="checkout-banner"
      bgColor={bgColor}
      justifyContent={justifyContent}
      contentColor={contentColor}
      height={height}
    >
      <span>
        <Icon
          name="lock-v2"
          size={12}
          color={theme.palette.white}
          {...iconProps}
        />
      </span>
      {title}
      {linkProps && (
        <React.Fragment>
          {linkProps?.path ? (
            <Link to={linkProps?.path} replace={true}>
              {linkProps?.content}
            </Link>
          ) : (
            <React.Fragment>{linkProps?.content}</React.Fragment>
          )}
        </React.Fragment>
      )}
    </SingleLineCheckoutBannerWrapper>
  );
};
