// noinspection SpellCheckingInspection

export const courierServiceProvider = {
  FEDEX: 'FEDEX',
  ROYAL: 'ROYAL',
  BRINKS: 'BRINKS',
  UPS: 'UPS',
  GOPHR: 'GOPHR',
  MALCA: 'MALCA',
  'BVC-IESHIP': 'BVC-IESHIP',
  DHL: 'DHL',
  TNT: 'TNT',
  ANGADIA: 'ANGADIA',
  BVC: 'BVC',
  THE_COURIER_GUY: 'THE_COURIER_GUY',
};

export const courierServiceProvidersList = [
  { name: 'Fedex', value: 'FEDEX', disabled: false },
  { name: 'Royal Mail', value: 'ROYAL', disabled: false },
  { name: 'Brinks', value: 'BRINKS', disabled: false },
  { name: 'UPS', value: 'UPS', disabled: false },
  { name: 'Gophr', value: 'GOPHR', disabled: false },
  { name: 'Malca-Amit', value: 'MALCA', disabled: false },
  {
    name: 'BVC-IESHIP',
    value: 'BVC-IESHIP',
    disabled: false,
  },
  { name: 'DHL', value: 'DHL', disabled: false },
  { name: 'TNT', value: 'TNT', disabled: false },
  {
    name: 'Angadia',
    value: 'ANGADIA',
    disabled: false,
  },
  {
    name: 'BVC',
    value: 'BVC',
    disabled: false,
  },
  {
    name: 'The Courier Guy',
    value: 'THE_COURIER_GUY',
    disabled: false,
  },
];

export const boxSizeMap = {
  '26X18.5X10cm': 'Box 1 - 26X18.5X10cm',
  '32X25X10cm': 'Box 2 - 32X25X10cm',
  '25.5X30X15cm': 'Box 3 - 25.5X30X15cm',
};

export const serviceProviderTypeList = {
  FEDEX: [
    {
      name: 'Fedex Next Day Afternoon',
      value: 'fedex_next_day_end_of_day',
    },
    {
      name: 'Fedex International Priority',
      value: 'fedex_international_priority',
    },
    {
      name: 'Fedex International Priority Express (All destinations except AU)',
      value: 'fedex_international_priority_express',
    },
    {
      name: 'Fedex Local Next Day (NL) or US to US ',
      value: 'fedex_priority_overnight',
    },
  ],
  BRINKS: [
    {
      name: 'Brinks Door to Door',
      value: 'brinks_door_to_door',
    },
  ],
  ROYAL: [
    {
      name: 'Royal Mail',
      value: 'royal_mail',
    },
    {
      name: 'Special Delivery Guaranteed by 1pm',
      value: 'special_delivery_1pm',
    },
  ],
  GOPHR: [
    {
      name: 'Gophr London',
      value: 'gophr_london',
    },
  ],
  UPS: [
    {
      name: 'UPS Express',
      value: 'ups_express',
    },
    {
      name: 'UPS Express Saver',
      value: 'ups_saver',
    },
    {
      name: 'UPS Next day air',
      value: 'ups_next_day_air',
    },
  ],
  MALCA: [
    {
      name: 'Malca-Amit Express Shipping',
      value: 'malca_amit_express_shipping',
    },
  ],
  'BVC-IESHIP': [
    {
      name: 'BVC-IESHIP',
      value: 'bvc_ieship',
    },
  ],
  DHL: [
    {
      name: 'DHL Express Worldwide EU only',
      value: 'dhl_express_worldwide_eu',
    },
    {
      name: 'DHL Express NL Only',
      value: 'dhl_domestic_express',
    },
    { name: 'DHL Express Envelope', value: 'dhl_express_envelope' },
  ],
  TNT: [
    {
      name: 'Overnight Express',
      value: 'overnight_express',
    },
  ],
  ANGADIA: [
    {
      name: 'Hand delivery',
      value: 'hand_delivery',
    },
  ],
  BVC: [
    {
      name: 'BVC',
      value: 'BVC',
    },
  ],
  THE_COURIER_GUY: [
    { name: 'Overnight regional ', value: 'thecourierguy_overnight_regional' },
    {
      name: 'Local Overnight flyer ',
      value: 'thecourierguy_local_overnight_lyer',
    },
    { name: 'Overnight ', value: 'thecourierguy_overnight' },
    {
      name: 'Local Overnight parcel ',
      value: 'thecourierguy_local_overnight_parcel',
    },
    {
      name: 'Local same day flyer ',
      value: 'thecourierguy_local_sameday_lyer',
    },
    {
      name: 'Local sameday economy ',
      value: 'thecourierguy_local_same_day_economy',
    },
  ],
};

export const ShipmentTabURLs = {
  ALL_SHIPMENTS: 'ALL_SHIPMENTS',
  CREATE_SHIPMENTS: 'CREATE_SHIPMENTS',
};

export const ShipmentTabTitles = {
  ALL_SHIPMENTS: 'All shipments',
  CREATE_SHIPMENTS: 'Create shipment',
  SEARCH_ENVELOPE: 'Search Envelope',
};

export const ShipmentStatus = {
  CANCELLED: 'CANCELLED',
  DELIVERED: 'DELIVERED',
  FINAL: 'FINAL',
  OFFICE_INCOMING: 'OFFICE_INCOMING',
  PENDING: 'PENDING',
  READY_FINAL_DELIVERY: 'READY_FINAL_DELIVERY',
  SHIPPED_FINAL_DELIVERY: 'SHIPPED_FINAL_DELIVERY',
  TO_PROCESS: 'TO_PROCESS',
  TO_RETURN: 'TO_RETURN',
};

export const ENVELOPE_TITLE_REGEX =
  /^(\d+)-[A-Z]+\d+\/\d+-[A-Z]+-(\d+)(-\w+)?$/;
