import { useEffect } from 'react';
import { uniq, upperCase } from 'lodash';
import { SignupContainer } from '../../../Signup/SignupContainer';
import { isMobile, useAsState } from 'utils';
// import { InputWithTick } from 'components/ui/Input';
import { validate } from 'email-validator';
import { CustomTrans, passwordChecker } from '../../../helpers';
import { useErrorWrapper } from 'fabric/hooks';
import { BasicInfoPageWrapper, PhoneInputWrapper } from '../styles';
import { isValidPhoneNumber } from 'react-phone-number-input/mobile';
import { CheckboxMedia } from '@nivoda/forms';
import PhoneInput from 'react-phone-input-2';
import { Trans, useTranslation } from 'react-i18next';
import { INITIALISE_STEPONE } from 'graphql/mutations/common/user-onboarding';
import { Link } from '@reach/router';
import { useMutation } from '@apollo/client';
import { graphqlClient } from 'graphql/factory';
import {
  CustomInput,
  PrimaryButton,
} from 'Signup/components/CustomSignupComponents';
import { theme } from 'fabric/theme';

import { useState } from 'react';
import { ROLES } from './helper';
import { CustomerRole } from './CustomerRole';
import axios from 'axios';
import ScrollToTop from 'layout/ScrollToTop';
import { Track } from 'fabric/integrations';

export const BasicInfoPage = ({ setState, state }) => {
  const local_role = localStorage.getItem('role');
  let params = new URLSearchParams(window.location?.search);
  let initialRole = local_role
    ? local_role === ROLES.CUSTOMER
      ? ROLES.CUSTOMER
      : ROLES.SUPPLIER
    : params.get('type')
    ? params.get('type') === 'seller'
      ? ROLES.SUPPLIER
      : ROLES.CUSTOMER
    : ROLES.CUSTOMER;

  let [stepOneCompleted, setStepOneCompleted] = useState(
    params.get('type') !== null &&
      (params.get('type') === 'seller' || params.get('type') === 'buyer')
  );
  let [role, setRole] = useState(initialRole);
  let [countryData, setCountryData] = useState({
    defaultCountry: '',
  });

  let SubmitStepOne = async () => {
    setStepOneCompleted(true);
    localStorage.setItem('role', role);
  };

  useEffect(() => {
    if (params.get('type')) {
      localStorage.setItem(
        'role',
        params.get('type') === 'seller' ? ROLES.SUPPLIER : ROLES.CUSTOMER
      );
      setRole(
        params.get('type') === 'seller' ? ROLES.SUPPLIER : ROLES.CUSTOMER
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let phone_country_code = localStorage.getItem('phone_country_code');
    let defaultCountry = localStorage.getItem('defaultCountry');
    let setFromLocal = async () => {
      if (defaultCountry != null && phone_country_code != null) {
        setCountryData({
          defaultCountry: defaultCountry,
          phone_country_code: phone_country_code,
        });
      }
    };
    const getGeoInfo = async () => {
      axios
        .get('https://ipapi.co/json/')
        .then((response) => {
          let data = response.data;

          setCountryData({
            country: role !== 'CUSTOMER' ? data.country_code : '',
            defaultCountry: data.country_code,
            phone_country_code: data.country_calling_code.replace('+', ''),
          });
          localStorage.setItem('defaultCountry', data.country_code);
          localStorage.setItem(
            'phone_country_code',
            data.country_calling_code.replace('+', '')
          );
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
    };

    function loadData() {
      if (phone_country_code && defaultCountry) {
        return setFromLocal();
      } else getGeoInfo();
    }
    loadData();
    return () => {
      loadData();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    Track.track_page('Signup page -> Basic info');
  }, []);
  // const { t } = useTranslation();

  return (
    <SignupContainer>
      {!stepOneCompleted ? (
        <CustomerRole
          onSubmit={SubmitStepOne}
          role={role}
          onBtnClick={(value) => setRole(value)}
        />
      ) : (
        <PersonalInfoPage
          role={role}
          defaultCountry={countryData.defaultCountry}
          countryData={countryData}
          set_general_form_state={setState}
          general_form_state={state}
          initial_email={params?.get('email') ? params?.get('email') : ''}
        />
      )}
    </SignupContainer>
  );
};

let initiateFormStatus = {
  hasEnterFirstName: false,
  hasEnterLastName: false,
  hasEnterEmail: false,
  hasEnterPassword: false,
  hasConfirmPassword: false,
  hasEnterPhone: false,
  phone_is_valid: false,
};

let PersonalInfoPage = ({
  role,
  defaultCountry,
  countryData,
  set_general_form_state,
  general_form_state,
  initial_email,
}) => {
  useEffect(() => {
    Track.track_page('Signup page -> Personal Info');
  }, []);
  let initiateFormState = {
    first_name: '',
    last_name: '',
    email: initial_email,
    phone: '',
    password: '',
    confirm_password: '',
    country: '',
    confirm_email: '',
  };
  const [formSubmitted, setFormSubmitted] = useState(false); // do we ned this?
  const [fnWrapper, context] = useErrorWrapper({
    showSuccess: false,
    useToastNotification: false,
    // toastNotificationProps: { duration: 10000 },
    disableHelmet: true,
  });
  const [initialize_signup_step_one] = useMutation(INITIALISE_STEPONE, {
    client: graphqlClient,
  });
  let [formValues, setFormValues] = useAsState(initiateFormState);
  let [formStatus, setFormStatus] = useAsState(initiateFormStatus);
  let locLanguage = JSON.parse(localStorage?.getItem('preferred_language'));

  let {
    first_name,
    last_name,
    email,
    confirm_email,
    password,
    confirm_password,
    phone,
    terms_conditions,
  } = formValues;
  let { t } = useTranslation();

  let disabled = !(
    first_name.length > 0 &&
    last_name.length > 0 &&
    email.length > 0 &&
    validate(email.toLowerCase().trim()) &&
    email.toLowerCase().trim() === confirm_email.toLowerCase().trim() &&
    password === confirm_password &&
    password &&
    passwordChecker(password) &&
    formStatus.hasEnterPhone &&
    formStatus.phone_is_valid
  );

  const onSubmit = async () => {
    setFormSubmitted(true);
    if (!terms_conditions) return;
    await fnWrapper(
      async () => {
        context.setBusy({
          type: 'STEP_ONE_CONT_BUTTON',
          status: true,
        });
        Track.track('Personal info submitted', {
          email: email.toLowerCase().trim(),
          first_name,
          last_name,
        });
        let payload = {
          email: email.toLowerCase().trim(),
          first_name,
          last_name,
          password,
          role,
          phone,
          preferred_language: upperCase(locLanguage?.value || 'en'),
        };
        let res = await initialize_signup_step_one({
          variables: payload,
        });
        if (res) {
          let signup_id = await res.data.initialize_signup_step_one.id;
          localStorage.setItem('signup_id', signup_id);

          if (
            res.data.initialize_signup_step_one.verify_status === '1' &&
            res.data.initialize_signup_step_one.id !== null
          ) {
            localStorage.removeItem('defaultCountry');
            localStorage.removeItem('phone_country_code');
            await set_general_form_state({
              ...general_form_state,
              role,
              phone,
              email: email.toLowerCase().trim(),
              first_name,
              signup_id: signup_id,
              phone_sent: true,
              email_confirmed: true,
              email_phone_verify: true,
            });
          } else {
            await set_general_form_state({
              ...general_form_state,
              role,
              phone,
              email: email.toLowerCase().trim(),
              first_name,
              signup_id: signup_id,
              email_sent: true,
              email_phone_verify: true,
            });
          }
        }
        context.setBusy({
          type: '',
          status: false,
        });
      },
      null,
      {
        error: {
          title: 'Error',
          messageResolver: (msg) => {
            Track.track('Personal info error', {
              error: msg,
              email: email.toLowerCase().trim(),
              first_name,
              last_name,
            });
            if (msg.includes('PHONE_EXISTS')) {
              return t('already_account_please_contact');
            } else if (msg.includes('EMAIL_EXISTS')) {
              return (
                <div>
                  {t('email_already_exists_in_database_please_contact')}
                  <div style={{ marginTop: 8, textAlign: 'center' }}>
                    <Link to="/login" className="btn">
                      {t('log_in')}
                    </Link>
                  </div>
                </div>
              );
            } else if (msg.includes('INVALID PASSWORD')) {
              return t('invaild_password');
            } else if (msg.includes('Not allowed.')) {
              return (
                <div>
                  <strong>{t('no_password_attempts_left')}</strong>
                  <p style={{ fontSize: 14 }}>
                    {t('no_password_attempts_left_detail_text')}
                  </p>
                </div>
              );
            } else if (
              msg.includes(
                'Permission to send an SMS has not been enabled for the region'
              )
            ) {
              return (
                <div>
                  <strong>{t('registration_failed')}</strong>
                  <br />
                  {t('region_not_supported_message')}
                </div>
              );
            } else {
              return t('something_wrong_contact_support');
            }
          },
        },
      }
    );
  };

  //phone_country_code
  // let SetValue = (key, value) => {
  //   setFormValues({[key]: value})
  // }

  const phoneJsx = (
    <MobilePhoneWrapper
      phone={phone}
      setFormValues={setFormValues}
      setFormStatus={setFormStatus}
      phone_is_valid={formStatus.phone_is_valid}
      hasEnterPhone={formStatus.hasEnterPhone}
      phone_country_code={countryData.phone_country_code}
      defaultCountry={defaultCountry}
    />
  );

  return (
    <>
      <ScrollToTop />
      {context.responseJSX}
      <BasicInfoPageWrapper>
        <div className="basic_info_page--header">
          <h1 className="basic_info_page--header__title">
            <CustomTrans text={'lets_start_with_some_basics'} />
          </h1>
          <span className="basic_info_page--header__subtitle">
            <CustomTrans
              text="please_enter_the_information_below_these_will_be_your_accounts_credentials"
              // returnPlainText={true}
            />
          </span>
        </div>
        <div className="basic_info_page--form">
          {/* Mobile Error should be at the top. only after submit */}
          <div className="basic_info_page--form__row1">
            <CustomInput
              label={
                <CustomTrans text={'first_name'} returnPlainText={false} />
              }
              placeholder={''}
              onBlur={() => {
                setFormValues({
                  first_name: first_name.trim(),
                });
                setFormStatus({
                  hasEnterFirstName: true,
                });
              }}
              onChange={(text) =>
                setFormValues({
                  first_name: text.replace(/[0-9]/g, ''),
                })
              }
              type={'text'}
              value={first_name}
              isShowTick={
                formStatus.hasEnterFirstName && first_name.trim().length > 1
              }
              hasError={
                formStatus.hasEnterFirstName && !(first_name.trim().length > 1)
              }
              errorMessage={t('please_enter_your_first_name')}
              errorStyle={{
                borderColor: 'red',
              }}
              isRequired={true}
            />
            <CustomInput
              label={<span>{t('last_name')}</span>}
              placeholder={''}
              onBlur={() => {
                setFormValues({
                  last_name: last_name.trim(),
                });
                setFormStatus({
                  hasEnterLastName: true,
                });
              }}
              onChange={(text) =>
                setFormValues({ last_name: text.replace(/[0-9]/g, '') })
              }
              type={'text'}
              value={last_name}
              isShowTick={
                formStatus.hasEnterLastName && last_name.trim().length > 1
              }
              hasError={
                formStatus.hasEnterLastName && !(last_name.trim().length > 1)
              }
              errorMessage={t('please_enter_your_last_name')}
              errorStyle={{
                borderColor: 'red',
              }}
              isRequired={true}
            />
          </div>
          <div className="basic_info_page--form__row1">
            <CustomInput
              label={<span>{t('your_business_email')}</span>}
              placeholder={''}
              onBlur={() => setFormStatus({ hasEnterEmail: true })}
              onChange={(text) => setFormValues({ email: text.trim() })}
              value={email}
              hasError={
                (formStatus.hasEnterEmail && !email) ||
                (formStatus.hasEnterEmail &&
                  !validate(email.toLowerCase().trim()))
              }
              isShowTick={formStatus && validate(email.toLowerCase().trim())}
              errorMessage={t('please_enter_valid_email_address')}
              errorStyle={{
                borderColor: 'red',
              }}
              isRequired={true}
              wrapperClass="flex-on-mobile"
            />
            <CustomInput
              type="text"
              autoComplete="off"
              onPaste={(e) => {
                e.preventDefault();
              }}
              label={<span>{t('confirm_email')}</span>}
              placeholder={''}
              onBlur={() =>
                // confirm_email &&
                setFormStatus({
                  hasEnterConfirmationEmail: true,
                })
              }
              onChange={(text) => setFormValues({ confirm_email: text.trim() })}
              value={confirm_email}
              hasError={
                formStatus.hasEnterConfirmationEmail &&
                email.toLowerCase().trim() !==
                  confirm_email.toLowerCase().trim()
              }
              isShowTick={
                formStatus.hasEnterConfirmationEmail &&
                email.toLowerCase().trim() ===
                  confirm_email.toLowerCase().trim()
              }
              errorMessage={t('email_needs_to_match')}
              errorStyle={{
                borderColor: 'red',
              }}
              isRequired={true}
              wrapperClass="flex-on-mobile"
            />
          </div>
          <div className="basic_info_page--form__row1">
            <CustomInput
              label={<span>{t('password')}</span>}
              placeholder={''}
              onBlur={() => setFormStatus({ hasEnterPassword: true })}
              onChange={(password) =>
                setFormValues({ password: password.trim() })
              }
              type={'password'}
              value={password}
              hasError={
                (formStatus.hasEnterPassword && !password) ||
                (formStatus.hasEnterPassword && !passwordChecker(password))
              }
              isShowTick={
                formStatus.hasEnterPassword && passwordChecker(password)
              }
              errorMessage={t('valid_password_rules')}
              errorStyle={{
                borderColor: 'red',
              }}
              isRequired={true}
            />
            <CustomInput
              label={<span>{t('confirm_password')}</span>}
              placeholder={''}
              onBlur={() => setFormStatus({ hasConfirmPassword: true })}
              onChange={(confirm_password) =>
                setFormValues({ confirm_password: confirm_password.trim() })
              }
              onPaste={(e) => {
                e.preventDefault();
              }}
              type={'password'}
              value={confirm_password}
              hasError={
                password !== confirm_password && formStatus.hasConfirmPassword
              }
              isShowTick={
                password === confirm_password &&
                confirm_password?.length > 0 &&
                formStatus.hasConfirmPassword
              }
              errorMessage={t('passwords_need_to_match')}
              errorStyle={{
                borderColor: 'red',
              }}
              isRequired={true}
            />
          </div>
          {phoneJsx}
          <div className="terms_and_conditions">
            <span className="terms_and_conditions--title">
              <CustomTrans
                text={'terms_and_conditions'}
                returnPlainText={true}
              />
            </span>
            <div className="terms_and_conditions--content">
              <span>
                <Trans
                  i18nKey={
                    'in_order_to_continue_with_your_registration_please_read_and_accept_our_terms_and_conditions'
                  }
                  values={{
                    link: CustomTrans({
                      text: 'terms_and_conditions',
                      returnPlainText: true,
                    }),
                    policy: CustomTrans({
                      text: 'privacy_policy',
                      returnPlainText: true,
                    }),
                  }}
                  components={{
                    bold: <strong />,
                    lnk: (
                      <a
                        href={`${
                          role === 'SUPPLIER'
                            ? '/supplier-terms-and-conditions'
                            : '/supply-of-goods-terms-and-conditions'
                        }`}
                        rel="noopener noreferrer"
                        className="terms"
                        target="_blank"
                      >
                        {t('terms_conditions')}
                      </a>
                    ),
                    privacyLink: (
                      <a
                        href={`${process.env.REACT_APP_NIVODA_MAIN_URL}/privacy-policy`}
                        rel="noopener noreferrer"
                        className="terms"
                        target="_blank"
                      >
                        {t('privacy_policy')}
                      </a>
                    ),
                  }}
                />
              </span>
              <CheckboxMedia
                checked={terms_conditions}
                onChange={() =>
                  setFormValues({ terms_conditions: !terms_conditions })
                }
              >
                <span className="check_box_label">
                  <CustomTrans
                    text="i_confirm_that_i_have_read_and_agree_to_nivodas_terms_conditions"
                    returnPlainText={true}
                  />
                </span>
              </CheckboxMedia>
              {!terms_conditions && formSubmitted ? (
                <div
                  style={{
                    color: 'red',
                    fontSize: '11px',
                    padding: isMobile() && 8,
                  }}
                >
                  {t('please_accept_terms')}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <PrimaryButton
          btnText="continue"
          onClick={() => onSubmit()}
          disabled={disabled || context.busy.status}
          loading={context.busy.status}
        />
      </BasicInfoPageWrapper>
    </>
  );
};
let MobilePhoneWrapper = ({
  compulsary_dot,
  setFormValues,
  setFormStatus,
  // state,
  phone_country_code,
  phone_is_valid,
  phone,
  defaultCountry,
  hasEnterPhone,
}) => {
  const { t } = useTranslation();

  let preferred_countries = uniq([
    'us',
    'gb',
    'ca',
    defaultCountry.toLowerCase(),
  ]);

  useEffect(() => {
    /**
     * On page load,
     * change node position for input element in PhoneInput
     * to allow tab movement work perfectly
     */
    const inputEl = document.querySelector('.phone-code-selector');
    inputEl.parentNode.insertBefore(inputEl.parentNode.lastChild, inputEl);
  }, []);

  const validatePhoneOnBlur = async () => {
    let val = phone.charAt(0) === '+' ? phone.slice(1) : phone;

    let is_valid = isValidPhoneNumber('+' + val);

    if (val.length > 0 && is_valid) {
      setFormValues({
        phone: '+' + val,
      });
      setFormStatus({
        hasEnterPhone: true,
        phone_is_valid: true,
      });
    } else if (val.length > 0 && !is_valid) {
      await setFormStatus({
        hasEnterPhone: true,
        phone_is_valid: false,
      });
    } else {
      setFormStatus({
        hasEnterPhone: false,
        phone_is_valid: false,
      });
    }
  };

  return (
    <PhoneInputWrapper className="phone_input_wrapper">
      <label className="phone_input_wrapper--label">
        {t('mobile_number')} {compulsary_dot}
      </label>
      <PhoneInput
        preferredCountries={preferred_countries}
        placeholder={phone_country_code}
        value={phone_country_code}
        enableTerritories={true}
        excludeCountries={['je']}
        onChange={(value) => {
          setFormValues({ phone: value });
        }}
        inputProps={{
          name: 'phone',
          required: true,
          'data-automation-id': 'phone-input',
        }}
        inputClass="phone-code-selector"
        inputStyle={{
          border: '1px solid #C3BEBC',
          width: '100%',
          height: '35px',
          borderRadius: '8px',
          background: theme.palette.Neutrals100,
        }}
        onBlur={validatePhoneOnBlur}
      />

      {hasEnterPhone && !phone_is_valid && (
        <label
          style={{
            display: 'block',
            marginTop: '5px',
            color: 'red',
            fontSize: '12px',
          }}
        >
          {t('please_enter_valid_mobile_phone_number')}
        </label>
      )}
    </PhoneInputWrapper>
  );
};
