import { GraphqlFactory } from 'graphql/factory';
import { gql } from '@apollo/client';
import {
  CertificateFragment,
  HoldFragment,
  OfferFragment,
} from '../../fragments';

import uniqBy from 'lodash/uniqBy';
import { GemstoneOfferFragment } from 'graphql/fragments/GemstoneOfferFragment';
import { CustomerOrderListFragment } from './orders';
import {
  MeleeOfferListFragment,
  OfferFragmentPairs,
} from 'graphql/fragments/OfferFragment';

export const OFFERS_QUERY = gql`
  query platformSearch(
    $query: DiamondQuery
    $offset: Int
    $order: DiamondOrder
  ) {
    diamonds: offers_by_query(
      limit: 24
      offset: $offset
      query: $query
      order: $order
    ) @connection(key: "offers_by_query", filter: ["query", "order"]) {
      total_count
      items {
        ...OfferFragment
        Product {
          ... on Diamond {
            diamondSupplierStockId
            last_qc {
              id
              brown
              green
              milky
              eyeClean
              blue
              gray
            }
          }
        }
        hold {
          ...HoldItemFragment
        }
        __typename
      }
      certificates {
        availability
        certificate {
          certNumber
          id
          shape
          carats
          cut
          polish
          symmetry
          clarity
          color
          f_color
          f_intensity
          f_overtone
          __typename
        }
        __typename
      }
      __typename
    }
  }
  ${OfferFragment}
  ${HoldFragment}
`;

export const FETCH_MELEE_OFFERS = gql`
  query ($query: MeleeSearchQuery, $offset: Int) {
    melees: melee_offers_by_query(limit: 24, offset: $offset, query: $query)
      @connection(key: "melee_offers_by_query", filter: ["query", "order"]) {
      ...MeleeOfferListFragment
    }
  }
  ${MeleeOfferListFragment}
`;

export const DoSearch = GraphqlFactory({
  query: OFFERS_QUERY,
  map_data: ({ diamonds, ...props }) => {
    let uniq_diamond_items = diamonds && uniqBy(diamonds.items, 'id');
    return {
      ...props,
      diamonds: diamonds && uniq_diamond_items.map((x) => x['Product']),
      offers: diamonds && uniq_diamond_items,
      total_count: diamonds ? diamonds.total_count : 0, //add the uniq here.
      has_more: diamonds && uniq_diamond_items.length !== diamonds.total_count,
      certificates: diamonds && diamonds.certificates,
    };
  },
});

export const GET_MATCHING_PAIRS = gql`
  query (
    $query: DiamondQuery
    $offset: Int
    $order: DiamondOrder
    $scores: PairsScoreObject
    $limit: Int
  ) {
    diamonds: get_matching_pairs(
      limit: $limit
      offset: $offset
      query: $query
      order: $order
      scores: $scores
    ) @connection(key: "offers_by_query", filter: ["query", "order"]) {
      total_count
      items {
        ...OfferFragment
        Product {
          ... on Diamond {
            last_qc {
              id
              brown
              green
              milky
              eyeClean
              blue
              gray
            }
          }
        }
        hold {
          ...HoldItemFragment
        }
        score
      }
      certificates {
        availability
        certificate {
          certNumber
          id
          shape
          carats
          cut
          polish
          symmetry
          clarity
          color
          f_color
          f_intensity
          f_overtone
        }
      }
    }
  }
  ${OfferFragmentPairs}
  ${HoldFragment}
`;

export const DIAMOND_OFFERS_COUNT = gql`
  query ($query: DiamondQuery, $offset: Int, $order: DiamondOrder) {
    offers_by_query_count(
      limit: 24
      offset: $offset
      query: $query
      order: $order
    )
  }
`;
export const GEMSTONE_OFFERS_COUNT = gql`
  query ($query: GemstoneQuery, $offset: Int, $order: GemstoneOrder) {
    gemstone_offers_by_query_count(
      limit: 24
      offset: $offset
      query: $query
      order: $order
    )
  }
`;

export let GET_GEMSTONES_OFFER_BY_QUERY = gql`
  query GemstonesOfferByQuery(
    $query: GemstoneQuery
    $offset: Int
    $order: GemstoneOrder
  ) {
    gemstones: gemstone_offers_by_query(
      limit: 24
      offset: $offset
      query: $query
      order: $order
    ) @connection(key: "gemstone_offers_by_query", filter: ["query", "order"]) {
      total_count
      items {
        ...GemstoneOfferFragment
      }
      certificates {
        availability
        certificate {
          certNumber
          id
          shape
          carats
          cut
          clarity
          treatment
        }
      }
    }
  }
  ${GemstoneOfferFragment}
`;

export const GET_PLATFORM_PRODUCTS = gql`
  query (
    $text: String!
    $ProductType: ProductType!
    $diamond_type: ProductSubtype
  ) {
    platform_product_search_by_text(
      text: $text
      ProductType: $ProductType
      diamond_type: $diamond_type
      limit: 24
    ) {
      total_count
      items {
        ...OfferFragment
      }
    }
  }
  ${OfferFragment}
`;

export const GET_PLATFORM_DIAMOND_REQUEST = gql`
  query ($text: String!) {
    diamond_request: platform_diamond_request_search_by_text(text: $text) {
      id
      certNumber
      lab
      created_at
      status
      customer_order_number
      comment
      polish
    }
  }
`;

export const GET_PLATFORM_HOLDS = gql`
  query ($text: String!) {
    platform_hold_search_by_text(text: $text, limit: 24) {
      total_count
      items {
        ...HoldItemFragment
      }
    }
  }
  ${HoldFragment}
`;

export const GET_PLATFORM_ORDERS = gql`
  query ($query: OrderItemQuery) {
    order_items(limit: 30, query: $query) {
      type
      ...CustomerOrderList
    }
  }
  ${CustomerOrderListFragment}
`;

export const GET_PLATFORM_ORDERS_BY_TRACKING_NUMBER = gql`
  query ($text: String!) {
    platform_order_search_by_tracking_number(text: $text) {
      type
      ...CustomerOrderList
    }
  }
  ${CustomerOrderListFragment}
`;

export const GET_PLATFORM_INVOICE = gql`
  query ($query: InvoiceQuery) {
    invoices(limit: 16, query: $query) {
      total_count
      items {
        ... {
          id
          currency
          exchange_rate
          invoiced_at
          invoice_link
          invoice_number
          incentive_pay_percentage
          credit_given_by
          invoice_status
          due_date
          currency
          exchange_rate
          usd_amount
          total_amount
          total_amount_tax
          items {
            id
            customer_price
            customer_discount
            Product {
              ... on Diamond {
                id
                supplierStockId
                eyeClean
                price
                certificate {
                  image
                  lab
                  certNumber
                  shape
                  carats
                  cut
                  polish
                  symmetry
                  clarity
                  color
                  f_color
                  f_intensity
                  f_overtone
                }
              }
            }
          }
          settled
          to_country
          total_amount
          total_amount_tax
          usd_amount
          settled_amount
          invoice_type
          payment_mode
          payment_method
          customer_initiated_payment
        }
      }
    }
  }
`;
export const DoGemstoneSearch = GraphqlFactory({
  query: GET_GEMSTONES_OFFER_BY_QUERY,
  map_data: ({ gemstones, ...props }) => {
    let uniq_items = (gemstones && uniqBy(gemstones.items, 'id')) || [];

    uniq_items = uniq_items
      ?.filter((r) => r && r.Product != null)
      .map((item) => {
        return {
          ...item,
          Product: {
            ...item.Product,
            certificate: item.Product.GemstoneCertificate,
          },
        };
      });
    return {
      ...props,
      gemstones: gemstones && uniq_items.map((x) => x['Product']),
      offers: uniq_items && uniq_items,
      count: gemstones ? uniq_items.total_count : 0,
    };
  },
});

export let GET_DIAMOND_BY_ID = gql`
  query ($id: ID!) {
    diamond: diamond_by_id(id: $id) {
      id
      OrderItemId
      price
      rapaportDiscount
      supplierStockId
      diamondSupplierStockId
      brown
      green
      milky
      eyeClean
      availability
      canada_mark_eligible
      forever_mark_eligible
      delivery_time {
        min_business_days
        max_business_days
      }
      has_bgmec_enquiry
      has_media_enquiry
      location {
        id
        name
        country
      }

      supplier {
        id
        name
        company_image
        rating
        preferred
        company_settings {
          CompanyId
          accept_holds
          accept_returns
          final_price
          holds_enabled
          hold_hours
        }
      }

      offer {
        id
        price
        discount
        product_discount
        product_price
        return_price
        is_returnable
        price_per_carat
        memo_return_deadline
        fbn_details {
          id
        }
        Product {
          ... on Diamond {
            id
            price
            rapaportDiscount
            supplierStockId
            brown
            green
            blue
            gray
            availability
            milky
            eyeClean
            has_bgmec_enquiry
            has_media_enquiry
            supplier {
              id
              name
              company_settings {
                CompanyId
                accept_holds
                hold_hours
              }
            }
            delivery_time {
              min_business_days
              max_business_days
              express_timeline_applicable
            }
          }
        }
        ProductType
        hold {
          ...HoldItemFragment
        }
        memo_details {
          id
          OrderItem {
            offer {
              price_per_carat
              price
              product_discount
            }
          }
        }
      }

      certificate {
        ...CertificateFragment
      }
      last_qc {
        id
        brown
        green
        milky
        eyeClean
        blue
        gray
      }
    }
  }

  ${CertificateFragment}
  ${HoldFragment}
`;
