import { gql } from '@apollo/client';
import {
  OrderDiamondFragmentCalculator,
  OrderDiamondFragment,
  OrderDiamondFragmentPairs,
} from './DiamondFragment';
import { GemstoneFragment } from './GemstoneOfferFragment';
import { MeleeFragment } from './MeleeFragment';

export const OfferFragment = gql`
  fragment OfferFragment on Offer {
    id
    price
    price_per_carat
    discount
    product_discount
    product_price
    return_discount
    return_price
    memo_return_deadline
    total_price_without_credit_charge
    Product {
      ... on Diamond {
        ...OrderDiamond
        last_qc {
          id
          brown
          green
          milky
          eyeClean
          blue
          gray
        }
      }
      ... on Melee {
        ...MeleeFragment
        price_per_carat
        carats
        carats_ordered
      }
      ... on Gemstone {
        id
        ...GemstoneFragment
      }
    }
    fbn_details {
      id
      inventory_type
    }
    hold {
      id
    }
    ProductType
    original_offer {
      id
      price
      discount
    }
  }

  ${OrderDiamondFragment}
  ${MeleeFragment}
  ${GemstoneFragment}
`;

export const MeleeOfferListFragment = gql`
  fragment MeleeOfferListFragment on OfferList {
    total_count
    items {
      ...OfferFragment
      Product {
        ... on Melee {
          id
          # Usage: carats, carats_ordered
          # is required for carat left calculation on CX search page side
          carats
          carats_ordered
          # Usage: To show timeline
          delivery_time {
            min_business_days
            max_business_days
          }
        }
      }
    }
  }
  ${OfferFragment}
`;

export const OfferFragmentCalculator = gql`
  fragment OfferFragment on Offer {
    id
    price
    price_per_carat
    discount
    product_discount
    product_price
    return_discount
    return_price
    total_price_without_credit_charge
    Product {
      ... on Diamond {
        ...OrderDiamond
      }
      ... on Melee {
        ...MeleeFragment
        supplierStockId
        createdAt
      }
      ... on Gemstone {
        id
        dollarValue
        ...GemstoneFragment
      }
    }
    fbn_details {
      id
    }
    hold {
      id
    }
    ProductType
    original_offer {
      id
      price
      discount
    }
  }

  ${OrderDiamondFragmentCalculator}
  ${MeleeFragment}
  ${GemstoneFragment}
`;

export const OfferFragmentPairs = gql`
  fragment OfferFragment on Offer {
    id
    price
    price_per_carat
    discount
    product_discount
    product_price
    return_discount
    return_price
    total_price_without_credit_charge
    Product {
      ... on Diamond {
        ...OrderDiamond
      }
      ... on Melee {
        ...MeleeFragment
        supplierStockId
        createdAt
      }
      ... on Gemstone {
        id
        ...GemstoneFragment
      }
    }
    fbn_details {
      id
    }
    hold {
      id
    }
    ProductType
    original_offer {
      id
      price
      discount
    }
  }

  ${OrderDiamondFragmentPairs}
  ${MeleeFragment}
  ${GemstoneFragment}
`;
