export const not_required_list = [
  'shortlist',
  'quality_standard',
  'sorting',
  'delivery',
  'actions',
];
export const MELEE_MEMO_STATUS_ENUM = {
  OPEN: 'OPEN',
  CLOSED: 'CLOSED',
  PARTIALLY_CLOSED: 'PARTIALLY_CLOSED',
};
export const MELEE_MEMO_STATUS = {
  CLOSED: {
    id: 'CLOSED',
    label: 'Closed',
    value: MELEE_MEMO_STATUS_ENUM.CLOSED,
  },
  PARTIALLY_CLOSED: {
    id: 'PARTIALLY_CLOSED',
    label: 'Partially closed',
    value: MELEE_MEMO_STATUS_ENUM.PARTIALLY_CLOSED,
  },
};
export const not_required_for_supplier = [
  'qty',
  'shortlist',
  'total_ct',
  'delivered_price',
];

export const DEFAULT_MELEE_STATE = {
  cart: { cart_items: {} },
  selected: {
    selected_items: [],
  },
  sort: {
    sort_by: 'delivered_price',
    sort_ascending: true,
  },
};

export const GroupedOption = [
  {
    group: true,
    sieve_size: 'Range — (+0000-2)',
    mm_size: 'Range — (0.80-1.25)',
    carat_weight: 'Range — (0.003-0.0075)',
  },
  {
    sieve_size: '+0000 - 000',
    mm_size: '0.80 - 0.89',
    carat_weight: '0.003',
  },
  {
    sieve_size: '+000 - 00',
    mm_size: '0.90 - 0.99',
    carat_weight: '0.004',
  },
  {
    sieve_size: '+00 - 0',
    mm_size: '1.00 - 1.09',
    carat_weight: '0.005',
  },
  {
    sieve_size: '+0 - 1',
    mm_size: '1.10 - 1.14',
    carat_weight: '0.006',
  },
  {
    sieve_size: '+1 - 1.5',
    mm_size: '1.15 - 1.19',
    carat_weight: '0.0068',
  },
  {
    sieve_size: '+1.5 - 2',
    mm_size: '1.20 - 1.24',
    carat_weight: '0.0075',
  },
  {
    group: true,
    sieve_size: 'Range — (+2-6.5)',
    mm_size: 'Range — (1.25-1.80)',
    carat_weight: 'Range — (0.0085-0.02)',
  },
  {
    sieve_size: '+2 - 2.5',
    mm_size: '1.25 - 1.29',
    carat_weight: '0.0085',
  },
  {
    sieve_size: '+2.5 - 3',
    mm_size: '1.30 - 1.34',
    carat_weight: '0.01',
  },
  {
    sieve_size: '+3 - 3.5',
    mm_size: '1.35 - 1.39',
    carat_weight: '0.011',
  },
  {
    sieve_size: '+3.5 - 4',
    mm_size: '1.40 - 1.44',
    carat_weight: '0.012',
  },
  {
    sieve_size: '+4 - 4.5',
    mm_size: '1.45 - 1.49',
    carat_weight: '0.013',
  },
  {
    sieve_size: '+4.5 - 5',
    mm_size: '1.50 - 1.54',
    carat_weight: '0.0145',
  },
  {
    sieve_size: '+5 - 5.5',
    mm_size: '1.55 - 1.59',
    carat_weight: '0.016',
  },
  {
    sieve_size: '+5.5 - 6',
    mm_size: '1.60 - 1.69',
    carat_weight: '0.018',
  },
  {
    sieve_size: '+6 - 6.5',
    mm_size: '1.70 - 1.79',
    carat_weight: '0.02',
  },
  {
    group: true,
    sieve_size: 'Range — (+6.5-11)',
    mm_size: 'Range — (1.80-2.70)',
    carat_weight: 'Range — (0.025-0.074)',
  },
  {
    sieve_size: '+6.5 - 7',
    mm_size: '1.80 - 1.89',
    carat_weight: '0.025',
  },
  {
    sieve_size: '+7 - 7.5',
    mm_size: '1.90 - 1.99',
    carat_weight: '0.03',
  },
  {
    sieve_size: '+7.5 - 8',
    mm_size: '2.00 - 2.09',
    carat_weight: '0.035',
  },
  {
    sieve_size: '+8 - 8.5',
    mm_size: '2.10 - 2.19',
    carat_weight: '0.04',
  },
  {
    sieve_size: '+8.5 - 9',
    mm_size: '2.20 - 2.29',
    carat_weight: '0.045',
  },
  {
    sieve_size: '+9 - 9.5',
    mm_size: '2.30 - 2.39',
    carat_weight: '0.05',
  },
  {
    sieve_size: '+9.5 - 10',
    mm_size: '2.40 - 2.49',
    carat_weight: '0.055',
  },
  {
    sieve_size: '+10 - 10.5',
    mm_size: '2.50 - 2.59',
    carat_weight: '0.065',
  },
  {
    sieve_size: '+10.5 - 11',
    mm_size: '2.60 - 2.69',
    carat_weight: '0.074',
  },
  {
    group: true,
    sieve_size: 'Range — (+11-14)',
    mm_size: 'Range — (2.70-3.30)',
    carat_weight: 'Range — (0.078-1.09)',
  },
  {
    sieve_size: '+11 - 11.5',
    mm_size: '2.70 - 2.79',
    carat_weight: '0.078',
  },
  {
    sieve_size: '+11.5 - 12',
    mm_size: '2.80 - 2.89',
    carat_weight: '0.086',
  },
  {
    sieve_size: '+12 - 12.5',
    mm_size: '2.90 - 2.99',
    carat_weight: '0.095',
  },
  {
    sieve_size: '+12.5 - 13',
    mm_size: '3.00 - 3.09',
    carat_weight: '0.108',
  },
  {
    sieve_size: '+13 - 13.5',
    mm_size: '3.10 - 3.19',
    carat_weight: '0.116',
  },
  {
    sieve_size: '+13.5 - 14',
    mm_size: '3.20 - 3.29',
    carat_weight: '0.125',
  },
  {
    sieve_size: '+14.0 - 14.5',
    mm_size: '3.30 - 3.39',
    carat_weight: '0.150',
  },
  {
    sieve_size: '+14.5 - 15.0',
    mm_size: '3.40 - 3.49',
    carat_weight: '0.160',
  },
  {
    sieve_size: '+15.0 - 15.5',
    mm_size: '3.50 - 3.59',
    carat_weight: '0.170',
  },
  {
    sieve_size: '+15.5 - 16.0',
    mm_size: '3.60 - 3.69',
    carat_weight: '0.180',
  },
  {
    sieve_size: '+16.0 - 16.5',
    mm_size: '3.70 - 3.79',
    carat_weight: '0.190',
  },
  {
    sieve_size: '+16.5 - 17.0',
    mm_size: '3.80 - 3.89',
    carat_weight: '0.210',
  },
  {
    sieve_size: '+17.0 - 17.5',
    mm_size: '3.90 - 3.99',
    carat_weight: '0.230',
  },
  {
    sieve_size: '+17.5 - 18.0',
    mm_size: '4.00 - 4.09',
    carat_weight: '0.250',
  },
  {
    sieve_size: '+18.0 - 18.5',
    mm_size: '4.10 - 4.19',
    carat_weight: '0.270',
  },
  {
    sieve_size: '+18.5 - 19.0',
    mm_size: '4.20 - 4.29',
    carat_weight: '0.300',
  },
  {
    sieve_size: '+19.0 - 19.5',
    mm_size: '4.30 - 4.39',
    carat_weight: '0.320',
  },
  {
    sieve_size: '+19.5 - 20.0',
    mm_size: '4.40 - 4.49',
    carat_weight: '0.340',
  },
  {
    sieve_size: 'Light 3/8 (0.38-0.39)',
    mm_size: '4.50 - 4.60',
    carat_weight: '0.385',
  },
  {
    sieve_size: 'Heavy 3/8 (0.40-0.47)',
    mm_size: '4.60 - 4.90',
    carat_weight: '0.435',
  },
  {
    sieve_size: 'Light 1/2 (0.48-0.49)',
    mm_size: '4.90 - 5.00',
    carat_weight: '0.485',
  },
  {
    sieve_size: 'Heavy 1/2 (0.50-0.59)',
    mm_size: '5.00 - 5.30',
    carat_weight: '0.545',
  },
  {
    sieve_size: '5/8 (0.60-0.69)',
    mm_size: '5.30 - 5.60',
    carat_weight: '0.645',
  },
  {
    sieve_size: '3/4 (0.70-0.79)',
    mm_size: '5.60 - 5.90',
    carat_weight: '0.745',
  },
  {
    sieve_size: '4/4 (0.96-1.09)',
    mm_size: '6.20 - 6.60',
    carat_weight: '1.025',
  },
];
