import React from 'react';
import ReactDOM from 'react-dom';
import './carousel_style.css';
import { AWS360 } from '../../diamond-image/AWS360';
import {
  countdown,
  DOWNLOAD_WAIT_TIME,
} from '../diamond-grid-item/DownloadAssets';
import DiamondImage from '../../diamond-image/DiamondImage';
import { GiaModal } from '@nivoda/common';
import { useAsState } from '../../utils';
import { ShortlistButton } from '../../shortlist/ShortlistButton';
import { CustomTrans, userInReviewStatements } from '../../helpers';
import { getUserReviewStatus } from '../../utils/user';
import {
  Overlay,
  Ticker,
  theme,
  Popup,
  Tooltip,
  NotificationProgressInfo,
} from '@nivoda/ui';
import Axios from 'axios';
import { getVideoDownloadLink } from '../../graphql/queries/common/diamond-asset';
import { Trans, useTranslation } from 'react-i18next';
import { CarouselWrapper } from 'NewDashboard/components/diamond-image-modal/style';
import { Icon } from 'fabric/resources/icon';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import ZoomSlider from 'NewDashboard/components/diamond-image-modal/ZoomSlider';
import { Button } from 'fabric/components';
import { downloadImageFile } from '../../helpers';
import { DownloadProgressModal } from 'NewDashboard/components/diamond-image-modal';
import { useErrorWrapper } from '../../fabric/hooks';
import { toast } from 'react-hot-toast';
import { CarouselOld } from './Carousel-old';
import { useMoRFlag } from 'fabric/hooks/useMoRFlag';
import { isGemstoneItem } from '../../utils';
// import ColorPicker from 'diamond-image/diamond-background-picker';

const hasAsset = (assets, _type) =>
  !!assets.find((asset) => asset.type === _type);

// const is_mobile = window.innerWidth < 700;

export const CarouselContext = React.createContext({
  carousel: null,
  set_carousel: () => {},
});

export const CarouselContainer = ({ children }) => {
  const [current_carousel, set_current_carousel] = React.useState(null);

  const handleClose = () => {
    set_current_carousel(null);
  };
  let is_new = true;

  const handleClick = (event) => {
    if (event.target === event.currentTarget) {
      set_current_carousel(null);
    }
  };

  return (
    <React.Fragment>
      <CarouselContext.Provider
        value={{
          carousel: current_carousel,
          set_carousel: set_current_carousel,
        }}
      >
        {children}
      </CarouselContext.Provider>

      {current_carousel != null &&
        (is_new ? (
          <Carousel images={current_carousel} onClose={handleClose} />
        ) : (
          <Overlay>
            <div
              style={{ pointerEvents: 'auto' }}
              className="container-popup"
              onClick={handleClick}
            >
              <CarouselOld images={current_carousel} onClose={handleClose} />
            </div>
          </Overlay>
        ))}
    </React.Fragment>
  );
};

const default_notification_state = {
  message: '',
  percent: 0,
  status: '', // percent < 100 ? 'downloading' : 'completed',
  progressbar_color: 'transparent',
};

export function Carousel(props) {
  //
  //  Carousel State
  // ----------------------------------------------------------------------
  const [state, setState] = React.useState({
    activeAssetIndex: 0,
    activeAsset: hasAsset(props.images, 'v360')
      ? 'VIDEO'
      : hasAsset(props.images, 'image')
      ? 'IMAGE'
      : 'CERTIFICATE',
    show_ticker: false,
    ticker_text: '',
  });
  let { certificateIsClickable } = useMoRFlag();

  const [processing, setProcessing] = React.useState({
    video_link: null,
    status: '',
  });
  let { t } = useTranslation();
  const [progress, setProgress] = React.useState(0);
  const [downloadProgressModal, setDownloadProgressModal] =
    React.useState(false);
  const [scale, setScale] = React.useState(1);
  const [showCertModal, setShowCertModal] = React.useState(false);
  const CancelToken = Axios.CancelToken;
  const [download_status, setDownloadStatus] = useAsState({
    ...default_notification_state,
    source: CancelToken.source(),
  });

  const [fnWrapper] = useErrorWrapper({ useToastNotification: true });
  //
  //  Carousel Props
  // ----------------------------------------------------------------------
  const { images: propsImages } = props;
  const { activeAssetIndex, activeAsset, show_ticker, ticker_text } = state;

  const show_add_to_shortlist = !window.location.pathname.includes(
    '/supplier/search' || '/admin/'
  );
  const _session = localStorage.getItem('graphql_session');
  const session = _session ? JSON.parse(_session) : null;
  const { userBeingReviewed, userNeedsReview } = session
    ? getUserReviewStatus(session.user)
    : { userBeingReviewed: () => true, userNeedsReview: () => true };

  const userInReview = session
    ? !!(
        show_add_to_shortlist &&
        (userNeedsReview(session.user) || userBeingReviewed(session.user))
      )
    : true;

  const is_supplier = session ? session.user.role === 'SUPPLIER' : true;
  const is_suppliers_own_stock = is_supplier
    ? propsImages.length > 0
      ? propsImages[0].diamond.supplier.id === session.user.company.id
      : false
    : true;

  const slide = propsImages[activeAssetIndex];
  const certificate = slide.diamond && slide.diamond.certificate;

  const transformComponentRef = React.useRef(null);
  const is_gemstone = isGemstoneItem(slide.diamond);
  //
  // Carousel Change Handlers
  // ----------------------------------------------------------------------
  const closeTicker = async () => {
    await setState({
      ...state,
      show_ticker: false,
    });
  };

  const handleOnAssetItemClick = React.useCallback(
    async (type, e) => {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }
      const index = propsImages
        .map((image) => (image.type === 'v360' ? 'VIDEO' : 'IMAGE'))
        .indexOf(type);

      await setState((prevState) => ({
        ...prevState,
        activeAssetIndex: index !== -1 ? index : 0,
        activeAsset: type,
      }));
    },
    [propsImages]
  );

  const handleShortlistClick = ({ certificate, userInReview }) =>
    showTicker({
      text: userInReview ? (
        userInReviewStatements.shortlist_text
      ) : (
        <Trans
          i18nKey={'cert_added_to_shortlist'}
          values={{ CERT: `${certificate.lab} ${certificate.certNumber}` }}
        />
      ),
      // `${certificate.lab} ${certificate.certNumber} added to your shortlist.`
    });

  const showTicker = async ({ text }) => {
    toast.success(text, { style: { flexWrap: 'nowrap' } });
  };

  const startGenerateVideoReq = async () => {
    await fnWrapper(
      async () => {
        countdown(DOWNLOAD_WAIT_TIME - 0.5, setProgress);
        await setProcessing({ ...processing, status: 'PROCESSING' });

        if (!certificate) return null;
        const { data, loading } = await getVideoDownloadLink(certificate.id);
        if (!loading && data && data['get_video_download_link']) {
          await setProcessing({
            ...processing,
            status: 'COMPLETE',
            video_link: data['get_video_download_link'],
          });
          // set percentage to 100%
          await setProgress(DOWNLOAD_WAIT_TIME);
        }
      },
      null,
      {
        success: {
          messageResolver: () => t('video_link_generated').split(',')[0],
        },
        error: {
          messageResolver: () => t('generating_video_link_fail'),
        },
      }
    );
  };
  const cancelHandler = async () => {
    const NewCancelToken = Axios.CancelToken;
    download_status.source.cancel();

    await setDownloadStatus({
      ...download_status,
      message: 'Download is been canceled.',
      progressbar_color: '#9E9E9E',
      source: NewCancelToken.source(),
      status: 'error',
    });
  };
  // const downloadHandler = (e) => {
  //   if (e.status === 'downloading') {
  //     setDownloadStatus({
  //       ...download_status,
  //       message: 'testing',
  //       source: CancelToken.source(),
  //     });
  //   }
  //   if (e.status === 'completed') {
  //     setDownloadStatus({
  //       ...download_status,
  //       source: CancelToken.source(),
  //     });
  //     setProcessing({
  //       video_link: null,
  //       status: '',
  //     });
  //     setProgress(0);
  //   }
  // };

  const onImageDownloadClickHandler = () =>
    downloadImageFile({
      cert_number: certificate.certNumber,
      downloadHandler: setDownloadStatus,
      source: download_status.source,
      url: slide.diamond?.certificate?.image,
    });

  const downloadVideoFromLink = (url) =>
    downloadImageFile({
      cert_number: url.split('/')[url.split('/').length - 1],
      downloadHandler: setDownloadStatus,
      source: download_status.source,
      type: 'VIDEO',
      url: url,
    });

  const onVideoDownloadClickHandler = () => {
    setDownloadProgressModal(true);
    startGenerateVideoReq();
  };

  if (propsImages.length === 0) {
    return <div />;
  }

  const updateScale = (e) => {
    const targetScale = parseFloat(e.target.value);
    const factor = Math.log(targetScale / scale);
    const { zoomIn, zoomOut } = transformComponentRef.current;

    if (targetScale > scale) {
      zoomIn(factor, 0);
    } else {
      zoomOut(-factor, 0);
    }

    setScale(targetScale);
  };
  const handleCloseNotification = () => {
    setDownloadStatus(default_notification_state);
  };
  let certIsClickable = certificateIsClickable(slide.diamond.certificate);
  return (
    <>
      {show_ticker && <Ticker text={ticker_text} closeTicker={closeTicker} />}

      {!showCertModal && (
        <Overlay>
          <CarouselWrapper isDiamond={true}>
            <Popup
              onClose={() => props.onClose()}
              remove_top_margin={true}
              hide_title={true}
              showBorder={false}
              className="inline_crousel"
            >
              <div
                className="inline_crousel_box"
                style={{
                  minWidth: '600px',
                  maxWidth: '800px',
                  width: 'fit-content',
                }}
              >
                <div className="crousel_media_header">
                  <div className="left-box">
                    {!is_supplier && show_add_to_shortlist && (
                      <ShortlistButton
                        custom_style={{
                          height: '30px',
                          width: '30px',
                          padding: '0',
                          borderRadius: '50%',
                          defaultIconColor: '#000',
                          defaultBgColor: 'transparent',
                          defaultBorderColor: 'transparent',
                          activeIconColor: '#000',
                          activeBgColor: '#E9F2FD',
                          activeBorderColor: '#c2dbfc',
                        }}
                        section={'custom'}
                        is_new={true}
                        //ToolTip placement
                        placement={'bottomRight'}
                        displayText={false}
                        offerId={slide.diamond.offer.id}
                        onClick={handleShortlistClick.bind(null, {
                          certificate: slide.diamond.certificate,
                          userInReview,
                        })}
                      />
                    )}
                  </div>
                  <Tooltip
                    placement={'bottom'}
                    content={
                      <CustomTrans text={'close'} returnPlainText={true} />
                    }
                  >
                    <div className="right-box">
                      <Icon
                        name="close"
                        size={12}
                        className="close-button-icon"
                        onClick={() => props.onClose()}
                      />
                    </div>
                  </Tooltip>
                </div>
                <div
                  className="crousel_media_image"
                  style={{
                    height: '100%',
                    width: '100%',
                  }}
                >
                  {slide.diamond.certificate.image &&
                    activeAsset === 'IMAGE' && (
                      <TransformWrapper
                        ref={transformComponentRef}
                        initialScale={scale}
                        minScale={1}
                        maxScale={4}
                        doubleClick={{
                          disabled: true,
                        }}
                        limitToBounds={false}
                        zoomAnimation={{ disabled: true }}
                        centerOnInit
                        centerZoomedOut={true}
                        onZoom={(e) => {
                          setScale(e.state.scale);
                        }}
                      >
                        {({
                          zoomIn,
                          zoomOut,
                          // resetTransform,
                          // centerView,
                          // setTransform,
                          // ...rest
                        }) => (
                          <>
                            <TransformComponent>
                              <img
                                src={slide?.diamond?.certificate?.image}
                                alt="diamond"
                                style={{
                                  objectFit: 'contain',
                                  height: 510,
                                  width: 500,
                                }}
                              />
                            </TransformComponent>
                            <div className="zoom-component">
                              <ZoomSlider
                                updateScale={updateScale}
                                value={scale}
                                className="slider"
                                zoomIn={() => {
                                  zoomIn();
                                  setScale((scale) =>
                                    scale < 5 ? scale + 1 : scale
                                  );
                                }}
                                zoomOut={() => {
                                  zoomOut();
                                  setScale((scale) =>
                                    !(scale <= 1) ? scale - 1 : scale
                                  );
                                }}
                              />
                            </div>
                          </>
                        )}
                      </TransformWrapper>
                    )}
                  {(slide.diamond.v360 || slide.diamond.certificate.v360) &&
                    activeAsset === 'VIDEO' && (
                      <AWS360
                        v360_info={
                          slide.diamond.v360 || slide.diamond.certificate.v360
                        }
                        animate={true}
                        variant="CAROUSEL"
                      />
                    )}
                </div>
                <div className="crousel_media_thumbes">
                  {(slide.diamond.v360 || slide.diamond.certificate.v360) && (
                    <div
                      className={`crousel_media_item ${
                        activeAsset === 'VIDEO' && 'active-item'
                      }`}
                    >
                      <DiamondImage
                        styles={{
                          borderRadius: '5px',
                        }}
                        url360={
                          slide.diamond.v360 || slide.diamond.certificate.v360
                        }
                        diamond={slide.diamond}
                        small={false}
                        shape={slide.diamond.certificate.shape}
                        onlyButton={true}
                        hasRadius
                        onClick={handleOnAssetItemClick.bind(null, 'VIDEO')}
                      />
                    </div>
                  )}
                  {slide?.diamond?.certificate?.image && (
                    <div
                      className={`crousel_media_item ${
                        activeAsset === 'IMAGE' && 'active-item'
                      }`}
                    >
                      <DiamondImage
                        styles={{
                          width: '100%',
                          borderRadius: '5px',
                          objectFit: 'contain',
                        }}
                        imageUrl={slide?.diamond?.certificate?.image}
                        onClick={handleOnAssetItemClick.bind(null, 'IMAGE')}
                      />
                    </div>
                  )}

                  {(!is_supplier || (is_supplier && is_suppliers_own_stock)) &&
                    slide.diamond.certificate &&
                    certIsClickable &&
                    certificate && (
                      <div
                        className="crousel_media_item cert-center"
                        onClick={() => {
                          if (certificate.lab === 'IGI') {
                            const url = certificate.pdfUrl
                              ? certificate.pdfUrl
                              : `https://www.igi.org/reports/verify-your-report?r=${certificate.certNumber}`;
                            window.open(url, '_blank');
                          } else if (certificate.lab === 'HRD') {
                            const url = certificate.pdfUrl
                              ? certificate.pdfUrl
                              : `https://my.hrdantwerp.com/?record_number=${certificate.certNumber}`;
                            window.open(url, '_blank');
                          } else {
                            setShowCertModal(!showCertModal);
                          }
                        }}
                      >
                        <div className="eyeclean-cert">
                          <Icon name="cert-v2" />
                          <label>Cert.</label>
                        </div>
                      </div>
                    )}
                </div>
                {slide.diamond?.certificate?.image && (
                  <div className="crousel_media_actions">
                    <Button
                      className="action_button"
                      onClick={async () => {
                        // navigator.clipboard.writeText(assets.image);
                        await navigator.clipboard.writeText(
                          `https://loupe360.com/${
                            is_gemstone ? 'gemstone' : 'diamond'
                          }/${certificate.id}/img/500/500`
                        );
                        await showTicker({ text: t('image_link_copied') });
                      }}
                    >
                      <Icon name="link-icon" size={16} />
                      {t('copy_image_link')}
                    </Button>
                    <Button
                      className="action_button"
                      onClick={() => onImageDownloadClickHandler()}
                    >
                      <Icon
                        name="download-2"
                        size={16}
                        color={theme.palette.themePrimary}
                      />
                      {t('download_image')}
                    </Button>
                  </div>
                )}
                {(slide.diamond.v360 || slide.diamond.certificate.v360) && (
                  <div className="crousel_media_actions">
                    <Button
                      className="action_button"
                      onClick={async () => {
                        await navigator.clipboard.writeText(
                          `https://loupe360.com/${
                            is_gemstone ? 'gemstone' : 'diamond'
                          }/${certificate.id}/video/500/500/autoplay`
                        );
                        await showTicker({ text: t('video_link_copied') });
                      }}
                    >
                      <Icon name="link-icon" size={16} />
                      {t('copy_video_link')}
                    </Button>
                    <Button
                      className="action_button"
                      onClick={() => onVideoDownloadClickHandler()}
                    >
                      <Icon
                        name="download-2"
                        size={16}
                        color={theme.palette.themePrimary}
                      />
                      {t('download_video')}
                    </Button>
                  </div>
                )}
              </div>
            </Popup>
          </CarouselWrapper>
        </Overlay>
      )}
      {processing.status !== '' && downloadProgressModal && (
        <DownloadProgressModal
          progress={progress}
          setDownloadProgressModal={setDownloadProgressModal}
          processing={processing}
          downloadVideoFromLink={downloadVideoFromLink}
          certificateId={certificate.certNumber}
          product={slide.diamond}
          is_supplier={is_supplier}
        />
      )}

      {download_status.status && (
        <PortalItem>
          <NotificationProgressInfo
            cancelLabel={'Cancel'}
            hideDetails
            onClose={handleCloseNotification}
            onCancel={cancelHandler}
            message={t(download_status.message)}
            placement={'top'}
            progress={{
              percent: download_status.percent,
              progressbar_color: download_status.progressbar_color,
            }}
            status={download_status.status}
          />
        </PortalItem>
      )}

      {showCertModal && (
        <GiaModal
          url={certificate.pdfUrl}
          lab={certificate.lab}
          giaNumber={certificate.certNumber}
          onClick={() => {
            setShowCertModal(!showCertModal);
          }}
        />
      )}
    </>
  );
}

let PortalItem = ({ children }) => {
  return ReactDOM.createPortal(
    children,
    document.getElementById('notification')
  );
};
