import { gql } from '@apollo/client';

export const MeleeCertificateFragment = gql`
  fragment MeleeCertificate on MeleeCertificate {
    id
    certNumber
    shape
    carats
    color
    cut
    cut_type
    polish
    symmetry
    clarity
    lab
    length
    width
    depth
    height
    from_mm_size
    to_mm_size
    from_sieve_size
    to_sieve_size
    labgrown
    image
    video
    v360 {
      url
      frame_count
      renumbered
      top_index
      dl_link
    }
    floInt
    floCol
    f_color
    f_intensity
    f_overtone
    calibrated
    treated
  }
`;

export const MeleeDescriptionFragment = gql`
  fragment MeleeDescription on Melee {
    id
    MeleeCertificate: certificate {
      ...MeleeCertificate
    }
    pieces
    createdAt
    parcel # Todo this will be deprecated against clarity
    supplierStockId
    stones_per_carat
    type
  }
  ${MeleeCertificateFragment}
`;

export const MeleeUploadInvoiceDescriptionFragment = gql`
  fragment MeleeUploadInvoiceDescription on Melee {
    id
    MeleeCertificate: certificate {
      id
      certNumber
      shape
      carats
      color
      cut
      polish
      clarity
      lab
      from_mm_size
      to_mm_size
      labgrown
    }
    pieces
    createdAt
    parcel # Todo this will be deprecated against clarity
    supplierStockId
    stones_per_carat
    type
  }
`;

export const MeleeUploadInvoiceFragment = gql`
  fragment MeleeUploadInvoiceFragment on Melee {
    ...MeleeUploadInvoiceDescription
    order_item {
      id
      offer {
        id
      }
    }
    supplier {
      id
      name
      country
      supplier_status
      company_settings {
        CompanyId
        accept_returns
      }
    }
  }
  ${MeleeUploadInvoiceDescriptionFragment}
`;

export const MeleeFragment = gql`
  fragment MeleeFragment on Melee {
    ...MeleeDescription
    child_melee_collections {
      id
    }
    order_item {
      id
      offer {
        id
      }
    }
    supplier {
      id
      name
      country
      supplier_status
      company_settings {
        CompanyId
        accept_returns
      }
    }
  }
  ${MeleeDescriptionFragment}
`;

export const AdminMeleeRepoItemFragment = gql`
  fragment AdminMeleeRepoItemFragment on Melee {
    carats
    status
    source
    sourced_date
    total_carats
    total_price
    price_per_carat
    carats_ordered
    consignment_period
    rejected_carats
    cost_price_per_carat
    total_cost_price
    ...MeleeFragment
    location {
      id
    }
    SupplierMemo {
      id
      memo_number
    }
    delivery_time {
      max_business_days
    }
  }
  ${MeleeFragment}
`;

export const AdminMeleeMemoItemFragment = gql`
  fragment AdminMeleeMemoItemFragment on OrderItem {
    Product {
      ... on Melee {
        id
        status
        source
        sourced_date
        price_per_carat
        supplierStockId
        stock_returned_date
        supplier {
          id
          name
        }
        SupplierMemo {
          id
          memo_number
          status
        }
        parent_melee_collection {
          id
          carats
        }
        base_melee_collection {
          id
          carats
          carats_ordered
          total_carats
        }
      }
    }
  }
`;

export const MeleeFragmentForStockIdSearch = gql`
  fragment MeleeFragmentForStockIdSearch on Melee {
    ...MeleeFragment
    price_per_carat
    status
  }
  ${MeleeFragment}
`;
