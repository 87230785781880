import { gql } from '@apollo/client';
import { GridItemDiamondFragment } from './DiamondFragment';
import { MeleeFragment } from './MeleeFragment';
import { GemstoneFragment } from './GemstoneOfferFragment';
import { HoldsHistoricDiamondFragment } from './HistoricProductFragment';
import { NoteFragment } from './NoteFragment';

export const HoldFragment = gql`
  fragment HoldItemFragment on Hold {
    id
    buyer {
      id
      firstName
      lastName
      company {
        id
        name
      }
      __typename
    }

    Product {
      ... on Diamond {
        ...GridDiamond
        availability
        origin_country
        final_price
        has_bgmec_enquiry
        # image
        supplier_video_link
        last_diamond_update
        location {
          id
          name
          __typename
        }
        supplier {
          id
          company_image
          rating
          locations {
            id
            name
            __typename
          }
          company_settings {
            CompanyId
            accept_returns
            final_price
            holds_enabled
            accept_holds
            hold_hours
            action_other_user_hold
          }
          __typename
        }
        delivery_time {
          max_business_days
          min_business_days
          __typename
        }
        offer {
          id
          product_discount
          product_price
          price
          return_price
          return_discount
          price_per_carat
          total_price_without_credit_charge
          Product {
            ... on Diamond {
              id
              __typename
            }
            ... on Melee {
              id
              __typename
            }
            ... on Gemstone {
              id
              __typename
            }
          }
          fbn_details {
            id
          }
          original_offer {
            id
            __typename
          }
          ProductType
          __typename
        }
        supplierStockId
        __typename
      }
      ... on Melee {
        ...MeleeFragment
        location {
          id
          name
          __typename
        }
        __typename
      }
      ... on Gemstone {
        ...GemstoneFragment
      }
    }
    ProductType
    extension_until
    until
    denied
    deny_reason
    extension_status
    status
    customer_reference
    __typename
  }
  ${GridItemDiamondFragment}
  ${MeleeFragment}
  ${GemstoneFragment}
`;

export const AdminHoldFragment = gql`
  fragment AdminHoldItem on Hold {
    ...HoldItemFragment
    Product {
      ... on Diamond {
        supplier {
          website
          accept_returns
          company_settings {
            CompanyId
            website_order_placement
            communicates_through_email
            communication_email
            supplier_based_comment
          }
        }
      }
      ... on Gemstone {
        supplier {
          website
          accept_returns
          company_settings {
            CompanyId
            website_order_placement
            communicates_through_email
            communication_email
            supplier_based_comment
          }
        }
      }
    }
    assigned_to {
      id
      firstName
      lastName
      profile_image
    }
    comment
    is_historic_product
    historic_value {
      ...HoldsHistoricDiamond
    }
    converted
    enquiry {
      id
      answered
      createdAt
      requester {
        id
      }
      requested_info
      internal_status
    }
    createdAt
    request_date
  }
  ${HoldFragment}
  ${HoldsHistoricDiamondFragment}
`;

export const AdminHoldByStatusFragment = gql`
  fragment AdminHoldsByStatus on Hold {
    id
    ...AdminHoldItem
    Product {
      ... on Diamond {
        supplierStockId
        diamondSupplierStockId
        offer {
          id
          fbn_details {
            id
            OrderItem {
              id
              FbnType
            }
          }
        }
      }
      ... on Gemstone {
        id
        gemstoneSupplierStockId
      }
    }
    buyer {
      id
      firstName
      company {
        id
        name
      }
    }
    notes {
      ...AdminNote
    }
    actioned_by {
      id
      firstName
      lastName
      role
    }
    updates {
      id
      type
      value_name
      value_from
      value_to
      reverse
      action_taken
      createdAt
      user {
        id
        firstName
        lastName
      }
    }
  }
  ${AdminHoldFragment}
  ${NoteFragment}
`;
