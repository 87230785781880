import { Button, Loader } from '@nivoda/ui';
import { SpecialRequestCustomerRx } from 'graphql/factory/reactiveVar';
import { useFeatureFlags } from 'fabric/hooks';
import { featureFlagKey } from 'constants/index';
import { useReactiveVar } from '@apollo/client';
import { MailToLink } from 'fabric/components';
import { CustomTrans } from 'helpers';

export const descriptionType = {
  ADMIN_FEED_CENTER_LOADING: 'ADMIN_FEED_CENTER_LOADING',
  ADMIN_NO_FEEDS: 'ADMIN_NO_FEEDS',
  ERROR: 'ERROR',
  NO_UPLOAD_STONES: 'NO_UPLOAD_STONES',
  LOADING: 'LOADING',
  LOADING_ALL_INVOICES: 'LOADING_ALL_INVOICES',
  LOADING_INVOICES: 'LOADING_INVOICES',
  LOADING_PAYMENT_HISTORY: 'LOADING_PAYMENT_HISTORY',
  NO_ORDERS: 'NO_ORDERS',
  NO_PAYMENT_HISTORY: 'NO_PAYMENT_HISTORY',
  NO_INVENTORY: 'NO_INVENTORY',
  NO_EXPRESS_REQUESTS: 'NO_EXPRESS_REQUESTS',
  NO_ADMIN_ORDERS: 'NO_ADMIN_ORDERS',
  NO_INACTIVE_HOLDS_FOUND: 'NO_INACTIVE_HOLDS_FOUND',
  NO_INVOICE_UPDATES: 'NO_INVOICE_UPDATES',
  NO_ACTIVE_HOLDS_FOUND: 'NO_ACTIVE_HOLDS_FOUND',
  NO_ORDER_INPUT: 'NO_ORDER_INPUT',
  NO_MELEE: 'NO_MELEE',
  NO_RETURN_ITEM_INPUT: 'NO_RETURN_ITEM_INPUT',
  NO_SHIPMENT_INPUT: 'NO_SHIPMENT_INPUT',
  NO_SHIPMENT_FOUND: 'NO_SHIPMENT_FOUND',
  NO_ADDABLE_ITEM_FOUND: 'NO_ADDABLE_ITEM_FOUND',
  NO_PENDING_HOLDS_FOUND: 'NO_PENDING_HOLDS_FOUND',
  NO_PENDING_ENQUIRY_HOLDS_FOUND: 'NO_PENDING_ENQUIRY_HOLDS_FOUND',
  NO_ALL_HOLDS_FOUND: 'NO_ALL_HOLDS_FOUND',
  NO_REJECTED_HOLDS_FOUND: 'NO_REJECTED_HOLDS_FOUND',
  NO_INVOICE: 'NO_INVOICE',
  NO_INVOICES: 'NO_INVOICES',
  NO_INVOICE_FOUND: 'NO_INVOICE_FOUND',
  NO_INVOICE_TO_PAY: 'NO_INVOICE_TO_PAY',
  NO_RESULT: 'NO_RESULT',
  THREE_OR_MORE_CHARS: 'THREE_OR_MORE_CHARS',
  NO_RESULT_CERT: 'NO_RESULT_CERT',
  NO_ITEMS_IN_CART: 'NO_ITEMS_IN_CART',
  NO_ADMIN_DIAMOND_REQUEST: 'NO_ADMIN_DIAMOND_REQUEST',
  NO_NIVODA_EXPRESS_INCOMING_SHIPMENTS: 'NO_NIVODA_EXPRESS_INCOMING_SHIPMENTS',
  NO_TO_RECEIVE: 'NO_TO_RECEIVE',
  NO_TO_TAG: 'NO_TO_TAG',
  NO_TO_QC: 'NO_TO_QC',
  NO_EXPRESS_TO_STORE: 'NO_EXPRESS_TO_STORE',
  MISSING: 'MISSING',
  SOLD_OUT: 'SOLD_OUT',
  NO_PRICE_HISTORY: 'NO_PRICE_HISTORY',
  NO_STONES_FOUND_FOR_SHIPMENT: 'NO_STONES_FOUND_FOR_SHIPMENT',
  NO_RESULT_MELEE_CX: 'NO_RESULT_MELEE_CX',
  NO_CREDIT_NOTES: 'NO_CREDIT_NOTES',
  NO_FEED_CURRENTLY_CUSTOMER: 'NO_FEED_CURRENTLY_CUSTOMER',
  NO_MELEE_SR: 'NO_MELEE_SR',
  NO_REFERENCE_REPORT: 'NO_REFERENCE_REPORT',
  NO_CONSIGNMENT_HISTORY: 'NO_CONSIGNMENT_HISTORY',
  NO_ALLOCATED_INVOICES: 'NO_ALLOCATED_INVOICES',
  NO_RESULTS_FOUND: 'NO_RESULTS_FOUND',
  NO_MEMO_FEATURE_ON_GEMS: 'NO_MEMO_FEATURE_ON_GEMS',
  NO_MEMO_FEATURE_ON_MELEE: 'NO_MEMO_FEATURE_ON_MELEE',
};

export const Descriptions = (type, translate, options = {}) => {
  const specialRequestState = useReactiveVar(SpecialRequestCustomerRx);
  const rxCtx = useFeatureFlags();
  const specialRequestCXV1 =
    rxCtx.featureFlags[featureFlagKey.specialRequestCXV1];
  switch (type) {
    case descriptionType.NO_INVENTORY:
      return {
        headerText: 'No inventory found',
        primaryText:
          'Your search yielded no results. Try adjusting your search by changing or removing some filters.',
        secondaryText: '',
      };

    case descriptionType.NO_EXPRESS_REQUESTS:
      return {
        headerText: 'No express requests found',
        primaryText:
          'Your search yielded no results. Try adjusting your search by changing or removing some filters.',
        secondaryText: '',
      };

    case descriptionType.NO_TO_RECEIVE:
      return {
        headerText: 'No return items found',
        primaryText: 'No return items found to receive.',
        secondaryText: '',
      };

    case descriptionType.NO_TO_TAG:
      return {
        headerText: 'No return items found',
        primaryText: 'No received return items found to tag.',
        secondaryText: '',
      };
    case descriptionType.THREE_OR_MORE_CHARS:
      return {
        headerText: ' ',
        primaryText: 'Searching is only possible with more than 3 characters.',
        secondaryText: '',
      };

    case descriptionType.NO_TO_QC:
      return {
        headerText: 'No return items found',
        primaryText: 'No received return items found to qc.',
        secondaryText: '',
      };

    case descriptionType.NO_EXPRESS_TO_STORE:
      return {
        headerText: 'No express to store items found',
        primaryText: 'No express items found to store.',
        secondaryText: '',
      };

    case descriptionType.NO_UPLOAD_STONES:
      return {
        headerText: 'No stones found',
        primaryText:
          'Your search yielded no results. Please check your certificate numbers and try again.',
        secondaryText: '',
      };

    case descriptionType.ADMIN_FEED_CENTER_LOADING:
      return {
        headerText: 'Loading Feeds',
        primaryText: 'Please wait, while feeds are loading..',
        secondaryText: <Loader style={{ visibility: 'visible', height: 34 }} />,
      };

    case descriptionType.ADMIN_NO_FEEDS:
      return {
        headerText: 'No Feeds',
        primaryText:
          'No feeds found for requested customer, Please check the company name (in case of duplicates).',
        secondaryText: '',
      };

    case descriptionType.ERROR:
      return {
        headerText: 'Request Error',
        primaryText:
          'An unexpected error occurred, please retry by reloading the page.',
        secondaryText: '',
      };

    case descriptionType.NO_ORDERS:
      return {
        headerText: 'no_orders',
        primaryText: 'no_result_diamond_orders_page',
        secondaryText: '',
      };

    case descriptionType.NO_ADMIN_ORDERS:
      return {
        headerText: 'No Orders',
        primaryText: 'No Orders to show, Try adjusting filters.',
        secondaryText: '',
      };

    case descriptionType.NO_ORDER_INPUT:
      return {
        headerText: 'Search for order items',
        primaryText:
          'Search for the item to return using the PO/Cert number or by company details',
        secondaryText: '',
      };

    case descriptionType.NO_RETURN_ITEM_INPUT:
      return {
        headerText: 'Search for initiated returns',
        primaryText:
          'Search for the item to return using the PO/Cert number or by company details',
        secondaryText: '',
      };
    case descriptionType.NO_SHIPMENT_INPUT:
      return {
        headerText: 'Search for shipment items',
        primaryText: 'Search for the shipment by the ‘Shipping office’ name.',
        secondaryText: '',
      };

    case descriptionType.NO_SHIPMENT_FOUND:
      return {
        headerText: 'No shipment items',
        primaryText:
          'No shipments found for the selected ‘Shipping office’ name.',
        secondaryText: '',
      };

    case descriptionType.NO_ADDABLE_ITEM_FOUND:
      return {
        headerText: 'No addable items',
        primaryText: 'No returnable order items found to add in the shipment.',
        secondaryText: '',
      };

    case descriptionType.NO_RESULT:
      return {
        headerText: 'No Result',
        primaryText:
          'Try adjusting your search by changing or removing some filters.',
        secondaryText: '',
      };

    case descriptionType.NO_ACTIVE_HOLDS_FOUND:
      return {
        headerText: 'No Active holds',
        primaryText:
          'No active holds request found, Please check pending holds to accept any.',
        secondaryText: '',
      };

    case descriptionType.NO_ALL_HOLDS_FOUND:
      return {
        headerText: 'No Result',
        primaryText: 'no_result_all_holds',
        secondaryText: '',
      };

    case descriptionType.NO_INACTIVE_HOLDS_FOUND:
      return {
        headerText: 'No Inactive holds',
        primaryText:
          'No inactive holds request found, Please check pending holds to accept any.',
        secondaryText: '',
      };

    case descriptionType.NO_PENDING_HOLDS_FOUND:
      return {
        headerText: 'No Pending holds',
        primaryText: 'No pending or received holds yet.',
        secondaryText: '',
      };
    case descriptionType.NO_REJECTED_HOLDS_FOUND:
      return {
        headerText: 'No Rejected holds',
        primaryText:
          'No rejected holds request found, Please check active holds to rejected any.',
        secondaryText: '',
      };

    case descriptionType.NO_INVOICE:
      return {
        headerText: 'Empty Invoice',
        primaryText: 'No orders found for this invoice.',
        secondaryText: '',
      };

    case descriptionType.NO_INVOICES:
      return {
        headerText: '',
        primaryText: 'no_data_found',
        secondaryText: '',
      };

    case descriptionType.NO_PAYMENT_HISTORY:
      return {
        headerText: 'No payment history',
        primaryText: 'You don’t have any payment history yet.',
        secondaryText: '',
      };

    case descriptionType.NO_INVOICE_UPDATES:
      return {
        headerText: 'No invoice updates',
        primaryText: 'You don’t have any invoice update yet.',
        secondaryText: '',
      };

    case descriptionType.NO_ADMIN_DIAMOND_REQUEST:
      return {
        headerText: 'No diamond requests',
        primaryText: 'No results match with the filter criteria.',
        secondaryText: '',
      };

    case descriptionType.LOADING:
      return {
        headerText: 'Loading data',
        primaryText: 'Please wait, while data is loading...',
        secondaryText: '',
      };

    case descriptionType.LOADING_ALL_INVOICES:
      return {
        headerText: 'Loading All the Invoices',
        primaryText:
          'Please wait while all the invoices for the selection are loading...',
        secondaryText: '',
      };

    case descriptionType.LOADING_INVOICES:
      return {
        headerText: 'Loading Invoices',
        primaryText: 'Please wait while data is loading...',
        secondaryText: '',
      };

    case descriptionType.LOADING_PAYMENT_HISTORY:
      return {
        headerText: 'Loading payment history',
        primaryText: 'Please wait while data is loading...',
        secondaryText: '',
      };

    case descriptionType.NO_INVOICE_FOUND:
      return {
        headerText: 'No invoices',
        primaryText: 'No invoice found for applied query.',
        secondaryText: '',
      };

    case descriptionType.NO_INVOICE_TO_PAY:
      return {
        headerText: 'No invoices',
        primaryText: "We don't have any invoices yet.",
        secondaryText: '',
      };

    case descriptionType.NO_RESULT_CERT:
      return {
        headerText: 'No Result',
        primaryText: "We couldn't find anything matching your search.",
        secondaryText: 'Try Searching other keywords.',
      };

    case descriptionType.NO_ITEMS_IN_CART:
      return {
        headerText: 'Your cart is currently empty',
        primaryText: 'Add Diamonds directly from the search results',
        secondaryText: '',
      };

    case descriptionType.MISSING:
      return {
        headerText: 'Request Diamond',
        primaryText: `One of the diamonds you are searching for is not currently listed. 
          Request to add this diamond now and we will notify you as soon as it is ready for purchase.`,
        secondaryText: '',
      };

    case descriptionType.SOLD_OUT:
      return {
        headerText: 'Sold Out',
        primaryText:
          'Sorry, the diamond you are searching for has been sold and is no longer available.',
        secondaryText:
          'We can help you find similar stones or notify you if it becomes available again.',
      };

    case descriptionType.NO_NIVODA_EXPRESS_INCOMING_SHIPMENTS:
      return {
        headerText: 'No Nivoda express incoming shipments',
        primaryText: 'No Nivoda express incoming shipments found',
        secondaryText: '',
      };

    case descriptionType.NO_PRICE_HISTORY:
      return {
        headerText: 'No price update history',
        primaryText: 'No price update history found in system',
        secondaryText: '',
      };

    case descriptionType.NO_STONES_FOUND_FOR_SHIPMENT:
      return {
        headerText: 'No stones available for shipment',
        primaryText:
          'No Nivoda express stones found for the selected “Origin office”.',
        secondaryText: '',
      };
    case descriptionType.NO_RESULT_MELEE_CX:
      return {
        headerText: translate('no_result_found'),
        primaryText: specialRequestCXV1 ? (
          <div>
            <div>{`${translate('no_result_melee_msz1')} ${translate(
              'no_result_melee_msz2'
            )}`}</div>
            <Button
              data-testid="special_request--switchButton"
              style={{ margin: '30px 0' }}
              onClick={() => {
                SpecialRequestCustomerRx({
                  ...specialRequestState,
                  step: 'PROVIDE_DETAILS',
                });
              }}
            >
              {translate('create_special_request')}
            </Button>
          </div>
        ) : (
          <div>{`${translate('no_result_melee_msz3')}`}</div>
        ),
        secondaryText: '',
      };

    case descriptionType.NO_MELEE:
      return {
        headerText: 'No result',
        primaryText: (
          <span>
            {translate('no_result_melee_msz1')} <MailToLink />.
            {` ${translate('no_result_melee_msz2')}`}
          </span>
        ),
        secondaryText: '',
      };

    case descriptionType.NO_FEED_CURRENTLY_CUSTOMER:
      return {
        headerText: (
          <CustomTrans
            text={'no_feeds_are_active_at_the_moment'}
            returnPlainText={true}
          />
        ),
        primaryText: (
          <CustomTrans
            text={'please_contact_support_to_activate_feeds'}
            returnPlainText={true}
          />
        ),
        secondaryText: '',
      };
    case descriptionType.NO_CREDIT_NOTES:
      return {
        headerText: 'No credit note updates',
        primaryText: 'You don’t have any credit note update yet.',
        secondaryText: '',
      };
    case descriptionType.NO_MELEE_SR:
      return {
        headerText: 'No melee special request found',
        primaryText:
          'Try adjusting your search by changing or removing some filters.',
      };
    case descriptionType.NO_CONSIGNMENT_HISTORY:
      return {
        headerText: 'No consignment history found',
        primaryText: '',
        secondaryText: '',
      };
    case descriptionType.NO_ALLOCATED_INVOICES:
      return {
        headerText: 'No Results',
        primaryText: 'no_allocated_invoices',
        secondaryText: '',
      };
    case descriptionType.NO_RESULTS_FOUND:
      return {
        headerText: 'no_result_found',
        primaryText: 'please_try_another_search',
        secondaryText: 'request_message',
      };
    case descriptionType.NO_MEMO_FEATURE_ON_GEMS:
      return {
        headerText: 'No memo results found',
        primaryText:
          "Sorry, gemstones can't be ordered with memo. Try browsing a different category or switching to 'buy' mode.",
        secondaryText: '',
      };
    case descriptionType.NO_MEMO_FEATURE_ON_MELEE:
      return {
        headerText: 'No memo results found',
        primaryText:
          "Sorry, melee stones can't be ordered with memo. Try browsing a different category or switching to 'buy' mode.",
        secondaryText: '',
      };
    case descriptionType.NO_PENDING_ENQUIRY_HOLDS_FOUND:
      return {
        headerText: 'No pending enquiry',
        primaryText: 'No pending enquiry found',
        secondaryText: '',
      };
    default:
      return {
        headerText: 'No Invoices',
        primaryText:
          'Try adjusting your search by changing or removing some filters.',
        secondaryText: '',
      };
  }
};
