import { GraphqlFactory } from 'graphql/factory';
import { gql, useQuery } from '@apollo/client';
import {
  CertificateInfoFragment,
  CustomerOrderItemFragment,
  MeleeFragment,
  OfferFragment,
} from '../../fragments';
import { flatten } from 'lodash';
import {
  GemstoneCertificate,
  GemstoneFragment,
} from 'graphql/fragments/GemstoneOfferFragment';
import { ORDER_STATUS } from '../../../constants';
import { OrderDiamondFragment } from 'graphql/fragments/DiamondFragment';
export const GetOrder = GraphqlFactory({
  query: gql`
    query ($id: ID!) {
      order(id: $id) {
        id
        generate_proforma
        checkout_fee
        last_mile_fee
        last_mile_fee_usd
        items {
          id
          order_number
          expectedDeliveryDate
          checkout_option_selected
          all_in_pricing
          destination {
            id
            name
            address1
            address2
            postalCode
            city
            state
            country
          }
          return_option
          offer {
            ...OfferFragment
          }
          carats
          Product {
            ... on Diamond {
              id
              certificate {
                ...CertificateInfo
              }
              supplier {
                id
                name
                company_settings {
                  CompanyId
                  accept_returns
                }
              }
              delivery_time {
                min_business_days
                max_business_days
                express_timeline_applicable
              }
            }
            ... on Melee {
              ...MeleeFragment
              delivery_time {
                min_business_days
                max_business_days
              }
            }
            ... on Gemstone {
              id
              supplier {
                id
                name
                company_settings {
                  CompanyId
                  accept_returns
                }
              }
              delivery_time {
                min_business_days
                max_business_days
              }
              GemstoneCertificate: certificate {
                ...GemstoneCertificate
              }
            }
          }
          ProductType
          pieces
          customer_comment
          customer_order_number
        }
        order_date
        comment
      }
    }
    ${CertificateInfoFragment}
    ${MeleeFragment}
    ${GemstoneCertificate}
    ${OfferFragment}
  `,
  map_data: (data = {}) => {
    let items = data?.order?.items || [];
    let otherItems = items.filter((r) => r.ProductType !== 'Gemstone');
    let GemstonesItems = items
      .filter((r) => {
        return r.ProductType === 'Gemstone';
      })
      .map((item) => {
        return {
          ...item,
          Product: {
            ...item.Product,
            certificate: item.Product.GemstoneCertificate,
          },
        };
      });

    return {
      ...data,
      order: {
        ...data.order,
        items: [...otherItems, ...GemstonesItems],
      },
    };
  },
});

const GET_ORDER = gql`
  query ($id: ID!) {
    order(id: $id) {
      id
      generate_proforma
      checkout_fee
      last_mile_fee
      last_mile_fee_usd
      items {
        id
        order_number
        expectedDeliveryDate
        checkout_option_selected
        all_in_pricing
        is_memo_item
        memo_return_deadline
        destination {
          id
          name
          address1
          address2
          postalCode
          city
          state
          country
        }
        return_option
        offer {
          ...OfferFragment
        }
        carats
        Product {
          ... on Diamond {
            id
            certificate {
              ...CertificateInfo
            }
            supplier {
              id
              name
              company_settings {
                CompanyId
                accept_returns
              }
            }
            delivery_time {
              min_business_days
              max_business_days
              express_timeline_applicable
            }
            supplierStockId
          }
          ... on Melee {
            ...MeleeFragment
            delivery_time {
              min_business_days
              max_business_days
            }
          }
          ... on Gemstone {
            id
            supplier {
              id
              name
              company_settings {
                CompanyId
                accept_returns
              }
            }
            delivery_time {
              min_business_days
              max_business_days
            }
            supplierStockId
            GemstoneCertificate: certificate {
              ...GemstoneCertificate
            }
          }
        }
        ProductType
        pieces
        customer_comment
        customer_order_number
      }
      order_date
      comment
    }
  }
  ${CertificateInfoFragment}
  ${MeleeFragment}
  ${GemstoneCertificate}
  ${OfferFragment}
`;

export const useGetOrder = ({ id }) => {
  const { data, loading, ...rest } = useQuery(GET_ORDER, {
    variables: { id },
    errorPolicy: 'all',
  });

  let items = data?.order?.items || [];
  let otherItems = items.filter((r) => r.ProductType !== 'Gemstone');
  let GemstonesItems = items
    .filter((r) => {
      return r.ProductType === 'Gemstone';
    })
    .map((item) => {
      return {
        ...item,
        Product: {
          ...item.Product,
          certificate: item.Product.GemstoneCertificate,
        },
        offer: {
          ...item.offer,
          Product: {
            ...item.Product,
            certificate: item.Product.GemstoneCertificate,
          },
        },
      };
    });
  return {
    data: !loading
      ? {
          ...data,
          order: {
            ...data.order,
            items: [...otherItems, ...GemstonesItems],
          },
        }
      : undefined,
    loading,
    ...rest,
  };
};

export const CustomerOrderListFragment = gql`
  fragment CustomerOrderList on OrderList {
    total_count
    items {
      ...CustomerOrderItem
      offer {
        id
        fbn_details {
          id
          inventory_type
        }
      }
    }
  }
  ${CustomerOrderItemFragment}
`;

export let processGemstones = (Products) => {
  let Gems = Array.isArray(Products) ? Products : [Products];
  return Gems.map((item) => {
    if (item.ProductType !== 'Gemstone') return item;
    return {
      ...item,
      Product: {
        ...item.Product,
        certificate: item.Product.GemstoneCertificate,
      },
    };
  });
};

export const mapOrderData = (data) => {
  let order_items = data.loading ? [] : data.order_items?.items;

  order_items = flatten(
    order_items.map((order_item) => {
      let item_status = ORDER_STATUS[order_item.status];
      if (item_status == null) {
        // eslint-disable-next-line no-console
        console.log(`ORDER_STATUS:`, ORDER_STATUS);
        // eslint-disable-next-line no-console
        console.log(`un accounted order_item.status:`, order_item.status);
      }
      return { ...order_item, item_status };
    })
  ).filter((x) => x.Product != null);

  let new_order_item = processGemstones(order_items);
  return {
    ...data,
    total_count: data.loading ? 0 : data.order_items.total_count,
    order_items: new_order_item,
  };
};

export let GET_ORDER_ITEMS_COUNT = gql`
  query ($status: CustomerOrderItemStatus, $query: OrderItemQuery) {
    order_items(status: $status, query: $query) {
      total_count
    }
  }
`;
export let GET_ORDER_ITEMS = gql`
  query (
    $status: CustomerOrderItemStatus
    $query: OrderItemQuery
    $offset: Int
  ) {
    order_items(status: $status, limit: 30, offset: $offset, query: $query) {
      ...CustomerOrderList
    }
  }
  ${CustomerOrderListFragment}
`;
export const GetCustomerOrders = GraphqlFactory({
  query: GET_ORDER_ITEMS,
  map_data: mapOrderData,
});

export const GET_CUSTOMER_ORDERS_DATA = gql`
  query (
    $status: CustomerOrderItemStatus
    $offset: Int
    $limit: Int
    $query: OrderItemQuery
  ) {
    order_items(
      status: $status
      limit: $limit
      offset: $offset
      query: $query
    ) {
      ...CustomerOrderList
    }
  }
  ${CustomerOrderListFragment}
`;
// export const GET_ACTION_REQUIRED_COUNT
export const GetActionRequiredCount = GraphqlFactory({
  query: gql`
    query (
      $status: CustomerOrderItemStatus
      $offset: Int
      $query: OrderItemQuery
    ) {
      order_items(
        status: $status
        limit: 1000
        offset: $offset
        query: $query
      ) {
        total_count
      }
    }
  `,
});

export let GET_ACTION_REQUIRED_COUNT = gql`
  query (
    $status: CustomerOrderItemStatus
    $offset: Int
    $query: OrderItemQuery
  ) {
    order_items(status: $status, limit: 1000, offset: $offset, query: $query) {
      total_count
    }
  }
`;

export const GET_ORDERS_TRACKING = gql`
  query getOrdersTracking($OrderItemId: ID!) {
    get_orders_tracking(OrderItemId: $OrderItemId) {
      source
      destination
      buyer_name
      buyer_id
      order_number
      order_details {
        shipment_details {
          origin_country
          destination_country
          tracking_number
          carrier
        }
        status
        date_time
      }
    }
  }
`;

export let GetOrderItemTrack = GraphqlFactory({
  query: GET_ORDERS_TRACKING,
});

export const GET_ORDER_BY_ID = gql`
  query ($id: ID!) {
    order_item_by_id(id: $id) {
      id
      Product {
        ... on Diamond {
          ...OrderDiamond
        }
        ... on Melee {
          ...MeleeFragment
        }
        ... on Gemstone {
          ...GemstoneFragment
        }
      }
      expectedDeliveryDate
      promisedDeliveryDate
      destination {
        id
        address1
        address2
        city
        state
        country
        postalCode
      }
      latest_shipment {
        tracking_code
      }
    }
  }
  ${OrderDiamondFragment}
  ${MeleeFragment}
  ${GemstoneFragment}
`;
