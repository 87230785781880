import { GraphqlFactory } from 'graphql/factory';
import { GraphqlQuery } from 'graphql/factory';
import { gql } from '@apollo/client';
import { CertificateLongInfoFragment } from '../../fragments';
import { OrderDiamondFragment } from '../../fragments/DiamondFragment';
// import { getUserReviewStatus } from '../../../utils/user';
// import { Diamond, formatDate } from '../../../helpers';
// import { Price, PriceExchanged } from '../../../Elements';
// import React from 'react';
// import { isMobile } from 'utils';
import { mapRequestsData } from 'App/RequestStone/helpers';

export const COMPANY_ACCOUNTING_DETAILS = gql`
  query CompanyAccounting {
    me {
      id
      email
      company {
        id
        incentive_pay_active
        country_of_incorporation
        all_in_pricing
        billing_currency_preference
        company_settings {
          CompanyId
          display_supplier_name
        }
        accounting_totals {
          open_invoices {
            currency
            balance
            balance_including_tax
          }
          purchase_orders {
            currency
            balance
            balance_including_tax
          }
          confirmed_orders {
            currency
            balance
            balance_including_tax
          }
        }
        incentive_pay_totals {
          balance
          currency
        }
      }
    }
  }
`;

export const CompanyAccountingDetails = GraphqlQuery({
  query: gql`
    query CompanyAccounting {
      me {
        id
        email
        company {
          id
          incentive_pay_active
          country_of_incorporation
          all_in_pricing
          billing_currency_preference
          company_settings {
            CompanyId
            display_supplier_name
          }
          accounting_totals {
            open_invoices {
              currency
              balance
              balance_including_tax
            }
            purchase_orders {
              currency
              balance
              balance_including_tax
            }
            confirmed_orders {
              currency
              balance
              balance_including_tax
            }
          }
          incentive_pay_totals {
            balance
            currency
          }
        }
      }
    }
  `,
});

export const GET_PURCHASE_REQUESTS = gql`
  query {
    me {
      id
      default_currency
      steps_required
      company {
        id
        diamond_requests {
          id
          certNumber
          supplier_stock_id
          lab
          created_at
          status
          customer_order_number
          comment
          payment_term_selected
          delivery_location {
            name
            address1
            address2
            postalCode
            city
            state
            country
          }
          requester {
            id
            firstName
            lastName
          }
          diamond {
            id
            price
            rapaportDiscount
            # image
            offer {
              id
              price
              discount
              product_price
              product_discount
              Product {
                ...OrderDiamond
              }
            }
            location {
              id
              country
            }
            supplier {
              id
              name
            }
            supplierStockId
            certificate {
              ...CertificateLongInfo
            }
          }

          order_item {
            id
            order {
              id
            }
          }
        }
      }
    }
  }
  ${CertificateLongInfoFragment}
  ${OrderDiamondFragment}
`;

export const GET_COMPANY_USERS = gql`
  query {
    me {
      id
      company {
        id
        company_users {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export let GetPurchaseRequests = GraphqlFactory({
  query: gql`
    query {
      me {
        id
        default_currency
        steps_required
        company {
          id
          diamond_requests {
            id
            certNumber
            lab
            created_at
            status
            customer_order_number
            comment
            requester {
              id
              firstName
              lastName
            }
            diamond {
              id
              price
              rapaportDiscount
              # image
              offer {
                id
                price
                discount
                product_price
                product_discount
                Product {
                  ...OrderDiamond
                }
              }
              location {
                id
                country
              }
              supplier {
                id
                name
              }
              supplierStockId
              certificate {
                ...CertificateLongInfo
              }
            }

            order_item {
              id
              order {
                id
              }
            }
          }
        }
      }
    }
    ${CertificateLongInfoFragment}
    ${OrderDiamondFragment}
  `,
  map_data: mapRequestsData,
});

export const GetCompany = GraphqlFactory({
  query: gql`
    query {
      me {
        id
        company {
          id
          company_users {
            id
            firstName
          }
        }
      }
    }
  `,
  map_data: ({ me, ...rest }) => ({
    me: me,
    company: me ? me.company : [],
    ...rest,
  }),
});

export const GET_COMPANY_MARKUPS = gql`
  query {
    me {
      id
      company {
        id
        cfm_settings {
          Markups {
            id
            from_amount
            to_amount
            percent
            ProductSubtype
            ProductType
            markup_type
          }
          markup_preference
          markup_currency
        }
      }
    }
  }
`;
