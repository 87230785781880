import { AdminGraphqlQuery } from '../../../graphql-admin';
import { gql } from '@apollo/client';
import { NoteFragment } from 'graphql/fragments';

export let GetGIARequestsByType = AdminGraphqlQuery({
  query: gql`
    query (
      $offset: Int
      $request_type: String
      $filters: OrderRequestFilterInput
    ) {
      get_gia_request_by_request_type(
        limit: 30
        offset: $offset
        request_type: $request_type
        filters: $filters
      ) {
        total_count
        hold_count
        info_count
        buy_count
        items {
          id
          lab
          status
          internal_status
          certNumber
          customer_order_number
          create_order
          hold
          requested_info
          type
          request_type
          internal_status
          comment
          created_at
          assigned_to {
            id
            firstName
            lastName
            profile_image
          }
          requester {
            id
            firstName
            lastName
            company {
              id
              name
            }
          }
          notes {
            ...AdminNote
          }
          supplier_stock_id
          supplier_name
          payment_term_selected
          delivery_location {
            id
            country
            address1
          }
        }
      }
    }
    ${NoteFragment}
  `,
});

export let GetGIARequestsById = AdminGraphqlQuery({
  query: gql`
    query ($ids: [ID]) {
      get_gia_by_ids(ids: $ids) {
        id
        lab
        status
        internal_status
        certNumber
        supplier_stock_id
        supplier_name
        customer_order_number
        create_order
        hold
        requested_info
        type
        request_type
        internal_status
        comment
        created_at
        assigned_to {
          id
          firstName
          lastName
          profile_image
        }
        requester {
          id
          firstName
          lastName
          company {
            id
            name
            main_location
            locations {
              id
              name
              city
              country
            }
          }
        }
        diamond {
          id
          OrderItemId
        }
      }
    }
  `,
});

export let FetchGIARequestsFilters = AdminGraphqlQuery({
  query: gql`
    query {
      get_gia_request_filters {
        certNumber
        status
        assigned_to {
          id
          firstName
          lastName
        }
        customer {
          id
          company {
            id
            name
          }
        }
      }
    }
  `,
});

export const FetchRapportPrice = AdminGraphqlQuery({
  query: gql`
    query (
      $shape: String
      $color: String!
      $clarity: String!
      $carats: Float!
    ) {
      get_rapport_price(
        shape: $shape
        color: $color
        clarity: $clarity
        carats: $carats
      )
    }
  `,
});
