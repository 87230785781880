import { GraphqlMutation } from 'graphql/factory';
import { gql } from '@apollo/client';

export const GIARequest = GraphqlMutation({
  query: gql`
    mutation GIARequest(
      $certificate_number: String!
      $lab: CertificateLab
      $comment: String
      $customer_order_number: String
      $create_order: Boolean
      $hold: Int
      $requested_info: [RequestedInfoType]
      $user_id: ID
    ) {
      request_gia(
        certificate_number: $certificate_number
        lab: $lab
        comment: $comment
        customer_order_number: $customer_order_number
        create_order: $create_order
        hold: $hold
        requested_info: $requested_info
        user_id: $user_id
      ) {
        id
      }
    }
  `,
});

export const GIA_REQUEST = gql`
  mutation GIARequest(
    $certificate_number: String!
    $supplier_stock_id: String
    $lab: CertificateLab
    $comment: String
    $customer_order_number: String
    $create_order: Boolean
    $hold: Int
    $requested_info: [RequestedInfoType]
    $user_id: ID
    $supplier_name: String
    $payment_term_selected: String
    $delivery_location: ID
  ) {
    request_gia(
      certificate_number: $certificate_number
      lab: $lab
      comment: $comment
      customer_order_number: $customer_order_number
      create_order: $create_order
      hold: $hold
      requested_info: $requested_info
      user_id: $user_id
      supplier_stock_id: $supplier_stock_id
      supplier_name: $supplier_name
      payment_term_selected: $payment_term_selected
      delivery_location: $delivery_location
    ) {
      id
    }
  }
`;

export const ConciergeRequest = GraphqlMutation({
  query: gql`
    mutation (
      $lab: CertificateLab
      $certificate_type: CertificateType!
      $type: OrderRequestType
      $size: String
      $shape: String
      $cut: String
      $color: String
      $clarity: String
      $polish: String
      $symmetry: String
      $fluorescence: String
      $comment: String
      $customer_order_number: String
      $create_order: Boolean
      $user_id: ID
    ) {
      create_concierge_order_request(
        lab: $lab
        certificate_type: $certificate_type
        type: $type
        size: $size
        shape: $shape
        cut: $cut
        color: $color
        clarity: $clarity
        polish: $polish
        symmetry: $symmetry
        fluorescence: $fluorescence
        comment: $comment
        customer_order_number: $customer_order_number
        create_order: $create_order
        user_id: $user_id
      ) {
        id
        certificate_type
        type
        status
        lab
      }
    }
  `,
});
