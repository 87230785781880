import { gql } from '@apollo/client';
import { OfferFragment } from './OfferFragment';

export const CartItemFragment = gql`
  fragment CartItemFragment on CartItem {
    offer {
      ...OfferFragment
      Product {
        ... on Melee {
          id
          # Usage: carats, carats_ordered
          # is required for carat left calculation on CX search page side
          carats
          carats_ordered
        }
      }
    }
    destination {
      id
    }
    delivery_type
    delivery_time {
      express_timeline_applicable
      min_business_days
      max_business_days
    }
    specific_date
    added_at
    return_option
    customer_order_number
    customer_comment
    carats
    pieces
  }
  ${OfferFragment}
`;
