import { Tooltip } from '@nivoda/ui';
import { Icon } from 'fabric/resources/icon';
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { tFn } from 'utils';

const CopyToClipboardWrapper = styled.div`
  display: flex;
  .copy_icon {
    cursor: pointer;
    color: ${({ iconColor }) => iconColor || '#a79595'};
    &:hover {
      color: green;
    }
  }
`;
export default function CopyToClipboard({
  text,
  copyContent,
  iconName,
  iconHeight,
  iconWidth,
  iconSize,
  iconColor,
  toolTipStyles,
  placement,
  noTranslate = false,
}) {
  const { t } = useTranslation();
  let method = noTranslate ? null : t;

  const [isCopy, setIsCopy] = React.useState(false);
  return (
    <CopyToClipboardWrapper
      className="copy__to__clipboard"
      data-automation-id="copy__to__clipboard"
      iconColor={iconColor}
    >
      <Tooltip
        content={
          <div
            style={{ width: 'max-content', fontSize: '12px', ...toolTipStyles }}
          >
            {isCopy
              ? `${tFn(method, 'copied', 'Copied')} ${text}`
              : `${tFn(method, 'click_to_copy', 'Click to copy')} ${text}`}
          </div>
        }
        placement={placement ? placement : 'top'}
      >
        <Icon
          name={iconName}
          className="copy_icon"
          // width={13}
          // height={12}
          color={iconColor}
          width={iconWidth}
          height={iconHeight}
          size={iconSize}
          onClick={(e) => {
            e.stopPropagation();
            if (copyContent) {
              navigator.clipboard.writeText(copyContent);
            } else {
              navigator.clipboard.writeText(text);
            }
            setIsCopy(true);
          }}
          onMouseLeave={() => setIsCopy(false)}
        />
      </Tooltip>
    </CopyToClipboardWrapper>
  );
}
