import React, { useCallback } from 'react';
import './carousel_style.css';
import styled from 'styled-components';
import { AWS360 } from '../../diamond-image/AWS360';

import {
  countdown,
  CustomBtnWithIcon,
  download__assets_action_list,
  DOWNLOAD_WAIT_TIME,
  GenerateLinkModal,
  getAssetList,
  getDownloadLabel,
  handleActionClick,
} from '../diamond-grid-item/DownloadAssets';
import DiamondImage from '../../diamond-image/DiamondImage';
import { GiaModal } from '@nivoda/common';
import { dummyFn, useAsState } from '../../utils';
import { ShortlistButton } from '../../shortlist/ShortlistButton';
import { userInReviewStatements } from '../../helpers';
import { getUserReviewStatus } from '../../utils/user';
import { Icon, NotificationProgressInfo, Ticker, theme } from '@nivoda/ui';
import Axios from 'axios';
import {
  getGemsVideoDownloadLink,
  getVideoDownloadLink,
} from '../../graphql/queries/common/diamond-asset';
import { round } from 'lodash/math';
import { downloadTypes } from '../../constants/download';
import { useNotificationClearEffect } from '../../fabric/hooks';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';

const hasAsset = (assets, _type) =>
  !!assets.find((asset) => asset.type === _type);

const is_mobile = window.innerWidth < 700;

export const CarouselContext = React.createContext({
  carousel: null,
  set_carousel: () => {},
});

const SlideImage = ({ url }) => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        backgroundImage: `url("${url}")`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    />
  );
};

const V360 = ({ url, active }) => {
  if (active) {
    return <AWS360 v360_info={url} animate={true} />;
  } else {
    return null;
  }
};

const CertSlide = ({ cert, onClick }) => (
  <GiaModal
    url={cert.pdfUrl}
    lab={cert.lab}
    giaNumber={cert.certNumber}
    onClick={onClick}
  />
);

const default_notification_state = {
  message: '',
  percent: 0,
  status: '', // percent < 100 ? 'downloading' : 'completed',
  progressbar_color: 'transparent',
};

export function CarouselOld(props) {
  //
  //  Carousel State
  // ----------------------------------------------------------------------
  const [state, setState] = React.useState({
    activeAssetIndex: 0,
    activeAsset: hasAsset(props.images, 'image')
      ? 'IMAGE'
      : hasAsset(props.images, 'v360')
      ? 'VIDEO'
      : 'CERTIFICATE',
    show_ticker: false,
    ticker_text: '',
  });
  const [processing, setProcessing] = React.useState({
    video_link: null,
    status: '',
  });
  let { t } = useTranslation();
  const [progress, setProgress] = React.useState(0);

  const CancelToken = Axios.CancelToken;
  const [download_status, setDownloadStatus] = useAsState({
    ...default_notification_state,
    source: CancelToken.source(),
  });

  const is_processing = processing.status === 'PROCESSING';
  const has_error = processing.status === 'ERROR';
  const loaded = processing.status === 'COMPLETE';

  //
  //  Carousel Props
  // ----------------------------------------------------------------------
  const { images: propsImages } = props;
  const { activeAssetIndex, activeAsset, show_ticker, ticker_text } = state;

  const show_add_to_shortlist = !window.location.pathname.includes(
    '/supplier/search' || '/admin/'
  );
  const _session = localStorage.getItem('graphql_session');
  const session = _session ? JSON.parse(_session) : null;
  const { userBeingReviewed, userNeedsReview } = session
    ? getUserReviewStatus(session.user)
    : { userBeingReviewed: () => true, userNeedsReview: () => true };

  const userInReview = session
    ? !!(
        show_add_to_shortlist &&
        (userNeedsReview(session.user) || userBeingReviewed(session.user))
      )
    : true;

  const is_supplier = session ? session.user.role === 'SUPPLIER' : true;
  const is_suppliers_own_stock = is_supplier
    ? propsImages.length > 0
      ? propsImages[0].diamond.supplier.id === session.user.company.id
      : false
    : true;

  const newImages = propsImages.map((image) => {
    if (image.type === 'image') {
      return {
        Component: SlideImage,
        props: image,
      };
    }
    if (image.type === 'v360') {
      return {
        Component: V360,
        props: image,
      };
    }
    throw new Error('Unknown slide type for carousel');
  });

  const slide = newImages[activeAssetIndex];
  const diamond = slide?.props?.diamond || {};
  //
  // DownloadAssets Getters
  // ----------------------------------------------------------------------
  // const getSubtitle = () => {
  //   console.log(t, 'this is i');
  //   return is_processing ? (
  //     <span className={'loading_message'}>
  //       <Icon name={'loader'} color={theme.palette.themePrimary} size={16} />
  //       <span>{t('processing_video_for_download')`... (${round(
  //         (progress / DOWNLOAD_WAIT_TIME) * 100
  //       )}%)`}</span>
  //     </span>
  //   ) : loaded ? (
  //     t('video_link_generated')
  //   ) : // 'Video link generated, click on download to save the video.'
  //   has_error ? (
  //     // 'Generating video link failed, Please try again in some time (504-Timed out)'
  //     t('generating_video_link_fail')
  //   ) : (
  //     ''
  //   );
  // };
  const getSubtitle = useCallback(() => {
    return is_processing ? (
      <span className={'loading_message'}>
        <Icon name={'loader'} color={theme.palette.themePrimary} size={16} />
        <span>
          {t('processing_video_for_download')}...
          {round((progress / DOWNLOAD_WAIT_TIME) * 100)}%
        </span>
      </span>
    ) : loaded ? (
      t('video_link_generated')
    ) : has_error ? (
      t('generating_video_link_fail')
    ) : (
      ''
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [has_error, loaded, is_processing, progress]);
  const getTitle = () =>
    !loaded && is_processing
      ? `${t('generating_link')} `
      : `${t('video_link_for')} ${t('video')}`;
  // : `${t('video_link_for')} ${diamond.supplierStockId}`;

  //
  // Carousel Change Handlers
  // ----------------------------------------------------------------------
  const closeTicker = async () => {
    await setState({
      ...state,
      show_ticker: false,
    });
  };

  const cancelHandler = async () => {
    const NewCancelToken = Axios.CancelToken;
    download_status.source.cancel();

    await setDownloadStatus({
      ...download_status,
      message: 'Download is been canceled.',
      progressbar_color: '#9E9E9E',
      source: NewCancelToken.source(),
      status: 'error',
    });
  };

  const handleAction = async (targetId, e) => {
    e.persist();
    if (targetId === downloadTypes.DOWNLOAD_VIDEO) {
      // close the generating link modal
      await setProcessing({ ...processing, status: '' });
    }

    // trigger the actual download
    handleActionClick.bind(null, {
      certNumber: diamond.certificate.certNumber,
      downloadStateHandler: setDownloadStatus,
      imageUrl: diamond.certificate.image,
      setProcessing,
      source: download_status.source,
      video_url: processing.video_link,
    })({
      preventDefault: e.preventDefault || dummyFn,
      stopPropagation: e.stopPropagation || dummyFn,
      target: { dataset: { targetId } },
    });
  };

  const handleClose = async (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    await setProcessing({ ...processing, status: '' });
  };

  const handleOnAssetItemClick = React.useCallback(
    async (type, e) => {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }

      const index = propsImages
        .map((image) => (image.type === 'v360' ? 'VIDEO' : 'IMAGE'))
        .indexOf(type);

      await setState((prevState) => ({
        ...prevState,
        activeAssetIndex: index !== -1 ? index : 0,
        activeAsset: type,
      }));
    },
    [propsImages]
  );

  const handleCloseNotification = () => {
    setDownloadStatus(default_notification_state);
  };

  const handleOnClose = async (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    await setState({
      ...state,
      activeAsset: hasAsset(props.images, 'image')
        ? 'IMAGE'
        : hasAsset(props.images, 'v360')
        ? 'VIDEO'
        : 'CERTIFICATE',
    });
  };

  const handleShortlistClick = ({ certificate, userInReview }) =>
    showTicker({
      text: userInReview
        ? userInReviewStatements.shortlist_text
        : `${certificate.lab} ${certificate.certNumber} added to your shortlist.`,
    });

  const showTicker = async ({ text }) => {
    toast.success(text, { style: { flexWrap: 'nowrap' } });
  };

  const startGenerateVideoReq = async () => {
    let is_gemstones = window.location.pathname.includes('/gemstones');
    let key = is_gemstones
      ? 'get_gemstone_video_download_link'
      : 'get_video_download_link';
    let method = is_gemstones ? getGemsVideoDownloadLink : getVideoDownloadLink;
    try {
      // show generating link modal
      await setProcessing({ ...processing, status: 'PROCESSING' });
      // start the percentage counter
      countdown(DOWNLOAD_WAIT_TIME - 0.5, setProgress);

      // call generate link query on BE

      const { data, loading } = await method(diamond.certificate.certNumber);
      // success
      if (!loading && data && data[key]) {
        await setProcessing({
          ...processing,
          status: 'COMPLETE',
          video_link: data[key],
        });
        // set percentage to 100%
        await setProgress(DOWNLOAD_WAIT_TIME);
      }
    } catch (e) {
      // handle error
      // eslint-disable-next-line no-console
      console.error(
        'Not cool, Something went wrong while downloading video',
        e
      );
      await setProcessing({ ...processing, status: 'ERROR' });
      await setProgress(0);
    }
  };

  useNotificationClearEffect(download_status, setDownloadStatus, CancelToken);

  if (newImages.length === 0) {
    return <div />;
  }

  const button_mapper_function = (action) => {
    const is_video_processing =
      action.id === downloadTypes.DOWNLOAD_VIDEO &&
      diamond.certificate.certNumber &&
      is_processing;

    const renderActionButtonItem = () => (
      <CustomBtnWithIcon
        key={action.id}
        className={'carousel_asset__action'}
        disabled={is_processing || false}
        btnProps={{
          customPadding: '7px 12px',
        }}
        iconProps={{
          color: is_video_processing ? theme.palette.themePrimary : '#bdbdbd',
          hoverColor: theme.palette.themePrimary,
          name: is_video_processing ? 'loader' : action.icon,
          noShadow: true,
          size: 18,
        }}
        actionId={action.id}
        label={getDownloadLabel({ action, processing, trans: t })}
        onClick={
          action.id === downloadTypes.DOWNLOAD_VIDEO
            ? startGenerateVideoReq
            : handleActionClick.bind(null, {
                certNumber: diamond.certificate.certNumber,
                downloadStateHandler: setDownloadStatus,
                imageUrl: diamond.certificate.image,
                source: download_status.source,
                video_url_query: null,
              })
        }
        title={action.label}
      />
    );

    return renderActionButtonItem();
  };

  const asset_list = diamond ? getAssetList(diamond) : [];
  return (
    <CarouselDiv className="container-popup-img" id="imagePopup">
      <div className="carousel_asset__box">
        {show_ticker && <Ticker text={ticker_text} closeTicker={closeTicker} />}
        <div className="carousel_asset__box--preview new_carousel">
          <div className="carousel_asset__item">
            {activeAsset !== 'CERTIFICATE' && (
              <slide.Component {...slide.props} active={true} />
            )}
            {activeAsset === 'CERTIFICATE' &&
              (!is_supplier || (is_supplier && is_suppliers_own_stock)) && (
                <CertSlide cert={diamond.certificate} onClick={handleOnClose} />
              )}
            {show_add_to_shortlist && diamond && diamond.offer && (
              <ShortlistButton
                offerId={diamond.offer.id}
                onClick={handleShortlistClick.bind(null, {
                  certificate: diamond.certificate,
                  userInReview,
                })}
              />
            )}
          </div>
        </div>
        {/* asset item switcher buttons */}
        <div className="carousel_asset__items">
          {(!is_supplier || (is_supplier && is_suppliers_own_stock)) &&
            diamond.certificate && (
              <CustomBtnWithIcon
                className={`${
                  activeAsset === 'CERTIFICATE' ? 'active' : ''
                } carousel_asset__item--cert`}
                btnProps={{
                  customPadding: '7px 12px',
                  hasBorder: true,
                }}
                iconProps={{
                  color: '#bdbdbd',
                  hoverColor: theme.palette.themePrimary,
                  name: 'certificate',
                  noShadow: true,
                  size: 18,
                }}
                onClick={handleOnAssetItemClick.bind(null, 'CERTIFICATE')}
                label={'Certificate'}
                title={'Certificate'}
              />
            )}

          {diamond && diamond.certificate.image && (
            <div
              className={`carousel_asset__item--image  ${
                activeAsset === 'IMAGE' ? 'active' : ''
              }`}
              onClick={handleOnAssetItemClick.bind(null, 'IMAGE')}
            >
              <DiamondImage
                diamond={diamond}
                imageUrl={diamond.certificate.image}
                small={false}
                showIcon={false}
                shape={diamond.certificate.shape}
                onlyButton={true}
                hasRadius
              />
            </div>
          )}
          {diamond && diamond.certificate && diamond.certificate.v360 && (
            <div
              className={`carousel_asset__item--video ${
                activeAsset === 'VIDEO' ? 'active' : ''
              }`}
              onClick={handleOnAssetItemClick.bind(null, 'VIDEO')}
            >
              <DiamondImage
                diamond={diamond}
                imageUrl={diamond.certificate.image}
                url360={diamond.v360 || diamond.certificate.v360}
                small={false}
                showIcon
                shape={diamond.certificate.shape}
                onlyButton={true}
                hasRadius
              />
            </div>
          )}
        </div>
      </div>
      {/* copy and download actions */}
      <div className="carousel_asset__action--box">
        {asset_list.length > 0 &&
          download__assets_action_list
            .filter((asset) => asset_list.includes(asset.id))
            .map(button_mapper_function)}
      </div>

      <button onClick={props.onClose} id="closePopup_search">
        {t('close')} <Icon name={'close'} size={10} color={'#212121'} />
      </button>

      {download_status.status && (
        <NotificationProgressInfo
          cancelLabel={'Cancel'}
          hideDetails
          onClose={handleCloseNotification}
          onCancel={cancelHandler}
          message={download_status.message}
          placement={is_mobile ? 'bottom' : 'top'}
          progress={{
            percent: download_status.percent,
            progressbar_color: download_status.progressbar_color,
          }}
          status={download_status.status}
        />
      )}

      {processing.status !== '' && (
        <GenerateLinkModal
          hasError={has_error}
          loaded={loaded}
          onClick={handleAction.bind(null, downloadTypes.DOWNLOAD_VIDEO)}
          onClose={handleClose}
          subtitle={getSubtitle()}
          title={getTitle()}
        />
      )}
    </CarouselDiv>
  );
}

const CarouselDiv = styled.div`
  &.container-popup-img {
    max-width: 680px;
    margin: 0 auto;
    max-height: 90vh;
  }

  #closePopup_search {
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;
    display: flex;
    align-items: center;
    gap: 5px;
    border: 1px solid #e0e0e0;
    border-radius: 3px;
    background: #ffffff;
    font-size: 20px;
    line-height: 32px;
    color: #212121;
    outline: none;
    padding: 0 8px;

    svg {
      margin-top: -3px;
    }

    &:hover {
      box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.4);
      border: 1px solid #e0e0e0;
      outline: none;
    }

    @media (max-width: 700px) {
      right: 10px;
      top: 10px;
      font-size: 14px;
      line-height: 19px;
    }
  }

  .carousel {
    &_asset {
      &__action {
        &--box {
          width: 600px;
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          gap: 10px;
          margin-top: 17px;

          @media (max-width: 400px) {
            display: flex;
            flex-wrap: wrap;
            width: auto;
            margin: 0;
            gap: 5px;
            align-items: center;

            & > button:not(:first-child) {
              margin: 0;
            }

            & > button:first-child {
              margin-left: 0;
              margin-right: 0;
            }
          }
        }

        &--btn.btn.btn-ghost {
          border: 1px solid #eeeeee;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;

          svg {
            margin-top: -3px;
          }

          &:hover {
            & {
              border: 1px solid ${theme.palette.themePrimary};
            }

            & svg,
            & .carousel_asset__action--label {
              color: ${theme.palette.themePrimary};
            }
          }
        }
      }

      &__box {
        display: flex;
        width: 100%;
        margin: 0 auto;
        position: relative;
        @media (max-width: 400px) {
          flex-direction: column;
        }

        &--preview {
          position: relative;
          width: 100%;
          max-width: 615px;
          height: 615px;
          overflow: hidden;

          @media (max-width: 400px) {
            height: 340px;
          }
        }
      }

      &__item {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        overflow: hidden;
        transition: transform 0.2s;
        background: rgb(255, 255, 255);
        background: linear-gradient(90deg, #ffffff 0%, #eaeaea 0%);
        border-radius: 6px;

        &--cert--btn,
        &--image,
        &--video {
          width: 56px;
          height: 56px;
          border-radius: 6px;
          border: 1px solid #e0e0e0;

          &.active {
            color: ${theme.palette.themePrimary};
            border-radius: 6px;
            border: 1px solid ${theme.palette.themePrimary} !important;
          }
        }

        &--image,
        &--video {
          &:hover {
            box-shadow: 0 3px 8px 0 rgb(0 0 0 / 17%);
          }
        }

        &--video {
          .video_icon_v360 {
            top: 12px !important;
          }

          & > div {
            border-radius: 6px !important;
          }

          .aws_wrapper {
            width: 56px !important;
            height: 56px !important;
          }
        }

        &--cert--btn {
          background: #ffffff;
          display: grid;
          font-style: normal;
          font-weight: 500;
          font-size: 9px;
          line-height: 12px;
          color: #212121;
          align-items: center;
          justify-content: center;
          justify-items: center;

          &:hover {
            &,
            & svg {
              color: ${theme.palette.themePrimary};
            }
          }
        }

        .video_icon_v360 {
          top: 10px !important;
        }
      }

      &__items {
        display: inline-flex;
        flex-direction: column;
        justify-content: flex-end;
        gap: 10px;
        margin-left: 20px;

        @media (max-width: 400px) {
          justify-content: start;
          flex-direction: row;
          margin-left: 0;
          align-items: center;
        }
      }
    }
  }
`;
