import { gql } from '@apollo/client';

import {
  ShortlistItemFragment,
  MiniShortlistItemFragment,
} from '../../fragments/ShortlistFragment';

export let GET_SHORTLIST_ITEMS = gql`
  query {
    me {
      id
      shortlist_items {
        ...ShortlistItemFragment
        offer {
          id
          hold {
            status
            id
            buyer {
              id
            }
          }
        }
      }
      shortlist_memo_items {
        ...ShortlistItemFragment
        offer {
          id
          hold {
            status
            id
            buyer {
              id
            }
          }
        }
      }
    }
  }
  ${ShortlistItemFragment}
`;

export let GET_MINI_SHORTLIST_ITEMS = gql`
  query {
    me {
      id
      shortlist_items {
        ...MiniShortlistItemFragment
      }
      shortlist_memo_items {
        ...MiniShortlistItemFragment
      }
    }
  }
  ${MiniShortlistItemFragment}
`;

export const GET_SHORTLIST_GROUPS_ITEMS = gql`
  query getShortlistItemsCount {
    me {
      id
      shortlist_groups {
        id
        group_name
        shortlist_items {
          ...ShortlistItemFragment
        }
      }
    }
  }
  ${ShortlistItemFragment}
`;
