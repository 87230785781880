import React from 'react';
import { uniqBy } from 'lodash';
import { Track } from 'fabric/integrations';

import { SET_MINI_SHORTLIST_ITEMS } from 'graphql/mutations/common/shortlist';
import { round } from 'lodash/math';
import { gql, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { graphqlClient } from 'graphql/factory';

import { GET_MINI_SHORTLIST_ITEMS } from 'graphql/queries/common/shortlist';
import { ProductTypes } from '../constants';
import { dashboardModeStateRx } from 'graphql/factory/reactiveVar';
import { useAppSession } from 'graphql/queries/common';
import { useLocation } from '@reach/router';
import { getSource } from 'helpers';
// import { GemstoneOfferFragment } from 'graphql/fragments';

const precondition = (condition, message) => {
  if (!condition) {
    throw new Error(message);
  }
};

export const GlobalShortlistContext = React.createContext({
  get_from_shortlist: () => {},
  items_in_shortlist: [],
  set_items: () => {},
  update_shortlist_item: () => {},
  shortlist_counter: {
    shortlist: 0,
    memoShortlist: 0,
  },
  gemstone_shortlists: [],
  melee_shortlists: [],
  is_shortlist_loading: false,
});

let validate_item = (item) => {
  precondition(item.offerId != null, `item.diamond.id needs to be set`);
  precondition(item.added != null, `item.added needs to be the timestamp`);
};

let FetchShortlistItem = ({ children }) => {
  let { data, error, loading } = useQuery(GET_MINI_SHORTLIST_ITEMS, {
    client: graphqlClient,
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
  });

  let map_data = (data) => {
    let { me = {} } = data;
    const _shortlists = [...(me?.shortlist_items ?? [])];
    const _memoShortlists = [...(me?.shortlist_memo_items ?? [])];

    const shortlists = (_shortlists, is_memo = false) =>
      uniqBy(
        _shortlists
          .filter((item) => item?.offer?.Product)
          .map((r) => {
            return {
              ...r,
              offerId: r.offer.Product.id,
              is_memo,
            };
          }),
        (x) => `${x?.offerId}-${x?.is_memo}`
      );

    return {
      ...data,
      me: {
        ...data.me,
        shortlist_items: shortlists(_shortlists),
        shortlist_memo_items: shortlists(_memoShortlists, true),
      },
    };
  };

  let newData = data != null && map_data(data);

  let result = Object.assign({}, newData, { error, loading });
  return children(result);
};

export let ShortlistProvider = (props) => {
  const { isMemoMode } = useReactiveVar(dashboardModeStateRx);
  let [is_shortlist_loading, set_shortlist_loading] = React.useState();
  let [set_shortlist_items, { client: store }] = useMutation(
    SET_MINI_SHORTLIST_ITEMS,
    {
      client: graphqlClient,
    }
  );
  // TODO use same file as in the shortlistFragment
  const Fragment = gql`
    fragment MiniOfferFragment on Offer {
      id
      ProductType
      Product {
        ... on Diamond {
          id
        }
        ... on Melee {
          id
        }
        ... on Gemstone {
          id
        }
      }
    }
  `;

  return (
    <FetchShortlistItem>
      {({ me, loading }) => {
        if (me == null) {
          return null;
        }

        const _shortlistItems = [...(me?.shortlist_items ?? [])];
        const _memoShortlistItems = [...(me?.shortlist_memo_items ?? [])];

        const allShortlistItems = [..._shortlistItems, ..._memoShortlistItems];

        let items_in_shortlist = uniqBy(
          allShortlistItems
            // filter items with no offers
            .filter((item) => item.offer && item.offer.Product)
            .map((item) => {
              return {
                id: item.id,
                added: item.added_at,
                carats: item.carats, // can be removed
                offer: item.offer,
                offerId: item.offer.id,
                pieces: item.pieces || 0, // c an be removed
                note: item.note,
                is_memo: item.is_memo,
                __typename: item.__typename,
              };
            }),
          (x) => `${x?.offerId}-${x?.is_memo}`
        );

        let value = {
          loading,
          is_shortlist_loading: is_shortlist_loading,
          items_in_shortlist: items_in_shortlist,

          set_shortlist_loading: (is_shortlist_loading) => {
            set_shortlist_loading(is_shortlist_loading);
          },
          shortlist_counter: {
            shortlist: _shortlistItems.length || 0,
            memoShortlist: _memoShortlistItems.length || 0,
          },
          set_items: async (items, isMemo) => {
            // Make sure we don't add two items with same id?
            let uniq_items = uniqBy(
              items,
              (x) => `${x?.offerId}-${x?.is_memo}`
            );
            if (uniq_items.length !== items.length) {
              throw new Error(
                `Trying to add an items that already exists to shortlist?!`
              );
            }

            // eslint-disable-next-line no-unused-vars
            for (let item of items) {
              validate_item(item);
            }

            set_shortlist_loading(true);

            const isMemoModeOn = isMemo ?? isMemoMode;

            let variables = {
              items: items
                ?.filter((item) => item?.is_memo === isMemoModeOn)
                ?.map((item) => ({
                  id: item.id,
                  added_at: item.added,
                  carats: item.carats || 0,
                  pieces: round(item.pieces) || 0,
                  offer_id: item.offerId,
                  note: item.note,
                })),
              is_memo: isMemoModeOn ? true : false,
            };

            try {
              await set_shortlist_items({
                variables,
                optimisticResponse: ({ items = [], is_memo }) => {
                  let { me } = store.readQuery({
                    query: gql`
                      query {
                        me {
                          id
                        }
                      }
                    `,
                  });

                  //TODO holds fragment is missing.
                  let results = items?.map((x) => {
                    let product_type = x.offer_id.includes('DIAMOND')
                      ? ProductTypes.DIAMOND
                      : x.offer_id.includes('GEMSTONE')
                      ? ProductTypes.GEMSTONE
                      : ProductTypes.MELEE;

                    let offerName = 'Offer';
                    const variables = {
                      id: `${offerName}:${x.offer_id}`,
                      fragment: Fragment,
                      fragmentName: 'MiniOfferFragment',
                    };

                    // // Read the cached offer from apollo-cache
                    let offerData = store.readFragment(variables);

                    const melee_offer_data = store.readFragment({
                      id: `Offer:${x.offer_id}`,
                      fragment: Fragment,
                      fragmentName: 'MiniOfferFragment',
                    });

                    let baseOffer = {
                      __typename: 'Offer',
                      id: x.offer_id,
                      ProductType: product_type,
                      Product: {
                        id: x.offer_id.split('/')[1],
                      },
                    };
                    return {
                      id: x.id ?? 'shortlist-temp-id-1',
                      added_at: x.added_at,
                      carats: 0,
                      pieces: 0,
                      note: null,
                      melee_collection_offer:
                        product_type === ProductTypes.MELEE
                          ? melee_offer_data
                          : null,
                      offer: offerData ?? baseOffer,
                      ProductType: product_type,
                      is_memo,
                      __typename: 'ShortlistItem',
                    };
                  });

                  let updateShortListData = {
                    __typename: 'User',
                    id: me.id,
                  };

                  if (isMemoModeOn)
                    updateShortListData.shortlist_memo_items = results;
                  else updateShortListData.shortlist_items = results;

                  return {
                    update_shortlist: updateShortListData,
                  };
                },
              });
            } catch (err) {
              // eslint-disable-next-line no-console
              console.log('show error on item merge to Shortlist', err);
            }

            set_shortlist_loading(false);
          },
        };

        return (
          <GlobalShortlistContext.Provider value={value}>
            {typeof props.children === 'function'
              ? props.children(value)
              : props.children}
          </GlobalShortlistContext.Provider>
        );
      }}
    </FetchShortlistItem>
  );
};
export let useShortlistContext = (props = {}) => {
  let {
    is_shortlist_loading,
    items_in_shortlist,
    set_items,
    set_shortlist_loading,
    shortlist_counter,
  } = React.useContext(GlobalShortlistContext);
  const { isMemoMode } = useReactiveVar(dashboardModeStateRx);
  const url = useLocation();
  const { data } = useAppSession();

  const { isNaturalView, isDiamondPage } = data?.appSession;

  let offerId = props?.offerId;

  // This are the shortlist Info that are accessible without the OfferId.
  let general = {
    shortlist_counter: shortlist_counter,
    items_in_shortlist,
    is_shortlist_loading,
    set_items: set_items,
    remove_selected: async (items) => {
      if (offerId && typeof offerId === 'string') {
        Track.track(`Shortlist - Remove Item - ${offerId.split('/')[0]}`, {
          offerId,
        });
      }
      Track.track('Product Removed from Wishlist', {
        items: items.map((item) => `${item?.offerId}-${item?.is_memo}`),
      });
      const itemIds = items.map((item) => `${item?.offerId}-${item?.is_memo}`);

      set_shortlist_loading(true);
      await set_items(
        items_in_shortlist.filter(
          (x) => !itemIds.includes(`${x?.offerId}-${x?.is_memo}`)
        )
      );
      set_shortlist_loading(false);
    },
    update_shortlist_item: async ({ item }) => {
      const rest_items = items_in_shortlist.filter(
        (x) => x.offerId !== item.offerId
      );

      if (Array.isArray(rest_items)) {
        let items_to_set;
        if (rest_items.length === 0) {
          if (items_in_shortlist.length > 0) {
            items_to_set = [item, ...items_in_shortlist];
          } else {
            items_to_set = [item];
          }
        } else {
          items_to_set = [...rest_items, item];
        }
        const items_unique = uniqBy(
          items_to_set,
          (item) => `${item?.offerId}-${item?.is_memo}`
        );
        set_shortlist_loading(true);
        await set_items(items_unique);
        set_shortlist_loading(false);
      }
    },
    get_from_shortlist: ({ offer_id }) => {
      return items_in_shortlist.find((x) => x.offerId === offer_id);
    },
  };

  if (offerId == null) {
    return general;
  }

  return {
    ...general,
    is_in_shortlist: Boolean(
      items_in_shortlist.find((x) => x.offerId === offerId && !x.is_memo)
    ),
    is_in_memo_shortlist: Boolean(
      items_in_shortlist.find((x) => x.offerId === offerId && x.is_memo)
    ),
    set_in_shortlist: async (
      in_shortlist,
      melee_data = { carats: 0, pieces: 0 },
      isMemo = null
    ) => {
      const isMemoModeOn = isMemo ?? isMemoMode;
      if (in_shortlist) {
        if (offerId && typeof offerId === 'string') {
          if (isMemoMode) {
            const type = isNaturalView ? 'NATURAL' : 'LABGROWN';
            Track.track('MemoAddToShortlist_Clicked', {
              FromPage: url.state?.from ?? getSource(),
              Category: isDiamondPage
                ? (type?.toLowerCase() === 'labgrown'
                    ? 'Labgrown'
                    : 'Natural') + 'Diamond'
                : (type?.toLowerCase() === 'labgrown'
                    ? 'Labgrown'
                    : 'Natural') + 'Melee',
              ProductID: offerId.split('/')[1],
              Source: url.state?.source ?? getSource(),
            });
          } else {
            Track.track(
              ['Product Added to Wishlist', 'AddToShortlist_Clicked'],
              {
                ProductID: offerId.split('/')[1],
                ProductType: offerId.split('/')[0],
                Source: 'Shortlist',
              }
            );
          }
        }
        set_shortlist_loading(true);
        await set_items(
          [
            ...items_in_shortlist,
            {
              offerId: offerId,
              added: Date.now(),
              ...melee_data,
              is_memo: isMemoModeOn,
            },
          ],
          isMemoModeOn
        );
        set_shortlist_loading(false);
      } else {
        if (offerId && typeof offerId === 'string') {
          if (isMemoMode) {
            const type = isNaturalView ? 'NATURAL' : 'LABGROWN';
            Track.track('MemoRemoveShortlist_Clicked', {
              FromPage: url.state?.from ?? getSource(),
              Category: isDiamondPage
                ? (type?.toLowerCase() === 'labgrown'
                    ? 'Labgrown'
                    : 'Natural') + 'Diamond'
                : (type?.toLowerCase() === 'labgrown'
                    ? 'Labgrown'
                    : 'Natural') + 'Melee',
              ProductID: offerId.split('/')[1],
              Source: url.state?.source ?? getSource(),
            });
          } else {
            Track.track(
              ['Product Removed from Wishlist', 'RemoveFromShortlist_Clicked'],
              {
                ProductID: offerId.split('/')[1],
                ProductType: offerId.split('/')[0],
                Source: 'Shortlist',
              }
            );
          }
        }
        set_shortlist_loading(true);
        await set_items(
          [
            ...items_in_shortlist.filter(
              (x) => x.offerId !== offerId && x.is_memo === isMemoModeOn
            ),
          ],
          isMemoModeOn
        );
        set_shortlist_loading(false);
      }
    },

    set_shortlist_loading,
  };
};
export let ShortlistConsumer = ({ children, offerId }) => {
  let props = useShortlistContext({ offerId });
  return children(props);
};

// keeping this for Backward compatibility
export let ShortlistItemConsumer = ({ children, offerId }) => {
  let props = useShortlistContext({ offerId });
  return children(props);
};
