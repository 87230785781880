/**
 * @desc Util function for verifying if users review status
 * @param {{steps_required: []}} user - user object with steps_required array
 * @return {{is_user_in_review, userInReview, userBeingReviewed, userNeedsReview}} - {boolean, function, function}
 */
export function getUserReviewStatus(user = {}) {
  const preCondition = (user) =>
    user &&
    typeof user === 'object' &&
    user?.steps_required &&
    Array.isArray(user?.steps_required);

  /**
   * @desc function for verifying if user needs review
   * @param {{steps_required: []}} user - user object with steps_required array
   * @return boolean - ture: needs review, false: user don't need review
   */
  // ? user.role !== 'ADMIN' &&
  //     (user.steps_required.includes('NEED_COMPANY_VERIFICATION') ||
  //       user.steps_required.includes('NEED_VERIFICATION'))
  const userNeedsReview = (user) => {
    return preCondition(user)
      ? user.steps_required.includes('NEED_COMPANY_VERIFICATION') ||
          user.steps_required.includes('NEED_VERIFICATION')
      : true;
  };

  /**
   * @desc function for verifying if user is being reviewed
   * @param {{steps_required: []}} user - user object with steps_required array
   * @return boolean - ture: in review, false: reviewed
   */
  const userBeingReviewed = (user) => {
    return preCondition(user)
      ? user.steps_required.includes('USER_IN_REVIEW') ||
          user.steps_required.includes('COMPANY_IN_REVIEW')
      : true;
  };

  return {
    is_user_in_review: !!(userNeedsReview(user) || userBeingReviewed(user)),
    userInReview: !!(userNeedsReview(user) || userBeingReviewed(user)),
    userBeingReviewed,
    userNeedsReview,
  };
}

// ? user.role !== 'ADMIN' &&
//     (user.steps_required.includes('USER_IN_REVIEW') ||
//       user.steps_required.includes('COMPANY_IN_REVIEW'))
