export let Permission = {
  SUPERADMIN: [
    // Admin Melee Permissions
    'view_melee_add_inventory_page', // melee/page/add-inventory
    'action_add_melee_supplier', // melee/page/add-inventory -> Add supplier action
    'view_melee_create_order_page', // melee/page/create-order
    'view_melee_repository_page', // melee/page/repository
    'layout_melee_repository_ops_page', // melee/page/repository -> with OPS cols
    'layout_melee_repository_finance_page', // melee/page/repository -> with finance cols
    'action_export_melee_repository_view_data', // melee/page/repository -> export csv
    'view_edit_melee_repository_page', // melee/page/repository/view/meleeId
    'view_melee_order_transactions', // melee/page/repository/view/meleeId -> Orders section
    'view_melee_cost_price_history', // melee/page/repository + view/meleeId -> view history
    'action_notify_melee_stock_added', // melee/page/repository + view/meleeId -> Notify supplier
    // Feature reference report page
    'action_send_reference_report_to_supplier', // /admin/melee/page/reference-report >> Send email
    // Feature Global Inventory
    'view_global_inventory_page',

    // Nivoda express
    'view_express_menu',

    /* companies items*/
    'search_by_company_name',
    'edit_company_credit_limit',
    'edit_company_payment_method',
    'block_unblock_supplier_uploading',
    'customers_view_cfm_settings',
    'add_company',
    'insurance_credit_settings',
    'customer_add_edit_company_users',
    'merge_duplicate_companies',
    'view_company_financial_settings',
    'view_company_detail_page',
    'edit_company_financial_settings',
    'customer_view_company_users',
    'customers_view_all_company_locations',
    'customers_add_edit_company_locations',
    'customers_view_all_company_invoices',
    'suppliers_view_company_users',
    'suppliers_view_company_locations',
    'supplier_add_edit_company_users', //not done (duplicated)
    'suppliers_add_edit_company_users', //duplicate
    'suppliers_add_edit_company_locations',
    'supplier_view_all_company_orders',
    'supplier_view_all_company_invoices',
    'customers_view_company_locations',
    'customers_view_all_company_orders',

    'view_packing_slips', //Shipment page
    'prepare_insurance_label', //Shipment page
    'remove_shipment', //Shipment page
    'change_shipment_tracking', //Shipment page
    'add_shipment_weight', //Shipment page
    'finalize_shipment', //Shipment page
    'add_item_to_shipment', //Shipment page

    'submit_generate_tracking_code',
    'access_admin_section',
    'view_overviw_supplier_feeds',
    'view_shipments', //Shipment page
    'edit_all_order_timestamps',
    'create_manual_orders',
    'create_new_shipments',
    'download_commercial_shipping_documents',
    'cancel_shipment',
    'send_delay_email',
    'send_consignment_docs_to_bank',

    // not done
    'revert_payment', //invoice page
    'revoke_ip_charge', // invoice page
    'add_payment_to_invoice', // Awating tab (mark reciept button)
    'convert_proforma_to_normal_invoice', // invoice page
    'view_pass_qc', // not yet in the codebase

    'verify_companies',
    'search_by_company_custom_code',

    'upload_stocks',
    'block_unblock_company_order',
    'add_edit_revoke_feeds',
    'view_active_feed',
    'view_all_order_history',
    'view_awaiting_tab',
    'view_invoice_to_upload_tab', //Accounting upload invoice tab
    'upload_supplier_invoices', //Accounting upload invoice tab
    'mark_supplier_invoices_as_paid',
    'mark_invoices_as_sent', //Accounting invoice to send tab
    'create_new_invoice_for_customers',
    'mark_supplier_order_item_as_paid', //Accounting supplier to pay(PO) tab
    'view_invoice_to_pay_tab', //Accounting Invoice to pay tab
    'view_invoice_to_send_tab', //Accounting Invoice to send tab
    'view_supplier_to_pay_tab', //Accounting Supplier to pay(INV) tab
    'delete_supplier_invoice', //Invoice page
    'mark_customer_or_proforma_invoice_as_unpaid', //Invoice page
    'mark_as_bad_debt', // Accounting Awaiting payment tab
    'unmark_invoice_as_bad_debt', //Invoice page
    'forgive_late_payment_charges', //Invoice page
    'void_customer_or_proforma_invoice', //Invoice page for Void Invoice
    'void_debit_note_invoice', //Invoice page for DN and Sales_Invoice
    'move_items_between_tabs',
    'add_stones',
    'view_incoming_tabs',
    'view_supplier_name',
    'edit_order_pricing',
    'view_pricing_order',
    'view_full_delivery_address',
    'view_limited_delivery_address',
    'access_supplier_dashboard',
    'view_certificates',
    'reply_to_enquiries',
    'access_buyer_dashboard',
    'answer_update_gia_requests',
    'view_admin_invoicing',
    'add_certificates',
    'view_gia_requests',
    'view_all_enquiries',
    'edit_certificates',
    'view_concierge_requests',
    'view_special_requests',
    'answer_update_special_requests',
    'answer_update_concierge_requests',

    'view_awaiting_payment_tab',
    'view_waiting_for_customer_tab',
    'view_po_tab',
    'view_confirmed_tab',
    'view_in_transit_tab',
    'view_ready_to_collect',
    'view_ready_to_tag',
    'view_ready_to_qc',
    'view_ready_to_ship',
    'view_ready_to_qc_action',
    'view_express_rtc_tab',
    'view_rtc_accept_all_action',
    'view_customer_name',
    // Uncomment following to test as admin contractor
    // 'view_admin_shipment_reports_cost_as_contractor',
    'view_admin_shipment_reports_cost',
    'can_remove_supplier_certificates',
    'view_jewellery_tabs',

    // Risk manager permission to mark customer fraudulent
    'mark_customer_fraudulent',

    // Admin tms Permissions
    'view_admin_tms_page', //translation-system
    'view_edit_translation_page', ////translation-system + edit/translationId -> view edit translation page
    'action_view_edit_translation_link', //translation-system + edit/translationId -> edit translation link
    'action_save_translation', //translation-system/edit/translationId -> save translation
    'action_create_translation', //translation-system/add-translation/translationId -> add new translation

    // Admin memo permissions
    'view_memo_tracking_dashboard', //memo tracking dashboard
    'action-items-memo', //actions buttons
  ],
};
