import {
  AdminGraphqlMutation,
  AdminGraphqlQuery,
} from '../../../graphql-admin';
import { gql } from '@apollo/client';

export const UpdateCompanySettings = AdminGraphqlMutation({
  query: gql`
    mutation admin_update_company_settings(
      $company_settings: AdminCompanySettings!
    ) {
      admin_update_company_settings(company_settings: $company_settings) {
        CompanyId
      }
    }
  `,
});

export const UpdateStaticInfo = AdminGraphqlMutation({
  query: gql`
    mutation ($company_info: AdminCompanyStaticInfo!) {
      admin_update_static_info_company(company_info: $company_info) {
        id
        billing_emails
        return_charge
        auto_invoice
        incentive_pay_active
        registration_number
        duns_number
        invoice_day
        billing_currency_preference
        qc_pref
        product_tour_had
        sow
        rapnet_id
        data_verified
        is_msme
        bdb_code
        markup_per_order
        lg_diamond_enabled
        natural_diamond_enabled
        invoice_per_shipment
        nivoda_fee_grouped
        nivoda_fee_in_diamond
        ip_charge_upfront
        ip_reminders_active
        ip_early_payment_refund
        ip_grace_period
        ip_upfront_charge_percentage
        allianz_id
        ip_percentage_charge
        ip_late_payment_percentage_charge
        sales_person {
          id
        }
        mpf_charged
        include_min_fee_in_per
        min_fee_per_stone_amount
        single_supplier_cutoff
        single_supplier_cutoff_amount
        single_supplier_cutoff_charge
        markup_cap_amount
        invoice_last_leg
        advance_payment
        concierge_charge_included
        concierge_charge_percentage
        loupe360_key
        credit_grace_period
        credit_late_payment_percentage_charge
        average_monthly_purchase
        rings_sold_monthly
        market_pay_active
        market_pay_eligible
        days_allowed_after_duedate
        disable_checkout
        market_pay_limit
        credit_safe_limit
        vat_registered
        vat_number
        article_42_vat_exemption
        account_limit
        insurance_limit
        memo_return_penalty
        insurance_currency
        diamond_registered
        charge_last_mile_fee
      }
    }
  `,
});

export const UpdateTrackingCode = AdminGraphqlMutation({
  query: gql`
    mutation ($ShipmentId: ID!, $tracking_code: String!) {
      admin_shipment_change_tracking(
        ShipmentId: $ShipmentId
        tracking_code: $tracking_code
      ) {
        id
        tracking_code
      }
    }
  `,
});

export const AddAndUpdateFeedNote = AdminGraphqlMutation({
  query: gql`
    mutation ($company_id: ID!, $feed_note: String) {
      admin_update_feed_note(company_id: $company_id, feed_note: $feed_note) {
        id
        feed_note
      }
    }
  `,
});

export const AdminMergeSupplierAccount = AdminGraphqlMutation({
  query: gql`
    mutation (
      $account_to_keep: ID!
      $account_to_delete: ID!
      $user_id_to_keep: ID
    ) {
      merge_supplier_accounts(
        account_to_keep: $account_to_keep
        account_to_delete: $account_to_delete
        user_id_to_keep: $user_id_to_keep
      )
    }
  `,
});

export const RemoveSupplierStock = AdminGraphqlMutation({
  query: gql`
    mutation ($CompanyId: ID!, $ProductType: ProductType) {
      remove_supplier_stock(CompanyId: $CompanyId, ProductType: $ProductType)
    }
  `,
});

export const RemoveSupplierCertificates = AdminGraphqlMutation({
  query: gql`
    mutation ($CompanyId: ID!, $ProductType: ProductType) {
      remove_supplier_certificates(
        CompanyId: $CompanyId
        ProductType: $ProductType
      )
    }
  `,
});

export let SearchSalesPersons = AdminGraphqlQuery({
  query: gql`
    query {
      me {
        id
        company {
          id
          company_users {
            id
            firstName
            lastName
          }
        }
      }
    }
  `,
  map_data: (data) => {
    return {
      company: data.me && data.me.company,
      company_users: data.me && data.me.company.company_users,
      id: data.me && data.me.id,
    };
  },
});

export const AdminAssignCompanyManager = AdminGraphqlMutation({
  query: gql`
    mutation admin_assign_company_manager(
      $company_id: ID!
      $key: String!
      $admin_user: ID!
    ) {
      admin_assign_company_manager(
        company_id: $company_id
        key: $key
        admin_user: $admin_user
      ) {
        id
        account_manager {
          id
          firstName
          lastName
          profile_image
        }
        sales_person {
          id
          firstName
          lastName
          profile_image
        }
      }
    }
  `,
});

export const ADMIN_UPDATE_COMPANY_FEED_SETTINGS = gql`
  mutation admin_update_company_feed_settings(
    $supplier_id: ID!
    $feed_settings: [FeedShareOption!]!
  ) {
    admin_update_company_feed_settings(
      supplier_id: $supplier_id
      feed_settings: $feed_settings
    ) {
      id
      name
      feed_settings
    }
  }
`;

export const UPDATE_COMPANY_DETAILS = gql`
  mutation UpdateCompanyDetails($companyDetails: AdminCompanyDetails!) {
    admin_update_company_details(company_details: $companyDetails) {
      id
      name
      website
    }
  }
`;
