import React from 'react';
import { round } from 'lodash';
import { SCREEN } from '../fabric/theme/screen';
import dayjs from 'dayjs';

/**
 * useAsState will return state and cleaner setState function
 * @param {object} initialState - initial values for state.
 * @deprecated Please avoid using this util as its deprecated and will be removed
 * in near future. We can use native `React.useState` instead, Thanks!
 */
const useAsState = (initialState = {}) => {
  const [state, setState] = React.useState(initialState);
  return [state, (_state) => setState({ ...state, ..._state })];
};

//  Default fonts for platform
const DEFAULT_FONTS = {
  medium_bold: `
 font-family: 'Hind Medium', sans-serif;
  font-weight: 600;
`,
  medium_semi_bold: `
 font-family: 'Hind Medium', sans-serif;
  font-weight: 500;
`,
  normal_bold: `
 font-family: Hind, sans-serif;
  font-weight: 600;
`,
};

// https://html.spec.whatwg.org/multipage/input.html#attr-input-accept
// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file
const MIME_TYPES = {
  all_images: 'image/*',
  csv: '.csv',
  doc: '.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  excel: `.xls,.xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel`,
  pdf: '.pdf',
};

const retrieveOldFiltersState = (location) => {
  const { key, ...rest } = location && location.state ? location.state : {};
  const has_filters_from_prev_page = 'oldState' in rest;

  return has_filters_from_prev_page
    ? {
        filters_state: rest,
        has_filters_from_prev_page,
      }
    : { filters_state: {}, has_filters_from_prev_page };
};

/**
 * asDayjs will return date as in dayjs
 * @param {dayjs|Date} date - date in string or dayjs form.
 * @return {dayjs} dayjs - date as in dayjs
 */
const asDayjs = (date) => (typeof date === 'string' ? dayjs(date) : date);

/**
 * util to get the supplier inventory type
 * @param {[string]} inventory_type - array of inventory type eg: ['Natural','Labgrown', 'Melee']
 * @return {{type,is_natural,is_labgrown,is_natural_n_labgrown}} type: [], others are boolean
 */
const getCompanyInventoryType = (inventory_type) => {
  let is_natural = true,
    is_labgrown = false,
    is_gemstone = false;

  if (Array.isArray(inventory_type) && inventory_type.length > 0) {
    is_natural = inventory_type.includes('Natural');
    is_labgrown = inventory_type.includes('Labgrown');
    is_gemstone = inventory_type.includes('Gemstone');
  }

  return {
    type: inventory_type,
    is_natural,
    is_labgrown,
    is_natural_n_labgrown: is_natural && is_labgrown,
    is_gemstone,
  };
};

/**
 * toSafeInt will always return int part of number
 * for falsy or non-numeric values it will return 0;
 * @param {number} num - string version of number
 * @return {number} eg: 1.232 -> 1, "32.343" -> 32
 */
const toSafeInt = (num) => ~~num;

/**
 * @summary Util to check if the device is medium screen sized
 * @return {boolean} true / false
 */
const isMediumScreen = () => SCREEN.width.large1440;

/**
 * @summary Dummy function
 * @param {boolean} skip_call pass true to skipp the function call eg: dummyFn.bind(null,true)
 * @return void
 */
const dummyFn = (skip_call) => {
  if (!skip_call) {
    // eslint-disable-next-line no-console
    console.error(
      '😵 No Function was provided to handler, running dummy function. 😵'
    );
  }
};

/**
 * @summary toNumber returns number for passed value if its a valid number else returns same
 * @return number
 */
export const toNumber = (val) => (isNaN(Number(val)) ? val : Number(val));

/**
 * customArrayOfEnumValidator can be used got check oneOf condition on proptypes for enums
 * for falsy or non-numeric values it will return 0;
 * @param {array} valid_enum_list - array of enum keys ['Diamond','Melee']
 * @param {string} propValue - propValue
 * @param {string} key - key
 * @param {string} _
 * @param {string} componentName
 * @param {string} propFullName
 */
function customArrayOfEnumValidator(
  valid_enum_list,
  propValue,
  key,
  componentName,
  _,
  propFullName
) {
  if (typeof propValue[key] === 'string') {
    if (propValue[key] && !valid_enum_list.includes(propValue[key])) {
      return new Error(
        `Invalid prop \`${componentName}.${propFullName}\` of value \`${propValue[key]}\`` +
          `expected one of ${valid_enum_list}.`
      );
    }
  }
}

function sanitizeInput(input) {
  // Remove non-numeric, non-dot, and non-minus characters
  const sanitized = input.replace(/[^0-9.-]/g, '');

  // Split the string into integer and decimal parts
  const [integerPart, decimalPart] = sanitized.split('.');

  // Reconstruct the sanitized value with a single dot and optional minus sign
  let result = integerPart;
  if (decimalPart !== undefined) {
    result += '.' + decimalPart;
  }

  return result;
}

/** Percentage Difference =(|ΔV| / (ΣV/2)) ×100 **/
const getPercentageDiff = (v1, v2, precision = 2) =>
  round(((+v1 - +v2) / ((+v1 + +v2) / 2)) * 100, precision);

export const precondition = (condition, message) => {
  if (!condition) {
    throw new Error(message);
  }
};

export const downloadCSV = (url, fileName) => {
  // Create a new anchor element
  const a = document.createElement('a');

  // Sanitize the Google Sheet URL
  // Returns actual url by removing the other routes & params
  // Final URL Format: https://docs.google.com/spreadsheets/d/<id>
  // const sanitizedURL = url.replace(/(\/d\/[a-zA-Z0-9-_]+)\/.*/, '$1');

  // Set the href and download attributes for the anchor element
  a.href = url;
  a.download = `${fileName}.csv`;

  // Required for Firefox
  // Attaches the anchor tag to the DOM
  document.body.appendChild(a);

  // Programmatically trigger a click on the anchor element
  a.click();

  // Removes the anchor tag from the DOM
  document.body.removeChild(a);
};

export const openOnBlankScreen = (url) => {
  // Create a new anchor element
  const a = document.createElement('a');

  // Set the href for the anchor element
  a.href = `${url}`;

  // Open the content in blank screen
  a.target = '_blank';

  // Programmatically trigger a click on the anchor element
  a.click();
};

export {
  customArrayOfEnumValidator,
  DEFAULT_FONTS,
  asDayjs,
  dummyFn,
  getCompanyInventoryType,
  getPercentageDiff,
  isMediumScreen,
  MIME_TYPES,
  retrieveOldFiltersState,
  toSafeInt,
  useAsState,
  sanitizeInput,
};

export { appEnv } from './appEnv';
export { isMobile } from './isMobile';
export { compareDateFunction, compareFunction } from './compareFunction';
export { createAction } from './createAction';
export { calculateDiamondTotal } from './diamond';
export { getOrdersDataWithLoadingMeta } from './getOrdersDataWithLoadingMeta';
export { getProductTypeName } from './getProductTypeName';
export { getPrintLabelURL } from './getPrintLabelURL';
export { hasShadeEyeCleanCheck } from './cart';
export { initLazyState } from './initLazyState';
export {
  isMeleeItem,
  isDiamondItem,
  isGemstoneItem,
  getOriginalSupplierStockID,
  isJewelleryItem,
} from './productTypes.js';
export { openInNewWindow } from './openInNewWindow';
export { getDateRange } from './getDateRange';
export { skipWeekends } from './skipWeekends';
export { tFn } from './translationFn';
export { isURL } from './isURL';
export { isValidActiveUrl } from './isValidActiveUrl';
export { validateEmail } from './validateEmail';
export { getPluralLabel } from './getPluralLabel';
export { connectIDB } from './indexedDB';
export { fetchTranslations } from './fetchTranslations';
