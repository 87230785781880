import { LocalstorageValue } from 'components/dashboard/dashboard-shared';
import _, { isObject } from 'lodash';
import { viewModeRx } from 'graphql/factory/reactiveVar';

export const graphql_range = (xs) => {
  let numbers = xs.length > 0 ? xs.map((x) => parseFloat(x)) : [];
  if (numbers.length === 0 || numbers.every((x) => Number.isNaN(x))) {
    return null;
  }

  if (Number.isNaN(numbers[0] && !Number.isNaN(numbers[1]))) {
    return {
      from: 0,
      to: numbers[1],
    };
  }

  if (Number.isNaN(numbers[1] && !Number.isNaN(numbers[0]))) {
    return {
      from: numbers[0],
      to: 100,
    };
  }

  return {
    from: numbers[0],
    to: numbers[1],
  };
};

export const localStorageView = LocalstorageValue({
  key: 'view',
  default_value: global && global.innerWidth < 700 ? 'biggrid' : 'grid',
});

export const isMobile = () => window.innerWidth < 700;

export const default_view = (is_supplier) => {
  if (isMobile() && viewModeRx() === 'list') {
    if (is_supplier) {
      return 'list';
    }
    viewModeRx('biggrid');
    localStorageView.set('biggrid');
    return 'biggrid';
  }
  if (!isMobile() && viewModeRx() === 'grid') {
    if (is_supplier) {
      viewModeRx('list');
      localStorageView.set('list');
      return 'list';
    }
    viewModeRx('biggrid');
    localStorageView.set('biggrid');
    return 'biggrid';
  }
  return viewModeRx();
};

export const cert_number_validator = (input) => {
  //^ asserts the position at the start of the string.
  //\d{5,} matches at least 5 digits.
  //$ asserts the position at the end of the string.

  // const regex = ^LG?[0-9]+$
  const regex = /^(LG\d{5,}|\d{5,})$/;

  return regex.test(input);
};

export const checkSizeStatus = (value) => {
  const sizesArr = value;
  const checkSize =
    sizesArr?.length &&
    sizesArr?.length === 1 &&
    sizesArr[0][0] &&
    sizesArr[0][1];

  return checkSize !== ''; // SizeStatus
};
export let checkIsActive = (name, query) => {
  switch (name) {
    case 'types':
      return query?.type?.length > 0;
    case 'shapes':
      return query?.shapes?.length > 0;
    case 'cut':
      return query?.cut?.length > 0;
    case 'carat':
      return checkSizeStatus(query?.sizes);
    case 'clarity':
      return query?.clarity?.length > 0;
    case 'colour':
      return (
        query?.color?.length > 0 ||
        query?.fancyOvertone?.length > 0 ||
        query?.fancyIntensity?.length > 0 ||
        query?.fancyColor?.length > 0
      );
    case 'make':
      return (
        query?.polish?.length > 0 ||
        query?.cut?.length > 0 ||
        query?.symmetry?.length > 0
      );
    case 'fluorescence':
      return (
        query?.flouresence?.length > 0 || query?.flouresence_color?.length > 0
      );
    case 'MMSize':
      return (
        !_.isEmpty(query?.depth_mm) ||
        !_.isEmpty(query?.length_mm) ||
        !_.isEmpty(query?.width_mm)
      );
    default:
      return false;
  }
};
export const isV2 = () => {
  return window?.location?.pathname.includes('v2');
};
// export const remove_key_from_obj = (obj, key_for_filter) => {
//   const asArray = Object.entries(obj);
//   const filtered = asArray.filter(([key, value]) => key !== key_for_filter);
//   const filteredObject = Object.fromEntries(filtered);
//   return filteredObject;
// };
export const getUserInfoFromSession = () => {
  let session = JSON.parse(localStorage.getItem('graphql_session'));
  let { user } = session;
  return { u_id: user?.id, c_id: user?.company.id };
};

export const getObjectDiff = (origObj, newObj) => {
  function changes(newObj, origObj) {
    let arrayIndexCounter = 0;
    return _.transform(newObj, (result, value, key) => {
      if (!_.isEqual(value, origObj[key])) {
        let resultKey = _.isArray(origObj) ? arrayIndexCounter++ : key;
        result[resultKey] =
          _.isObject(value) && _.isObject(origObj[key])
            ? changes(value, origObj[key])
            : value;
      }
    });
  }
  return changes(newObj, origObj);
};

export const isDiamondPage = () => {
  return window.location.pathname.includes('/diamond');
};

export const isGemstonePage = () => {
  return window.location.pathname.includes('/gemstones');
};

// ReturnPrice(offer.return_price, offer.price);
export const ReturnPrice = (return_price, offer_price) => {
  return offer_price !== undefined
    ? ((return_price - offer_price) / 100).toFixed(2)
    : false;
};

export const maskCert = (certNumber) => {
  let is_lab = certNumber.includes('LG');
  let isMasked = is_lab
    ? certNumber.match(/^[A-Za-z]{2}[0-9]{2}\*+$/)
    : certNumber.match(/^[0-9]{2}\*+$/);

  let num = is_lab ? 4 : 2;
  function convertToAsterisks(inputString) {
    return (
      inputString.slice(0, num) +
      '*'.repeat(Math.max(0, inputString.length - num))
    );
  }
  if (isMasked) return certNumber;
  else return convertToAsterisks(certNumber);
};

export let maskName = (name, isAdmin, canSeeSupplierInfo) => {
  if (isAdmin || canSeeSupplierInfo) return name;
  return 'NIVODA LIMITED';
};

const copyToClipboardFallback = (text) => {
  const textarea = document.createElement('textarea');
  textarea.value = text;
  document.body.appendChild(textarea);
  textarea.select();
  document.execCommand('copy');
  document.body.removeChild(textarea);
};

export const copyToClipboard = (value) => {
  const text = isObject(value) ? JSON.stringify(value, null, 2) : value; // Pretty print JSON
  if (navigator.clipboard) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        return true;
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error('Failed to copy object: ', err);
      });
    return true;
  } else {
    copyToClipboardFallback(text);
    return true;
  }
};

/**
 * Bytes to 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'
 * @param {number} bytes - input bytes.
 * @param {number} decimals - consider decimal upto n places.
 */
export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';
  // 1kb = 1024 bytes (binary)
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};
