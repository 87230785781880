import { CopyText } from '@nivoda/common';
import { theme } from '@nivoda/ui';
import { Icon } from 'fabric/resources/icon';
import { Certificate, Diamond, toTwodecimal } from 'helpers';
import { uniq } from 'lodash';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import { isValidPhoneNumber } from 'react-phone-number-input/mobile';
import { PhoneInputWrapper, StyledReqPopup } from './style';

export const convertToNumber = (text) => {
  const cleanedString = text.replace(/[^\d.]/g, '');
  const numberValue = parseFloat(cleanedString);
  return isNaN(numberValue) ? null : numberValue;
};

export const share_detail_list = ({ data, t }) => {
  const certificate = data?.certificate;
  return [
    {
      id: 'MEDIA',
      title: 'Media (video, still image)',
      translated_key: t('media_video_still_image'),
      short_title: 'Media (video)',
      value: '',
      editable: false,
      isBold: true,
      isSelected: true,
    },
    {
      id: 'LAB',
      title: 'Certificate',
      translated_key: t('certificate'),
      value: certificate.lab,
      editable: false,
      isBold: true,
      isSelected: true,
    },
    {
      id: 'SHAPE',
      title: 'Shape',
      translated_key: t('shape'),
      value: Certificate.get_shape(certificate),
      editable: false,
      isBold: true,
      isSelected: true,
    },
    {
      id: 'WEIGHT',
      title: 'Carat',
      translated_key: t('carat'),
      value: `${Certificate.get_size(certificate)}ct`,
      editable: false,
      isBold: true,
      isSelected: true,
    },
    {
      id: 'COLOR',
      title: 'Colour',
      translated_key: t('color'),
      value: Certificate.get_color(certificate),
      editable: false,
      isBold: true,
      isSelected: true,
    },
    {
      id: 'CLARITY',
      title: 'Clarity',
      translated_key: t('clarity'),
      value: Certificate.get_clarity(certificate),
      editable: false,
      isBold: true,
      isSelected: true,
    },
    {
      id: 'CUT',
      title: 'Cut',
      translated_key: t('cut'),
      value: Certificate.get_cut(certificate),
      editable: false,
      isBold: true,
      isSelected: true,
    },
    {
      id: 'POLISH',
      title: 'Polish',
      translated_key: t('polish'),
      value: Certificate.get_polish(certificate),
      editable: true,
      isBold: true,
      isSelected: false,
    },
    {
      id: 'SYMMETRY',
      title: 'Symmetry',
      translated_key: t('symmetry'),
      value: Certificate.get_symmetry(certificate),
      editable: true,
      isBold: true,
      isSelected: false,
    },
    {
      id: 'FLUORESCENCE',
      title: 'Fluorescence',
      isBold: true,
      translated_key: t('fluorescence'),
      value: Certificate.get_fluorescence(certificate),
      editable: true,
      isSelected: false,
    },
    {
      id: 'SHADE',
      title: 'Shade',
      translated_key: t('shade'),
      value: Diamond.get_shade(data)[0]?.full_text || '',
      editable: true,
      isSelected: false,
    },
    {
      id: 'LUSTER',
      title: 'Luster',
      translated_key: t('luster'),
      value: Diamond.get_luster(data)?.full_text || '',
      editable: true,
      isSelected: false,
    },
    {
      id: 'EYECLEAN',
      title: 'Eye clean',
      translated_key: t('eye_clean'),
      value:
        Diamond.get_eyeclean(data) !== null
          ? `${Diamond.get_eyeclean(data)} Eye clean`
          : '',
      editable: true,
      isSelected: false,
    },
    {
      id: 'MEASUREMENTS',
      title: 'Measurements',
      translated_key: t('measurements'),
      value: Certificate.get_measurements(certificate, true),
      editable: true,
      isSelected: false,
    },
    {
      id: 'RATIO',
      title: 'Ratio',
      translated_key: t('ratio'),
      value: Certificate.get_ratio(certificate),
      editable: true,
      isSelected: false,
    },
    {
      id: 'TABLE',
      title: 'Table',
      translated_key: t('table'),
      value: `${toTwodecimal(certificate.table)}%`,
      editable: true,
      isSelected: false,
    },
    // {
    //   id: 'DEPTH',
    //   title: 'Depth',
    //   translated_key: t('depth'),
    //   value: `${toTwodecimal(certificate.depthPercentage)}%`,
    //   editable: true,
    //   isSelected: false,
    // },
  ];
};

export const format_price = ({
  amount,
  exchangeRate,
  currency = 'USD',
  precision = 2,
}) => {
  const calculateAmount = () => {
    let calculatedAmount = parseFloat(amount);
    if (isNaN(calculatedAmount)) {
      return 0;
    }
    if (exchangeRate) {
      calculatedAmount /= exchangeRate;
    }
    return calculatedAmount;
  };

  const format = (calculatedAmount) => {
    const options = {
      style: 'currency',
      currency,
      minimumFractionDigits: precision,
    };

    let priceString = calculatedAmount.toLocaleString('en-GB', options);

    if (currency === 'AUD' || currency === 'CA$') {
      priceString = priceString.replace(/A\$/g, 'AU$').replace(/CA\$/g, 'CA$');
    }

    priceString = priceString.replace(/US/, '').replace('ZAR', 'R');

    return priceString;
  };

  const calculatedAmount = calculateAmount();
  const priceString = format(calculatedAmount);

  return [priceString, parseFloat(calculatedAmount.toFixed(2))];
};

export const get_carat_price = ({
  carats,
  price,
  currency = 'USD',
  exchangeRate,
}) => {
  if (!carats || !price) {
    return 'N/A';
  }

  const numericPrice = parseFloat(price);
  if (isNaN(numericPrice)) {
    return 'N/A';
  }

  let cp = numericPrice * (1 / carats);

  const options = {
    style: 'currency',
    currency,
    minimumFractionDigits: 2,
  };

  let formattedPrice = cp.toLocaleString('en-GB', options);

  if (currency === 'AUD' || currency === 'CA$') {
    formattedPrice = formattedPrice
      .replace(/A\$/g, 'AU$')
      .replace(/CA\$/g, 'CA$');
  }

  formattedPrice = formattedPrice.replace(/US/, '').replace('ZAR', 'R');

  return `${formattedPrice}/ct`;
};

export const calculateNewPrice = (currentPrice, percentage) => {
  if (typeof currentPrice !== 'number' || typeof percentage !== 'number') {
    // eslint-disable-next-line no-console
    console.error('Invalid input. Please enter numbers.');
    return parseFloat(currentPrice);
  }

  if (percentage >= 0) {
    const newPrice = currentPrice + (currentPrice * percentage) / 100;
    return newPrice.toFixed(2);
  } else {
    // eslint-disable-next-line no-console
    console.error('Percentage should be between 0 and 100.');
    return parseFloat(currentPrice.toFixed(2));
  }
};

export const calculatePercentageIncrease = (initialPrice, newPrice) => {
  if (typeof initialPrice !== 'number' || typeof newPrice !== 'number') {
    // eslint-disable-next-line no-console
    console.error('Invalid input. Please enter numbers.');
    return;
  }

  if (newPrice > initialPrice) {
    const percentageIncrease = ((newPrice - initialPrice) / initialPrice) * 100;
    return percentageIncrease.toFixed(2);
  } else {
    // eslint-disable-next-line no-console
    console.error('New price should be greater than the initial price.');
    return;
  }
};

export const shareViaWhatsApp = ({ message }) => {
  const width = 500;
  const height = 500;
  const left = (window.innerWidth - width) / 2;
  const top = (window.innerHeight - height) / 2;

  window.open(
    `https://web.whatsapp.com/send?text=${encodeURIComponent(message)}`,
    '',
    `width=${width},height=${height},left=${left},top=${top}`
  );
};

export const LinkModal = ({ link = '', message, clearModalState }) => {
  const { t } = useTranslation();
  return (
    <StyledReqPopup
      width={'400px'}
      borderRadius={'4px'}
      clearModalState={clearModalState}
    >
      <div className="success-message">
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            cursor: 'pointer',
          }}
          onClick={() => clearModalState()}
        >
          <Icon name="close" size={14} />
        </div>
        <div
          style={{
            textAlign: 'center',
            fontWeight: 600,
            fontSize: '18px',
          }}
        >
          {t('preview_the_link_shared_below')}
        </div>
        <div
          style={{
            display: 'flex',
            gap: '5px',
            alignItems: 'center',
            marginTop: '10px',
            justifyContent: 'center',
          }}
        >
          <CopyText
            className="copy-link"
            clipboardId={link}
            copyHintText={t('copy_link')}
            textToCopy={link}
            alt_copied_text={'Link Copied!'}
            textToShow={link}
            placement={'top'}
            icon={{ name: 'copyText' }}
            maxLength={40}
            height={'20px'}
            width={'20px'}
          />
        </div>
      </div>
    </StyledReqPopup>
  );
};

export const MobilePhoneWrapper = ({
  compulsary_dot,
  setFormValues,
  setFormStatus,
  state,
  phone_country_code,
  phone_is_valid,
  phone,
  defaultCountry,
  hasEnterPhone,
}) => {
  const { t } = useTranslation();

  let preferred_countries = uniq([
    'us',
    'gb',
    'ca',
    defaultCountry.toLowerCase(),
  ]);

  useEffect(() => {
    /**
     * On page load,
     * change node position for input element in PhoneInput
     * to allow tab movement work perfectly
     */
    const inputEl = document.querySelector('.phone-code-selector');
    inputEl.parentNode.insertBefore(inputEl.parentNode.lastChild, inputEl);
  }, []);

  const validatePhoneOnBlur = async () => {
    let val = phone.charAt(0) === '+' ? phone.slice(1) : phone;

    let is_valid = isValidPhoneNumber('+' + val);

    if (val.length > 0 && is_valid) {
      setFormValues('phone', '+' + val);
      setFormStatus({
        hasEnterPhone: true,
        phone_is_valid: true,
      });
    } else if (val.length > 0 && !is_valid) {
      await setFormStatus({
        hasEnterPhone: true,
        phone_is_valid: false,
      });
    } else {
      setFormStatus({
        hasEnterPhone: false,
        phone_is_valid: false,
      });
    }
  };

  return (
    <PhoneInputWrapper className="phone_input_wrapper">
      {/* <label className="phone_input_wrapper--label">
        {t('mobile_number')} {compulsary_dot}
      </label> */}
      <PhoneInput
        preferredCountries={preferred_countries}
        placeholder={phone_country_code}
        value={phone_country_code}
        enableTerritories={true}
        excludeCountries={['je']}
        onChange={(value) => {
          setFormValues('phone', value);
        }}
        inputProps={{
          name: 'phone',
          required: true,
        }}
        inputClass="phone-code-selector"
        inputStyle={{
          border: '1px solid #d4d4d8',
          width: '100%',
          height: '35px',
          borderRadius: '8px',
          background: theme.palette.Neutrals100,
        }}
        onBlur={validatePhoneOnBlur}
      />

      {hasEnterPhone && !phone_is_valid && (
        <label
          style={{
            display: 'block',
            marginTop: '5px',
            color: 'red',
            fontSize: '12px',
          }}
        >
          {t('please_enter_valid_mobile_phone_number')}
        </label>
      )}
    </PhoneInputWrapper>
  );
};
