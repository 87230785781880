import { theme } from 'fabric/theme';
import React, { useState, useRef } from 'react';
import DatePicker from 'react-date-picker';
import ReactPhoneInput from 'react-phone-input-2';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { Translation, useTranslation } from 'react-i18next';
import { noop } from 'lodash';
import styled from 'styled-components';
import { passwordChecker } from 'helpers';
import { Icon } from 'fabric/resources/icon';
import { isMobile } from 'utils';

export function Input(props) {
  const {
    type,
    id,
    onChange,
    errorStyle = {},
    labelStyle = {},
    label = '',
    placeholder,
    className,
    style = {},
    value,
    required,
    hasError = false,
    skipError = false,
    errorMessage = '',
    place_by_side,
    prefix,
    onKeyPress,
    onKeyDown,
    place_input_below,
    inputContainerStyle = {},
    formGroupStyle = {},
    ...rest
  } = props;
  //STATE
  const [showError, setShowError] = useState(false);
  const [isTyping, setIsTyping] = React.useState(false);
  let [temp_holder, set_temp_holder] = React.useState('');
  const ref = useRef();
  // HANDLERS
  const onBlur = (e) => {
    // Run custom logic passed to input on blur
    typeof props.onBlur === 'function' && props.onBlur(e);
    // Show error to trigger error evaluation logic

    setShowError(true);
  };

  function handleKeyDown() {
    setIsTyping(true);
    setShowError(false);
  }

  React.useEffect(() => {
    let timeoutId = null;

    if (isTyping) {
      timeoutId = setTimeout(() => {
        setIsTyping(false);

        return typeof onKeyDown === 'function' && onKeyDown(temp_holder);
      }, 500); // Change this value to set the timeout duration
    }

    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTyping, temp_holder]);

  if (typeof props.t === 'function') {
    // eslint-disable-next-line no-console
    console.log(
      'Warning: sending translation as props in',
      window.location.pathname
    );
  }

  let Local_style = place_by_side
    ? {
        display: 'flex',
        justifyContent: 'center',
      }
    : null;
  let place_input_below_style = place_input_below
    ? {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }
    : null;
  const validEmail = (email) => /^(.+)@(.+)\.(.+)$/.test(email);
  const validPassword = (password) => passwordChecker(password);
  // Initialize is_error only when showError is true
  // To avoid showing on error when component mounts
  // And show only after focus blurs from the input
  // To another input
  const is_error =
    // check if dynamic error state passed from parent component
    hasError ||
    // if not then proceed with internal error logic
    (!skipError && showError
      ? type === 'phone'
        ? !isValidPhoneNumber(value)
        : type === 'email'
        ? required && !validEmail(value)
        : type === 'password'
        ? required && !validPassword(value)
        : required && value === ''
      : false);

  return (
    <Translation>
      {(t) => (
        <div
          style={{
            ...Local_style,
            ...place_input_below_style,
            ...formGroupStyle,
          }}
          className={`form-group ${is_error ? 'has-error' : ''}`}
        >
          {label && (
            <label
              className="form-control-label control-label"
              htmlFor={id}
              style={labelStyle}
            >
              {label}
            </label>
          )}
          <div
            style={{
              ...inputContainerStyle,
              position: prefix ? 'relative' : '',
            }}
          >
            {prefix ? prefix : null}
            <input
              ref={ref}
              id={id}
              onChange={(e) => {
                // eslint-disable-next-line no-unused-expressions
                onChange ? onChange(e.target.value) : '';
                set_temp_holder(e.target.value);
              }}
              onBlur={onBlur}
              type={type ? type : 'text'}
              className={`form-control ${className} ${
                is_error ? 'form-control-error' : ''
              }`}
              placeholder={placeholder ? placeholder : ''}
              data-automation-id={placeholder ? placeholder : 'placeholder'}
              value={value || ''}
              required={required}
              style={{ ...style }}
              onKeyPress={onKeyPress}
              onKeyDown={handleKeyDown}
              {...rest}
            />
          </div>
          {((required && value === '' && !skipError && showError) ||
            is_error) &&
            type !== 'email' && (
              <small
                className="has-error control-label"
                data-automation-id="password-error"
                style={errorStyle}
              >
                {errorMessage
                  ? errorMessage
                  : label && typeof label === 'string'
                  ? `${t('please_enter')} ${
                      label.toLowerCase() ||
                      (placeholder &&
                        typeof placeholder === 'string' &&
                        placeholder.toLowerCase())
                    }`
                  : t('enter_valid_value')}
              </small>
            )}
          {value !== '' && required && type === 'email' && is_error && (
            <small
              className="has-error control-label"
              style={errorStyle}
              data-automation-id="has-error"
            >
              {errorMessage ? errorMessage : t('enter_valid_email')}
            </small>
          )}
        </div>
      )}
    </Translation>
  );
}
// let InputWithTickWrapper = styled.div`
//   & .green_check_wrapper {
//     display: flex;
//     place-items: center;
//     gap: 5px;
//     padding-right: 10px;
//     height: 45px;
//     width: 100%;
//   }
//   & .hide-input {
//     border: none !important;
//     margin-bottom: 0;
//   }
//   .tick-mark-style {
//     margin-top: 10px;
//     width: 20px;
//     height: 20px;
//   }
// `;
// export const InputWithTick = (props) => {
//   const { t } = useTranslation();
//   //STATE
//   const [showError, setShowError] = useState(false);
//   // HANDLERS
//   const onBlur = () => {
//     // Run custom logic passed to input on blur
//     typeof props.onBlur === 'function' && props.onBlur();
//     // Show error to trigger error evaluation logic
//     setShowError(true);
//   };

//   const {
//     type,
//     id,
//     onChange,
//     errorStyle = {},
//     labelStyle = {},
//     label = '',
//     placeholder,
//     className,
//     style = {},
//     value,
//     required,
//     hasError = false,
//     skipError = false,
//     errorMessage = '',
//     place_by_side,
//     prefix,
//     onKeyPress,
//     isShowTick,
//     wrapperClass,
//     ...rest
//   } = props;
//   let Local_style = place_by_side
//     ? {
//         display: 'flex',
//         justifyContent: 'center',
//       }
//     : null;
//   const validEmail = (email) => /^(.+)@(.+)\.(.+)$/.test(email);
//   // Initialize is_error only when showError is true
//   // To avoid showing on error when component mounts
//   // And show only after focus blurs from the input
//   // To another input
//   const is_error =
//     // check if dynamic error state passed from parent component
//     hasError ||
//     // if not then proceed with internal error logic
//     (!skipError && showError
//       ? type === 'phone'
//         ? !isValidPhoneNumber(value)
//         : type === 'email'
//         ? required && !validEmail(value)
//         : required && value === ''
//       : false);
//   return (
//     <InputWithTickWrapper
//       style={Local_style}
//       className={`form-group ${is_error ? 'has-error' : ''} ${wrapperClass}`}
//     >
//       <label
//         className="form-control-label control-label"
//         htmlFor={id}
//         style={labelStyle}
//       >
//         {label}
//       </label>

//       <div
//         className={`form-control green_check_wrapper ${className} ${
//           is_error ? 'form-control-error' : ''
//         }`}
//         style={prefix ? { position: 'relative' } : {}}
//       >
//         {prefix ? prefix : null}
//         <input
//           id={id}
//           className="hide-input"
//           onChange={(e) => (onChange ? onChange(e.target.value) : '')}
//           onBlur={onBlur}
//           type={type ? type : 'text'}
//           placeholder={placeholder ? placeholder : ''}
//           value={value || ''}
//           required={required}
//           style={{
//             ...style,
//           }}
//           onKeyPress={onKeyPress}
//           {...rest}
//         />
//         {isShowTick && (
//           <span>
//             <Icon
//               className="tick-mark-style"
//               name="check"
//               color={theme.palette.green1}
//             />
//           </span>
//         )}
//       </div>

//       {((required && value === '' && !skipError && showError) || is_error) &&
//         type !== 'email' && (
//           <small className="has-error control-label" style={errorStyle}>
//             {errorMessage
//               ? errorMessage
//               : label && typeof label === 'string'
//               ? `${t('please_enter')} ${label.toLowerCase()}`
//               : t('enter_valid_value')}
//           </small>
//         )}
//       {value !== '' && required && type === 'email' && is_error && (
//         <small className="has-error control-label" style={errorStyle}>
//           {errorMessage ? errorMessage : t('enter_valid_email')}
//         </small>
//       )}
//     </InputWithTickWrapper>
//   );
// };
const BubbleInputWrapper = styled.div`
  .input_disabled {
    cursor: not-allowed;
    pointer-events: none;
    background: ${theme.palette.gray200} !important;
  }
  .form-control-error {
    &.form-input {
      border: 1px solid ${theme.palette.red} !important;
    }
  }

  .input-wrapper {
    border: 1px solid ${theme.palette.gray300} !important;
    display: flex;
    align-items: center;
    .inner-bubble-wrap {
      display: flex;
      align-items: center;
      gap: 5px;
      flex-wrap: wrap;
      .value_bubble {
        display: flex;
        align-items: center;
        padding: 2px;
        border: 1px solid ${theme.palette.gray300};
        border-radius: 4px;
        ${theme.fonts.small}
      }
    }
  }
  input {
    border: none;
    background: transparent;
    width: ${isMobile() && '45%'};
  }
`;

export const BubbleInput = (props) => {
  const initialState = {
    showError: false,
  };
  const [state, setState] = useState(initialState);

  const { t } = useTranslation();
  let {
    type,
    id,
    onChange,
    errorStyle = {},
    labelStyle = {},
    label = '',
    placeholder,
    className,
    style = {},
    value,
    required,
    hasError = false,
    skipError = false,
    errorMessage = '',
    place_by_side,
    prefix,
    onKeyPress,
    valueList,
    disabled,
    onBlur,
    onKeyDown,
    invalidField,
    handleBubbleRemoval,
    ...rest
  } = props;
  // HANDLERS
  onBlur = (e) => {
    // Run custom logic passed to input on blur
    typeof props.onBlur === 'function' && props.onBlur(e);
    // Show error to trigger error evaluation logic

    setState({
      ...state,
      showError: true,
    });
  };

  onKeyDown = (e) => {
    // Run custom logic passed to input on onKeyDown
    typeof props.onKeyDown === 'function' && props.onKeyDown(e);
    if (e.keyCode === 13) {
      setState({
        ...state,
        showError: true,
      });
    } else {
      setState({
        ...state,
        showError: false,
      });
    }
    // Show error to trigger error evaluation logic
  };

  if (typeof props.t === 'function') {
    // eslint-disable-next-line no-console
    console.log(
      'Warning: sending translation as props in',
      window.location.pathname
    );
  }

  const { showError } = state;
  let Local_style = place_by_side
    ? {
        display: 'flex',
        justifyContent: 'center',
      }
    : null;

  return (
    <BubbleInputWrapper
      style={Local_style}
      className={`form-group ${hasError ? 'has-error' : ''}`}
    >
      {label && (
        <label
          className="form-control-label control-label"
          htmlFor={id}
          style={labelStyle}
        >
          {label}
        </label>
      )}
      <div
        style={prefix ? { position: 'relative' } : {}}
        className={`form-control input-wrapper ${
          disabled && 'input_disabled'
        } ${className} ${hasError ? 'form-control-error' : ''}`}
      >
        {prefix ? prefix : null}
        <div className="inner-bubble-wrap">
          {valueList && valueList?.length > 0 && (
            <>
              {valueList.map((e, index) => {
                return (
                  <div key={index} className="value_bubble">
                    <span>{e}</span>
                    <Icon
                      name="small-close"
                      size={12}
                      onClick={() => handleBubbleRemoval(e)}
                      style={{ cursor: 'pointer' }}
                    />
                  </div>
                );
              })}
            </>
          )}
          <input
            id={id}
            onChange={(e) => (onChange ? onChange(e.target.value) : '')}
            onBlur={onBlur}
            type={type ? type : 'text'}
            placeholder={placeholder ? placeholder : ''}
            value={value || ''}
            required={required}
            style={{ ...style }}
            onKeyPress={onKeyPress}
            onKeyDown={onKeyDown}
            disabled={disabled}
            {...rest}
          />
        </div>
      </div>
      {((required && value === '' && !skipError && showError) || hasError) &&
        type !== 'email' && (
          <small className="has-error control-label" style={errorStyle}>
            {errorMessage
              ? errorMessage
              : label && typeof label === 'string'
              ? `${t('please_enter')} ${
                  label.toLowerCase() ||
                  (placeholder &&
                    typeof placeholder === 'string' &&
                    placeholder.toLowerCase())
                }`
              : t('enter_valid_value')}
          </small>
        )}
      {invalidField &&
        value !== '' &&
        required &&
        !valueList.includes(value) &&
        type === 'email' &&
        hasError && (
          <small className="has-error control-label" style={errorStyle}>
            {errorMessage ? errorMessage : t('enter_valid_email')}
          </small>
        )}
      {invalidField && type === 'email' && valueList.includes(value) && (
        <small className="has-error control-label" style={errorStyle}>
          {t('this_email_already_in_list')}
        </small>
      )}
      {invalidField && type === 'email' && value === '' && (
        <small className="has-error control-label" style={errorStyle}>
          {t('email_cannot_be_blank')}
        </small>
      )}
    </BubbleInputWrapper>
  );
};

export const FlatInput = (props) => {
  const { t } = useTranslation();
  //STATE
  const [showError, setShowError] = useState(false);
  // HANDLERS
  const onBlur = () => {
    // Run custom logic passed to input on blur
    typeof props.onBlur === 'function' && props.onBlur();
    // Show error to trigger error evaluation logic
    setShowError(true);
  };
  const clearError = () => {
    if (showError) {
      // this.setState({ showError: false });
      setShowError(false);
    }
  };

  const {
    extraPhoneProps = {},
    type,
    id,
    onChange,
    errorStyle = {},
    labelStyle = {},
    label = '',
    placeholder,
    className,
    style = {},
    value = '',
    required,
    hasError = false,
    errorMessage = '',
    place_by_side,
    options,
    customMarginRight = 40,
    fieldMarginBottom,
    selected_country,
    ...rest
  } = props;
  const validEmail = (email) => /^(.+)@(.+)\.(.+)$/.test(email);
  // Initialize is_error only when showError is true
  // To avoid showing on error when component mounts
  // And show only after focus blurs from the input
  // To another input

  const is_error =
    // check if dynamic error state passed from parent component
    hasError ||
    // if not then proceed with internal error logic
    (showError
      ? type === 'phone'
        ? value?.trim() && !isValidPhoneNumber(`+${value}`)
        : type === 'email'
        ? required && !validEmail(value)
        : required && value === ''
      : false);

  return (
    <div
      className={`form-group ${is_error ? 'has-error' : ''}`}
      style={{ marginBottom: fieldMarginBottom || theme.spacing.s5 }}
    >
      <label
        className="form-control-label  control-label"
        htmlFor={id}
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {label && (
          <span
            style={{
              width: isMobile() ? '40%' : '20%',
              marginRight: isMobile() ? 5 : customMarginRight,
              textAlign: 'right',
              fontWeight: 500,
              ...labelStyle,
            }}
          >
            {label}:
          </span>
        )}
        <>
          {type === 'select' && label !== 'Country' && label !== 'State' && (
            <select
              className={`form-control rounded ${className} ${
                is_error ? 'form-control-error' : ''
              }`}
              value={value}
              onChange={(e) => (onChange ? onChange(e.target.value) : noop)}
            >
              <option value="" disabled default hidden style={{ color: 'red' }}>
                {props.role === 'user'
                  ? 'Select your Options'
                  : t('select_your_option')}
              </option>

              {options &&
                options.length &&
                options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.display}
                  </option>
                ))}
            </select>
          )}
          {type === 'select' && label === 'Country' && (
            <CountryDropdown
              style={{
                borderRadius: '5px',
                minHeight: '43px',
              }}
              classes="form-control select"
              onChange={(val) => (onChange ? onChange(val) : noop)}
              value={value}
              required
              valueType="short"
              labelType="full"
            />
          )}
          {type === 'select' && label === 'State' && selected_country && (
            <RegionDropdown
              country={selected_country}
              style={{
                borderRadius: '5px',
                minHeight: '43px',
              }}
              classes="form-control select"
              onChange={(val) => (onChange ? onChange(val) : noop)}
              value={value}
              countryValueType="short"
              required
              valueType="short"
              // labelType="full"
            />
          )}
          {type === 'phone' && (
            <ReactPhoneInput
              inputClass="phone-input"
              inputProps={{
                name: 'phone',
                required: true,
                style: { paddingLeft: '55px' },
              }}
              containerClass={''}
              disableCountryCode={false}
              specialLabel={null}
              defaultErrorMessage={t('invalid_phone')}
              value={value || ''}
              enableTerritories={true}
              excludeCountries={['je']}
              style={{ width: '100%' }}
              onChange={(val) => (onChange ? onChange(val) : '')}
              onBlur={onBlur}
              onFocus={clearError}
              {...extraPhoneProps}
            />
          )}
          {type === 'date' && (
            <DatePicker
              onChange={onChange}
              todayButton={'Today'}
              clearIcon={null}
              calendarIcon={null}
              value={value}
              dateFormat="DD-MM-YYYY"
              dateFormatCalendar={'MMMM YYYY'}
              shouldCloseOnSelect={true}
              isClearable={true}
              className={'custom_date'}
              locale="en-US"
              minDate={new Date()}
            />
          )}{' '}
          {(!type || type === 'text' || type === 'email') && (
            <input
              id={id}
              onChange={(e) => (onChange ? onChange(e.target.value) : '')}
              onBlur={onBlur}
              type={type ? type : 'text'}
              className={`form-control rounded ${className} ${
                is_error ? 'form-control-error' : ''
              }`}
              placeholder={placeholder ? placeholder : ''}
              value={value || ''}
              required={required}
              style={{ ...style }}
              {...rest}
            />
          )}
        </>
      </label>
      {((required && value === '' && showError) || is_error) &&
        type !== 'email' && (
          <small className="has-error control-label" style={errorStyle}>
            {errorMessage
              ? errorMessage
              : label && typeof label === 'string'
              ? `${t('please_enter')} ${label.toLowerCase()}`
              : t('enter_valid_value')}
          </small>
        )}
      {value !== '' && required && type === 'email' && is_error && (
        <small className="has-error control-label" style={errorStyle}>
          {errorMessage ? errorMessage : t('enter_valid_email')}
        </small>
      )}
    </div>
  );
};
