import React, { useState } from 'react';
import styled from 'styled-components';
import { Location } from '@reach/router';
import {
  ExchangeRate,
  UnauthenticatedExchangeRate,
  VatRate,
} from './graphql/queries/common';

import trashIcon from 'fabric/resources/svg/ui-icons/delete.svg';
import moreMenuWhiteIcon from 'fabric/resources/svg/ui-icons/more.svg';
import moreMenuBlueIcon from 'fabric/resources/svg/ui-icons/more_blue.svg';
import { theme } from 'fabric/theme';
import { useTranslation } from 'react-i18next';
import { getUserReviewStatus } from 'utils/user';
import { useUserContext } from 'context/userContext';
import {
  CustomTrans,
  isHoldByCompanyUser,
  isOnHoldForAnotherUser,
} from 'helpers';
import { holdStatusTypes } from './constants';
import CopyToClipboard from 'NewDashboard/components/copy-to-clipboard';
import { GiaModal } from '@nivoda/common';
import { noop } from 'lodash';
import { styled_predicate } from 'helpers';

let is_mobile = window.innerWidth < 700;

let precondition = (condition, message = `Unmet precondition`) => {
  if (!condition) {
    throw new Error(message);
  }
};

export class AddClass extends React.Component {
  /*:flow
              props: {
                element: HTMLElement,
                className: string,
              }
              */

  had_class = false;

  componentDidMount() {
    this.had_class = this.props.element.classList.contains(
      this.props.className
    );
    this.props.element.classList.add(this.props.className);
  }

  componentWillUnmount() {
    if (this.had_class === false) {
      // Remove the class if we didn't have it before we mounted
      this.props.element.classList.remove(this.props.className);
    }
  }

  render() {
    let { children } = this.props;
    if (children) return children;
    return null;
  }
}

export let WithHistory = ({ children }) => {
  return (
    <Location>
      {(props) =>
        children({
          push: props.navigate,
          customPush: (url, source, category) =>
            props.navigate(url, {
              state: { from: props.location.href, source, category },
            }),
          goBack: () => {
            window.history.back();
          },
          replace: (url) => {
            if (typeof url !== 'string') {
              url = url.search;
            }
            props.navigate(url, { replace: true });
          },
        })
      }
    </Location>
  );
};

export class OnChange extends React.Component {
  componentDidMount() {
    this.props.do(this.props.value);
  }

  componentDidUpdate(prevProps, _, _1) {
    if (this.props.value !== prevProps.value) {
      this.props.do(this.props.value);
    }
  }

  render() {
    return null;
  }
}

export class OnRouteChange extends React.Component {
  render() {
    return null;
  }
}

export let Grid = ({
  area,
  template,
  templateColumns,
  templateRows,
  gap,
  rowGap,
  columnGap,
  style,
  ...props
}) => {
  if (area) {
    return <div style={{ gridArea: area, ...style }} {...props} />;
  } else {
    return (
      <div
        style={{
          display: 'grid',
          gridTemplate: template,
          gridTemplateColumns: templateColumns,
          // gridTemplateRows: templateRows,
          gridGap: gap,
          gridRowGap: rowGap,
          gridColumnGap: columnGap,
          ...style,
        }}
        {...props}
      />
    );
  }
};

export let NowIcon = ({ iconStyle = {}, name, ...props }) => {
  return (
    <span {...props}>
      <i
        className={`now-ui-icons ${name}`}
        style={{ display: 'inline-block', ...iconStyle }}
      />
    </span>
  );
};
export let UserInReview = ({ user }) => {
  let { t } = useTranslation();
  const { userBeingReviewed, userNeedsReview } = getUserReviewStatus(user);
  const is_fraudulent = user?.fraudulent_customer ? true : false;

  const Wrp = styled.div`
    &.no-verify-card {
      display: flex;
      background: #dbdce9;
      box-shadow: 0px 2px 6px rgba(10, 10, 20, 0.1);
      border-radius: 3px;
      width: 100%;
      align-items: center;
      justify-content: center;
      padding: 2px 12px;
      margin-top: 15px;
      margin-bottom: 5px;
    }
    &.no-verify-card span {
      color: var(--primary-color-light);
      font-size: 14px;
      margin-right: 24px;
      padding: 4px;
    }

    &.v2-no-verify-card {
      margin-top: 1px;
      margin-bottom: 10px;
    }

    @media (max-width: 700px) {
      &.no-verify-card {
        flex-direction: column;
        text-align: center;
      }
      &.no-verify-card span {
        margin-right: 0;
        padding-top: 4px;
      }
    }
  `;

  return (
    <>
      {is_fraudulent && (
        <Wrp
          className="no-verify-card v2-no-verify-card"
          style={{ marginTop: 10 }}
        >
          <span>{t('your_account_is_restricted')}</span>
        </Wrp>
      )}
      {userNeedsReview(user) && !is_fraudulent && (
        <Wrp
          className="no-verify-card v2-no-verify-card"
          style={{ marginTop: 10 }}
          data-automation-id="verification-text"
        >
          <span>{t('we_are_verifying_your_account')} </span>
        </Wrp>
      )}
      {userBeingReviewed(user) && !userNeedsReview(user) && !is_fraudulent && (
        <Wrp
          className="no-verify-card v2-no-verify-card"
          style={{ marginTop: 10 }}
        >
          <span>{t('your_account_is_currently_being_reviewed')}</span>
        </Wrp>
      )}
    </>
  );
};

export let Price = ({
  exchange_rate,
  price,
  vat_rate,
  include_vat,
  vat_only,
  precision = 2,
  className,
  plain_text = false,
  extra_amount = 0, // this is any amount that may otherwise want to add to the final displayed price. Use with caution.
  editable = false,
  onChange = () => null,
  onBlur = () => null,
  inputWidth = '100',
  inputId = '',
}) => {
  precondition(
    typeof price !== 'number',
    `You passed in a number to <Price price={${price}} />, but it should be an object { currency, amount }`
  );

  if (vat_rate != null) {
    return (
      <Price
        className={className}
        price={{
          currency: exchange_rate.id,
          amount:
            (price.amount / exchange_rate.to_USD) *
            (include_vat ? 1 + vat_rate / 100 : vat_only ? vat_rate / 100 : 1),
        }}
        extra_amount={extra_amount}
      />
    );
  }
  if (exchange_rate) {
    return (
      <Price
        className={className}
        price={{
          currency: exchange_rate.id,
          amount: price.amount / exchange_rate.to_USD,
        }}
        extra_amount={extra_amount}
      />
    );
  }

  // precondition(price > 100000, `<Price /> recieved price higher than 100.000; most like you forgot to divide by 100 for cents`);

  // TODO Make this not rely on toLocalString (not supported everywhere)
  // let rounded_amount = Math.ceil(converted_amount / 100) * 100;
  if (price && !price.currency) {
    // eslint-disable-next-line no-console
    console.error('Error: Missing currency info: ', price, className);
  }

  let new_price = price.amount + extra_amount;
  const currency = price && price.currency ? price.currency : 'USD';
  const price_string = new_price
    .toLocaleString(currency === 'INR' ? 'en-IN' : 'en-GB', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    ?.replace(
      price?.currency === 'AUD' ? /A\$/ : /CA\$/,
      price?.currency === 'AUD' ? 'AU$' : 'CA$'
    )
    ?.replace(/US/, '')
    ?.replace('ZAR', 'R');
  return plain_text ? (
    price_string
  ) : (
    <>
      {editable ? (
        <>
          <input
            style={{
              padding: '5px 0',
              border: 'none',
              outline: 'none',
              backgroundColor: 'unset',
              width: '7px',
            }}
            type="text"
            defaultValue={price_string[0]}
          />
          <input
            style={{
              padding: '5px 3px',
              border: 'none',
              outline: 'none',
              backgroundColor: 'unset',
              width: inputWidth + '%',
            }}
            type="text"
            id={inputId}
            defaultValue={
              typeof new_price === 'number' && new_price > 0
                ? price_string.slice(1)
                : ''
            }
            placeholder="--, --. --"
            onChange={onChange}
            onBlur={onBlur}
          />
        </>
      ) : (
        <span style={{ display: 'inline-block' }} className={className}>
          {price_string}
        </span>
      )}
    </>
  );
};

export let VatPrice = ({
  className = '',
  price,
  include_vat,
  vat_only,
  rate,
  item_vat_rate,
  extra_amount,
}) => {
  return (
    <VatRate dontRenderWhileLoading>
      {({ exchange_rate, vat_rate }) => (
        <Price
          className={className}
          exchange_rate={rate || exchange_rate}
          vat_rate={item_vat_rate || vat_rate}
          price={price}
          include_vat={include_vat}
          vat_only={vat_only}
          extra_amount={extra_amount}
        />
      )}
    </VatRate>
  );
};

export let PriceExchanged = ({
  price,
  include_vat,
  precision = 2,
  ...props
}) => {
  return (
    <ExchangeRate dontRenderWhileLoading>
      {({ exchange_rate }) => {
        if (exchange_rate.name === 'USD') return null;
        return (
          <Price
            exchange_rate={exchange_rate}
            price={price}
            precision={precision}
            className={props.className}
            include_vat={include_vat}
            {...props}
          />
        );
      }}
    </ExchangeRate>
  );
};

export let CustomPriceExchanged = ({ price, exchange_value, ...props }) => {
  return (
    <UnauthenticatedExchangeRate dontRenderWhileLoading>
      {({ get_exchange_rates }) => {
        let exch_val = get_exchange_rates.find(
          (sample) => sample?.name === exchange_value
        );
        return (
          <Price
            exchange_rate={exch_val}
            price={price}
            className={props.className}
            {...props}
          />
        );
      }}
    </UnauthenticatedExchangeRate>
  );
};

/**
 * type can be
 * success, info, warning, danger
 * TODO: add wdc type?
 */
// TODO: duplicate of NotificationProgressBar?
export let NotificationBar = ({ icon, title, msg, type, onclose, style }) => {
  let className = `alert alert-${type}`;
  let button;
  if (typeof onclose === 'function') {
    button = (
      <button
        type="button"
        className="close"
        onClick={onclose}
        aria-label="Close"
      >
        <span aria-hidden="true">
          <NowIcon name="ui-1_simple-remove" />
        </span>
      </button>
    );
  } else {
    button = (
      <button
        type="button"
        className="close"
        data-dismiss="alert"
        aria-label="Close"
      >
        <span aria-hidden="true">
          <NowIcon name="ui-1_simple-remove" />
        </span>
      </button>
    );
  }
  return (
    <div className={className} role="alert" style={style}>
      <div
        className="container"
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <div>
          {icon && (
            <div className="alert-icon">
              <NowIcon name={icon} />
            </div>
          )}
          {title && <strong>{title}</strong>}
          {msg}
        </div>
        {button}
      </div>
    </div>
  );
};
export const Flex = styled.div`
  display: flex;
  align-items: ${({ align = 'stretch' }) => align};
  gap: ${({ gap = '0' }) => gap};
  flex-direction: ${(p) => (p.column === true ? 'column' : 'row')};
  flex-wrap: wrap;
`;

export let order_number = (order_item) => (
  <span className="order-overview__order-number">
    {order_item.customer_order_number
      ? `${order_item.customer_order_number} - ${order_item.order_number}`
      : order_item.order_number}
  </span>
);

let MoreIconWrapper = styled.div`
  height: 40px;
  border-radius: 3px;
  background: white url(${moreMenuBlueIcon}) no-repeat center;
  border: 1px solid ${theme.palette.themePrimary};
  background-size: 24px;
  cursor: pointer;

  &:hover {
    background: #0c0a09 url(${moreMenuWhiteIcon}) no-repeat center;
    background-size: 24px;
  }
`;

let GenericIconWrapper = styled.div`
  height: 32px;
  margin: 4px 1px 4px 1px;
  width: 32px;
  border-radius: 3px;
  background: white url(${trashIcon}) no-repeat center;
  border: 1px solid ${theme.palette.themeSecondary};
  background-size: 75% 50%;
  cursor: pointer;

  &:hover {
    background: white url(${trashIcon}) no-repeat center;
    background-size: 75% 50%;
    border: 1px solid ${theme.palette.themeSecondary};
    box-shadow: ${is_mobile ? '' : '0px 3px 8px 0 rgba(0, 0, 0, 0.17)'};
  }
`;

export let MoreIcon = () => <MoreIconWrapper />;
export let DeleteIcon = ({ style }) => (
  <GenericIconWrapper style={{ ...style }} />
);

export let CheckHoldsOnAddToCart = ({ product, offer, children }) => {
  let { user } = useUserContext();
  let { userInReview } = getUserReviewStatus(user);

  const isSoldOut = product.availability === 'SOLD_OUT';

  const isOnHoldForAnotherCustomer = isOnHoldForAnotherUser({
    offer,
    userId: user.id,
  });

  const isWithinCompanyATCEnabled = isHoldByCompanyUser({
    offer,
    user,
  });

  const isStonedOnHoldFromSupplier =
    offer &&
    offer.Product.availability === holdStatusTypes.ON_HOLD &&
    !offer?.hold?.id;
  let on_hold_for_external_customer =
    isOnHoldForAnotherCustomer && !isWithinCompanyATCEnabled;

  let is_disabled =
    on_hold_for_external_customer ||
    isStonedOnHoldFromSupplier ||
    isSoldOut ||
    userInReview;
  let text = () => {
    if (on_hold_for_external_customer)
      return (
        <CustomTrans
          text="this_stone_is_on_hold_for_another_customer"
          returnPlainText
        />
      );
    else if (isStonedOnHoldFromSupplier)
      return (
        <CustomTrans
          text="this_stone_is_on_hold_by_the_supplier"
          returnPlainText
        />
      );
    else if (isSoldOut)
      return <CustomTrans text="the_stone_is_sold_out" returnPlainText />;
    else if (userInReview)
      return (
        <CustomTrans text="your_account_is_under_review" returnPlainText />
      );
    else return '';
  };
  let disabled_reason = text();
  let response = {
    isSoldOut,
    isOnHoldForAnotherCustomer,
    isWithinCompanyATCEnabled,
    is_disabled,
    disabled_reason,
  };
  return children(response);
};

let CertWrapper = styled.div`
  .cert_wrapper {
    padding-right: ${({ cert_wrapper }) => cert_wrapper.paddingRight || '5px'};
    font-weight: ${({ cert_wrapper }) => cert_wrapper.fontWeight || 400};
    font-size: ${({ cert_wrapper }) => cert_wrapper.fontSize || '12px'};
    color: ${({ cert_wrapper }) => cert_wrapper.color || '#424243'};
    width: ${({ truncate_width }) =>
      truncate_width ? truncate_width : 'fit-content'};
    ${styled_predicate(
      ({ cert_wrapper }) => cert_wrapper?.viewType === 'box'
    )} {
      display: flex;
      flex-direction: column;
    }
  }

  .cert_label {
    font-weight: ${({ cert_wrapper }) =>
      cert_wrapper.labelStyles?.fontWeight || 700};
    font-size: ${({ cert_wrapper }) =>
      cert_wrapper.labelStyles?.fontSize || '12px'};
    color: ${({ cert_wrapper }) =>
      cert_wrapper.labelStyles?.color || '#424243'};
    cursor: ${({ isUserAllowed }) => (isUserAllowed ? 'poiner' : 'auto')};
  }

  .label:hover {
    text-decoration: ${({ isUserAllowed }) =>
      isUserAllowed ? 'underline !important' : 'none'};
  }
  .copy__to__clipboard {
    ${styled_predicate(
      ({ cert_wrapper }) => cert_wrapper?.viewType === 'box'
    )} {
      align-items: flex-end;
      padding-bottom: 3px;
    }
  }
`;
export const CertWithIconV2 = ({
  certificate,
  hide_lab,
  truncate_width,
  supplierId,
  isOrderItem,
  cert_value_styles = {},
  cert_wrapper = {},
  hideIcon = false,
  separator = ':',
  only_lab = false,
}) => {
  const [showCertModal, setShowCertModal] = useState(false);
  const { user, company } = useUserContext();
  const isAdmin = user?.role === 'ADMIN';
  const isUserRoleCustomer = user?.role === 'CUSTOMER';
  const isUserRoleSupplier = user?.role === 'SUPPLIER';
  const isSupplierOwner = isUserRoleSupplier && company?.id === supplierId;
  const isMOR = isUserRoleCustomer && company?.company_settings?.is_mor_enabled;
  // three categories
  // 1. Admin/SupplerOwner/NonMoR / is an orderItem (can open and can copy)
  // 2. MoR with a PDF (can open only)
  // 3. MoR without a PDF (cannot open and cannot copy)
  const onClick = React.useCallback(
    (e) => {
      e && e.preventDefault();
      e && e.stopPropagation();
      if (certificate.lab === 'IGI') {
        const url = certificate.pdfUrl
          ? certificate.pdfUrl
          : `https://www.igi.org/reports/verify-your-report?r=${certificate.certNumber}`;
        window.open(url, '_blank');
      } else if (certificate.lab === 'HRD') {
        const url = certificate.pdfUrl
          ? certificate.pdfUrl
          : `https://my.hrdantwerp.com/?record_number=${certificate.certNumber}`;
        window.open(url, '_blank');
      } else {
        setShowCertModal(!showCertModal);
      }
    },
    [showCertModal, certificate.pdfUrl, certificate.certNumber, certificate.lab]
  );

  function isPDFUrl(url) {
    if (!url) return null;
    return url?.toLowerCase()?.endsWith('.pdf');
  }

  const isPdfFile = isPDFUrl(certificate?.pdfUrl);
  const can_open =
    isAdmin || isSupplierOwner || isOrderItem || !isMOR || (isMOR && isPdfFile);
  const can_copy = isAdmin || isSupplierOwner || isOrderItem || !isMOR;

  let certNumber = certificate.certNumber;

  return (
    <CertWrapper
      truncate_width={truncate_width}
      isUserAllowed={can_open}
      cert_wrapper={cert_wrapper}
    >
      {certificate.lab !== 'NONE' && certificate.lab !== 'OTHER' ? (
        <div style={{ display: 'flex' }}>
          <div
            className="cert_wrapper truncate"
            data-automation-id="cert-wrapper"
          >
            {!hide_lab && `${certificate.lab}${separator} `}
            {!only_lab && (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a
                style={{
                  cursor: can_open ? 'pointer' : 'auto',
                }}
                className="label"
                onClick={can_open ? onClick : noop}
                data-automation-id="cert-number"
              >
                {certNumber}
              </a>
            )}
          </div>
          {!only_lab && can_copy && !hideIcon && (
            <CopyToClipboard
              iconName={'copyIcon'}
              iconHeight={16}
              iconWidth={16}
              text={certNumber}
            />
          )}
        </div>
      ) : certificate.lab === 'OTHER' ? (
        <span>
          {' '}
          <CustomTrans text="supplier_certificate" />
        </span>
      ) : (
        <span
          style={{ display: 'flex', justifyContent: 'flex-start' }}
          className="cert_label"
        >
          <CustomTrans text="non_cert" />
        </span>
      )}

      {/* If certificate.pdfUrl  is NOT a PDF then don't show this modal. */}
      {showCertModal && can_open && (
        <GiaModal
          url={certificate.pdfUrl}
          lab={certificate.lab}
          giaNumber={certificate.certNumber}
          onClick={onClick}
        />
      )}
    </CertWrapper>
  );
};
