import { getPlaceholderItems } from '@nivoda/ui';
import { OrderItemStatus } from '../constants';
import { capitalize } from 'lodash/string';
import React from 'react';
import styled from 'styled-components';
import { roundSkeletonStyles } from '../fabric/styles';
import theme from '../fabric/theme';

export function getOrdersDataWithLoadingMeta({
  columns = [],
  list,
  loading = false,
  status,
}) {
  const items = loading
    ? getPlaceholderItems(
        columns.map(({ id }) => id),
        15
      )
    : list.items;

  const heading = loading
    ? status === OrderItemStatus.READY_TO_QC
      ? 'QC'
      : capitalize(status.replace(/_/g, ' '))
    : status === OrderItemStatus.READY_TO_QC
    ? `QC (${list.total_count})`
    : capitalize(`${status.replace(/_/g, ' ')} (${list.total_count})`);

  const tabHeading = loading ? (
    <Span className={'page_heading'}>
      {heading} <span className={'skeleton skeleton__page_heading'} />
    </Span>
  ) : (
    heading
  );
  return { items, heading, tabHeading };
}

const Span = styled.span`
  &.page_heading {
    display: inline-flex;
    align-items: center;
    gap: ${theme.spacing.s1};

    .skeleton.skeleton__page_heading {
      ${roundSkeletonStyles('15px')};
      margin-bottom: -2px;
    }
  }
`;
