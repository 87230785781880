import React from 'react';
import { bool, number, string } from 'prop-types';
import styled from 'styled-components';
import { FlagIcon } from '@nivoda/react-flag-kit';
import theme from 'fabric/theme';
import { Link } from '@reach/router';

export const FlagWithLabel = (props) => {
  const {
    align = 'center',
    countryCode,
    direction = '',
    iconSize = 24,
    fonts = theme.fonts.small,
    label,
    roundFlag = false,
    supplierDetails = null,
  } = props;
  return (
    <FlagWithLabelWrapper
      align={align}
      className="flg"
      direction={direction}
      isRoundFlag={roundFlag}
      fonts={fonts}
    >
      {countryCode ? (
        <FlagIcon code={countryCode} size={iconSize} />
      ) : (
        <div className="flg__na">NA</div>
      )}
      {label && (
        <span className="flg__text-label">
          {supplierDetails?.id ? (
            <Link to={`/admin/companies/${supplierDetails?.id}`}>
              <div className="underline_on_hover">{label}</div>
            </Link>
          ) : (
            <div>{label}</div>
          )}
        </span>
      )}
    </FlagWithLabelWrapper>
  );
};

const FlagWithLabelWrapper = styled.div`
  display: flex;
  align-items: ${({ align }) => align};
  column-gap: ${theme.spacing.s1};
  flex-direction: ${({ direction }) => direction};
  & img {
    clip-path: ${({ isRoundFlag }) =>
      isRoundFlag ? 'ellipse(35% 35% at 50% 50%)' : 'unset'};
  }
  & .flg__ {
    &na {
      ${theme.fonts.xTiny};
      width: ${({ isRoundFlag }) => (isRoundFlag ? '20px' : '24px')};
      height: 20px;
      background: ${theme.palette.offWhite2};
      border-radius: ${({ isRoundFlag }) => (isRoundFlag ? '50px' : '4px')};
      margin-left: 2px;
      margin-right: 3px;
      padding: ${theme.spacing.s1}
        ${({ isRoundFlag }) => (isRoundFlag ? '5px' : '6px')};
    }
    &text-label {
      ${({ fonts }) => fonts};
      color: ${theme.palette.offBlack4};
      padding-left: ${({ align }) =>
        align === 'start' ? theme.spacing.s1 : ''};
    }
  }
`;

FlagWithLabel.propTypes = {
  label: string,
  countryCode: string.isRequired,
  iconSize: number,
  roundFlag: bool,
};
