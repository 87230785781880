import { AdminGraphqlQuery } from '../../../graphql-admin';
import { gql } from '@apollo/client';
import {
  AdminDiamondFragment,
  AdminDiamondPriceFragment,
  GemstoneFragment,
  CertificateFragment,
} from 'graphql/fragments';

import { OfferFragmentCalculator } from 'graphql/fragments/OfferFragment';
export let SEARCH_BY_KEY = gql`
  query ($q: String!, $type: String!) {
    search_by_company(q: $q, type: $type) {
      items {
        id
        name
        company_users {
          id
          firstName
          lastName
        }
        OwnerId
        main_location
        locations {
          id
          name
          city
          country
        }
      }
    }
  }
`;
export let SearchByKey = AdminGraphqlQuery({
  query: SEARCH_BY_KEY,
});

export let LoadCompanyOptions = gql`
  query ($q: String!, $type: String!) {
    search_by_company(q: $q, type: $type) {
      items {
        id
        name
        company_users {
          id
          firstName
          lastName
        }
        OwnerId
        main_location
        locations {
          id
          name
          city
          country
        }
      }
    }
  }
`;

export let SearchByStockIdDiamonds = AdminGraphqlQuery({
  query: gql`
    query ($q: String!) {
      search_by_stockId(q: $q) {
        items {
          id
          supplierStockId
          certificate {
            id
            certNumber
            lab
          }
          supplier {
            id
            name
          }
          price
        }
      }
    }
  `,
});

export let SearchCertificates = AdminGraphqlQuery({
  query: gql`
    query ($q: String!) {
      search_certificates(q: $q) {
        total_count
        items {
          ...AdminDiamond
          ...AdminDiamondPrice
          offer {
            id
            discount
            price
            product_price
            product_discount
            total_price_without_credit_charge
            hold {
              id
              buyer {
                id
              }
            }
            original_offer {
              id
              discount
            }
            fbn_details {
              inventory_type
            }
          }
        }
      }
    }
    ${AdminDiamondFragment}
    ${AdminDiamondPriceFragment}
  `,
});

export let SearchGemstoneCertificates = AdminGraphqlQuery({
  query: gql`
    query ($q: String!) {
      search_gemstone_certificates(q: $q) {
        total_count
        items {
          ...GemstoneFragment
        }
      }
    }
    ${GemstoneFragment}
  `,
});

export let SEARCH_FOR_ADMIN_CALCULATOR = gql`
  query searchForAdminCalculator($userId: ID, $query: AdminCalculatorQuery) {
    search_for_admin_calculator(userId: $userId, query: $query) {
      total_count
      items {
        ...OfferFragment
        __typename
      }
      __typename
    }
  }
  ${OfferFragmentCalculator}
`;

export let SEARCH_BY_STOCK_ID = gql`
  query searchByStockId($stockId: String!) {
    product_by_stock_id(stockId: $stockId) {
      ... on Diamond {
        id
        price
        brown
        green
        milky
        eyeClean
        availability
        supplierStockId
        certificate {
          girdleCondition
          girdlePercent
          ...CertificateFragment
        }
        location {
          id
          name
          country
        }
        offer {
          id
          discount
          price
          product_discount
          product_price
          ProductType
          original_offer {
            id
            discount
          }
          fbn_details {
            id
            internal_status
          }
        }
      }
      ... on Gemstone {
        ...GemstoneFragment
      }
    }
  }
  ${CertificateFragment}
  ${GemstoneFragment}
`;

export let DOWNLOAD_PRICE_CALCULATIONS = gql`
  query downloadPriceCalculations($userId: ID, $query: AdminCalculatorQuery) {
    download_price_calculations(userId: $userId, query: $query) {
      fileBuffer
    }
  }
`;
