import { OrderItemStatus } from '../../constants';
import { WIP } from '@nivoda/ui';
import React from 'react';
import { InvoiceTabIDs } from '../../constants';

export const AdminMeleeConfig = {
  orders: {
    status: 'ACTIVE',
    tabs: [
      'MELEE_REPOSITORY',
      'ADD_MELEE',
      'CREATE_MC_ORDER',
      'REFERENCE_REPORT',
      OrderItemStatus.PURCHASE_ORDER,
      OrderItemStatus.CONFIRMED,
      OrderItemStatus.IN_TRANSIT,
      OrderItemStatus.READY_TO_COLLECT,
      OrderItemStatus.READY_TO_SHIP,
    ],
  },
  invoices: {
    status: 'ACTIVE',
    tabs: [
      InvoiceTabIDs.TO_INVOICE,
      InvoiceTabIDs.UPLOAD_INVOICE,
      InvoiceTabIDs.SUPPLIERS_TO_PAY_INV,
      InvoiceTabIDs.SUPPLIERS_TO_PAY_PO,
      InvoiceTabIDs.PAYMENT_HISTORY,
    ],
  },
};

export const getMeleeConfig = (enable = true) => {
  return {
    meleeConfig: {
      enable,
      admin: AdminMeleeConfig,
      orders: {
        status: 'ACTIVE',
        component: (
          <WIP
            subtitle={'Orders loading failed'}
            title={'Please try again after some time.'}
          />
        ),
      },
      shortlist: {
        status: 'ACTIVE',
        component: (
          <WIP
            subtitle={'Shortlist loading failed'}
            title={'Please try again after some time.'}
          />
        ),
      },
      search: {
        status: 'ACTIVE',
        component: (
          <WIP
            subtitle={'Page loading failed'}
            title={'Please try again after some time.'}
          />
        ),
      },
    },
  };
};
