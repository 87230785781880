import styled from 'styled-components';
import { Track } from 'fabric/integrations';
import { navigate } from '@reach/router';
import { Button, theme } from '@nivoda/ui';
import { Icon } from '../../resources/icon';
import * as PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const AsDiv = styled.div`
  &.section_details__back--back-btn {
    // To make back btn sticky
    position: sticky;
    bottom: 0;
    width: fit-content;
    background: ${({ background, transparent }) =>
      transparent ? 'transparent' : background || '#fafafa'};
    padding: 5px 16px 0;
    border-radius: 3px;
    z-index: 2;

    @media (max-width: 700px) {
      // hide back to cart on mobile
      display: none;
    }
  }

  & {
    .back-arrow-icon {
      margin: ${({ reverse }) => (reverse ? '0 0 0 6px' : '0 6px 3px 0')};
    }

    .section_details__back--label {
      color: ${({ color = theme.palette.themePrimary }) => color};
    }

    .section_details__address-btn {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      background: transparent;
    }
  }
`;

export const BackTo = (props) => {
  const { t } = useTranslation();
  const onClick = async () => {
    if (typeof props.back === 'function') {
      // when coming from delivery to place order
      props.back();
      return true;
    }
    if (props.hasTracking) {
      // If back to handler is not provided by default use window back API
      Track.track(`${props.path.from} -> ${props.path.to}`);
    }
    if (!props.path.toURL || typeof props.path.toURL !== 'string') {
      window.history.back();
    } else {
      await navigate(props.path.toURL);
    }
  };

  return (
    <AsDiv
      background={props.background}
      className={`section_details__back--back-btn ${props.className || ''}`}
      color={props.color}
      reverse={props.reverse}
      transparent={props.transparent}
    >
      <Button
        data-automation-id="back-btn"
        onClick={onClick}
        className="section_details__address-btn"
        customPadding="0"
        ghost
        noShadow
        title={props.path.to}
      >
        {!props.reverse && (
          <Icon
            className="back-arrow-icon"
            name="back-arrow"
            color={theme.palette.themePrimary}
            width={7}
            height={13}
          />
        )}
        <span className="section_details__back--label">{t(props.path.to)}</span>
        {props.reverse && (
          <Icon
            className="back-arrow-icon"
            name="arrow-forward"
            color={theme.palette.themePrimary}
            width={16}
          />
        )}
      </Button>
    </AsDiv>
  );
};

BackTo.propTypes = {
  background: PropTypes.string,
  back: PropTypes.func,
  className: PropTypes.string,
  color: PropTypes.string,
  hasTracking: PropTypes.bool,
  path: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string.isRequired,
    toURL: PropTypes.string,
  }).isRequired,
  // show forward arrow icon
  reverse: PropTypes.bool,
  transparent: PropTypes.bool,
};
